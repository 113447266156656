<template>
  <div
    class="message"
    :class="{ 'own-message': isOwnMessage, 'other-message': !isOwnMessage }"
  >
    <img v-if="!isOwnMessage" :src="toAvatar" class="avatar" />
    <img v-if="isOwnMessage" :src="ownAvatar" class="avatar" />
    <div class="message-content">
      <div>
        <img
          v-if="message.type === 'img'"
          :src="message.content || defaultImage"
          class="message-image"
          @click="showPic"
        />
        <video v-if="message.type === 'video'" controls class="message-video">
          <source :src="message.content || defaultImage" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          v-if="message.type === 'audio'"
          class="message-audio-container"
          @click="togglePlay"
        >
          <div class="audio-controls">
            <button @click.stop="togglePlay" class="play-button">
              {{ isPlaying ? "❚❚" : "▶" }}
            </button>
            <div class="progress-bar" @click.stop="seekAudio">
              <div
                class="progress-bar-fill"
                :style="{ width: progress + '%' }"
              ></div>
            </div>
          </div>
          <audio
            ref="audio"
            :src="message.content"
            class="message-audio"
            @timeupdate="updateProgress"
            @ended="isPlaying = false"
          >
            Your browser does not support the audio tag.
          </audio>
          <div v-if="!isPlaying" class="play-button-overlay">▶</div>
        </div>
        <a
          v-if="message.type === 'file'"
          :href="message.content"
          class="message-file"
          download
        >
          <div class="file-icon">📁</div>
          <div class="file-info">
            <div class="file-name">{{ message.fileName }}</div>
          </div>
        </a>
        <MarkdownPreview
          v-if="message.type === 'txt' && message.fromUser === '001'"
          @on-copy="onCopy"
          :initialValue="message.content"
        ></MarkdownPreview>

        <p
          v-if="message.type === 'txt' && message.fromUser !== '001'"
          v-html="formatMessage(message)"
        ></p>

        <div v-if="recommendedQuestions.length" class="recommended-questions">
          <div v-for="(question, index) in recommendedQuestions" 
               :key="index" 
               class="recommendation-question"
               @click="handleQuestionClick(question)">
            {{ question }}
          </div>
        </div>

        <div class="popup-actions-container">
          <div v-if="message.sending">
            <div class="sending-indicator"></div>
            <p>发送中...</p>
          </div>
          <div v-if="!message.sending">
            <PopupActions :actions="actions" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PopupActions from "@/components/PopupActions.vue";
import { deleteMessage } from "@/api/message";
import "highlight.js/styles/github.css";
import MarkdownPreview from '@/components/markdown/preview';

export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
    isOwnMessage: {
      type: Boolean,
      required: true,
    },
    toAvatar: {
      type: String,
      required: true,
    },
    ownAvatar: {
      type: String,
      required: true,
    },
  },
  components: {
    PopupActions,
    MarkdownPreview,
  },
  data() {
    return {
      actions: [
        { name: "删除", icon: "trash", callback: this.deleteMessage },
        { name: "复制", icon: "copy", callback: this.copyMessage },
        // 其他操作
      ],
      defaultImage: "@/assets/imgs/loading.gif", // 设置默认图片路径
      isPlaying: false,
      progress: 0,
    };
  },
  mounted() {},
  updated() {},
  computed: {
    recommendedQuestions() {
      // 从消息内容中提取推荐问题
      const content = this.message.content;
      const questions = [];
      
      if (content.includes('推荐问题：')) {
        const questionsSection = content.split('推荐问题：')[1];
        // 提取加粗的问题
        const matches = questionsSection.match(/\*\*(.*?)\*\*/g);
        if (matches) {
          matches.forEach(match => {
            questions.push(match.replace(/\*\*/g, ''));
          });
        }
      }
      return questions;
    }
  },
  methods: {
    onCopy(val) {
      this.$Message.success("复制成功");
    },
    async getFileInfo(url) {
      try {
        // 获取文件名
        const fileName = url.substring(url.lastIndexOf("/") + 1);

        // 获取文件大小（需要发送HEAD请求获取文件头信息）
        const response = await fetch(url, { method: "HEAD" });
        const contentLength = response.headers.get("content-length");

        let fileSize = "未知大小";
        if (contentLength) {
          fileSize = `${contentLength} bytes`;
        }

        return { fileName, fileSize };
      } catch (error) {
        console.error("获取文件信息出错:", error);
        return { fileName: "未知文件名", fileSize: "无法获取文件大小" };
      }
    },
    togglePlay() {
      const audio = this.$refs.audio;
      if (audio) {
        if (this.isPlaying) {
          audio.pause();
        } else {
          audio.play();
        }
        this.isPlaying = !this.isPlaying;
      }
    },
    formatFileSize(size) {
      if (size < 1024) return size + " B";
      else if (size < 1048576) return (size / 1024).toFixed(1) + " KB";
      else return (size / 1048576).toFixed(1) + " MB";
    },
    copyMessage() {
      if (this.message.type === "txt") {
        console.log("复制文本消息：", this.message.content);
        this.copyToClipboard(this.message.content);
        this.$Message.success("复制成功");
      } else {
        this.$Message.error("暂不支持");
      }
    },
    copyToClipboard(text) {
      var textarea = document.createElement("textarea");
      textarea.style.position = "fixed";
      textarea.style.opacity = 0;
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    },
    deleteMessage() {
      let messId = this.message.messageId;
      let mess = this.message;
      deleteMessage({
        messageId: messId,
      }).then((res) => {
        if (res.code === 0) {
          this.$emit("deleteMessage", mess);
        } else {
          this.$Message.error(res.msg);
        }
      });
    },
    formatMessage(message) {
      if (message.type === "txt") {
        const urlRegex =
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

        return message.content.replace(
          urlRegex,
          (url) =>
            ` <a href="${url}" target="_blank" class="link-style">${url}</a> `
        );
      }
      return message.content;
    },
    showPic() {
      this.$viewerApi({
        url: this.message.content,
        images: [this.message.content],
      });
    },
    updateProgress() {
      const audio = this.$refs.audio;
      if (audio && audio.duration) {
        this.progress = (audio.currentTime / audio.duration) * 100;
      }
    },
    seekAudio(event) {
      const audio = this.$refs.audio;
      if (audio) {
        const progressBar = event.currentTarget;
        const clickPosition = event.offsetX / progressBar.clientWidth;
        audio.currentTime = clickPosition * audio.duration;
      }
    },
    handleQuestionClick(question) {
      this.$emit('question-clicked', question);
    }
  },
};
</script>

<style scoped>
.message {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.own-message {
  justify-content: flex-end;
}

.own-message .message-content {
  background-color: #b9d6c8;
  color: white;
  order: 1;
}

.own-message .avatar {
  order: 2;
  margin-left: 10px;
}

.other-message .message-content {
  background-color: #f5f5f5;
  color: black;
  order: 2;
}

.other-message .avatar {
  order: 1;
  margin-right: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;
}

.message-content {
  padding: 10px;
  border-radius: 15px;
  word-break: break-word;
  overflow: hidden;
  max-width: 60%;
}

.message-image {
  max-width: 100%;
  max-height: 350px;
  height: auto;
  border-radius: 15px;
  cursor: pointer;
}

.message-video {
  max-width: 100%;
  max-height: 350px;
  height: auto;
  border-radius: 15px;
}

.message-audio-container {
  position: relative;
  max-width: 100%;
  padding: 5px;
  border-radius: 15px;
  background-color: #f0f0f0;
  color: #333;
}

.audio-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.play-button {
  background-color: #3498db;
  border: none;
  color: white;
  padding: 10px;
  border-radius: 50%;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 5px;
  transition: background-color 0.3s;
}

.play-button {
  background-color: #2980b9;
}

.progress-bar {
  width: 100%;
  height: 5px;
  background-color: #ddd;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
}

.progress-bar-fill {
  height: 100%;
  background-color: #3498db;
  border-radius: 10px;
  transition: width 0.1s;
}

.message-file {
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 10px;
  border-radius: 15px;
  background-color: #f0f0f0;
  color: #333;
  text-decoration: none;
  word-break: break-all;
  overflow-wrap: break-word;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.file-icon {
  font-size: 24px;
  margin-right: 10px;
}

.file-info {
  display: flex;
  flex-direction: column;
}

.file-name {
  font-weight: bold;
}

.file-size {
  font-size: 0.8em;
  color: #666;
}

.popup-actions-container {
  margin-top: 5px;
  border-top: 1px solid #e0e0e0;
  padding-top: 5px;
}

.popup-actions-container > .popup-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #19be6b;
  border-radius: 10px;
  background-color: #f8f8f8;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.link-style {
  color: #3498db;
  text-decoration: underline;
  cursor: pointer;
}

.sending-indicator {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  margin-right: 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.recommended-questions {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.recommendation-question {
  display: inline-block;
  color: #1890ff;
  cursor: pointer;
  padding: 4px 12px;
  border-radius: 4px;
  background: rgba(24, 144, 255, 0.1);
  border: 1px solid rgba(24, 144, 255, 0.2);
  transition: all 0.3s ease;
  font-weight: 500;
}

.recommendation-question:hover {
  background: rgba(24, 144, 255, 0.2);
  border-color: rgba(24, 144, 255, 0.3);
  transform: translateY(-1px);
  box-shadow: 0 2px 6px rgba(24, 144, 255, 0.1);
}

.recommendation-question:active {
  transform: translateY(0);
  box-shadow: none;
}
</style>
