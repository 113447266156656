<!-- eslint-disable max-len -->
<template>
        <div ref="list" v-auto-animate="{ duration: 100 }">
          <diary  v-for="(entry, index) in diaryList" :key="index" :diary="entry" @delete-diary="deleteDiary" />
          <Page :total="total" size="small" @on-change="change" show-elevator show-sizer style="margin-bottom: 45px;" @on-page-size-change="pageSizeCall" prev-text="上一页" next-text="下一页"/>
        </div>

</template>

<script>
import Diary from '@/components/diary/diaryEntity.vue'
import { diaryPage } from '@/api/diary'

export default {
    data() {
        return {
            diaryList: [],
            firstResult: 0,
            pageSize: 10,
            total: 0,
        }
    },
    components: {
        Diary,
    },
    mounted() {
        this.getDiaryList()
    },
    methods: {
        change(val) {
            this.firstResult = this.calculateStartData(val, this.pageSize)
            this.getDiaryList()
            const diaryList = this.$refs.list
            diaryList.scrollIntoView({ behavior: 'smooth', block: 'start' })
        },
        pageSizeCall(val) {
            this.pageSize = val
        },
        calculateStartData(currentPage, pageSize) {
            let startData = (currentPage - 1) * pageSize
            return startData
        },
        deleteDiary(deletedDiaryId) {
            const index = this.diaryList.findIndex(diary => diary.diaryId === deletedDiaryId)
            if (index !== -1) {
                this.diaryList.splice(index, 1)
            }
        },
        getDiaryList() {
            diaryPage({
                isPublic: 'true',
                notShowMePublic: 'F',
                pageSize: this.pageSize,
                firstResult: this.firstResult,
            }).then(res => {
                this.diaryList = res.retVal.datas
                this.total = res.retVal.totalRows
            })
        },
    },
}
</script>

<style>
div[ref="list"] {
    border: 2px solid #007BFF;
    padding: 15px;
    border-radius: 8px;
    background: #f0f0f0;
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
    transition: all 0.3s ease;
}

diary {
    margin-bottom: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

diary:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.2);
}

Page {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
    color: #007BFF;
}

Page .show-elevator, Page .show-sizer {
    background: #e0e0e0;
    border: 1px solid #ccc;
    padding: 8px 12px;
    border-radius: 8px;
    margin: 0 10px;
}

@media (max-width: 768px) {
    div[ref="list"], Page {
        padding: 10px;
        margin: 10px;
    }

    diary {
        margin-bottom: 10px;
    }
}
</style>