<template>
  <div class="forgot-password-card">
    <h2>重置密码</h2>
    <div class="input-group">
      <div class="input-c">
        <i class="ivu-icon ios-mail"></i>
        <Input v-model="email" placeholder="请输入注册邮箱" clearable />
        <p class="error">{{ error }}</p>
      </div>
    </div>

    <Button class="submit-btn" type="primary" @click="handleSubmit" :loading="isLoading">
      {{ isLoading ? '发送中...' : '发送重置链接' }}
    </Button>

    <div class="back-to-login">
      想起密码了？ <span @click="$emit('switch', 'login')">返回登录</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ForgotPasswordCard',
  data() {
    return {
      email: '',
      error: '',
      isLoading: false
    }
  },
  methods: {
    validateEmail() {
      if (!this.email) {
        this.error = '请输入邮箱地址';
        return false;
      }
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)) {
        this.error = '请输入有效的邮箱地址';
        return false;
      }
      return true;
    },
    handleSubmit() {
      if (!this.validateEmail()) return;
      
      this.isLoading = true;
      // 模拟发送重置邮件
      setTimeout(() => {
        this.isLoading = false;
        this.$Message.success('重置链接已发送到您的邮箱！');
        this.$emit('switch', 'login');
      }, 1500);
    }
  }
}
</script>

<style scoped>
.forgot-password-card {
  padding: 25px 30px;
}

h2 {
  font-size: 28px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 8px;
  text-align: center;
  background: linear-gradient(45deg, #2d8cf0, #00d0ff, #2d8cf0);
  background-size: 200% auto;
  animation: shimmer 2s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.input-group {
  margin: 24px 0;
}

.input-c {
  position: relative;
  margin-bottom: 20px;
}

.input-c i {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  z-index: 2;
  font-size: 20px;
}

.input-c :deep(.ivu-input) {
  height: 42px;
  padding-left: 40px;
  border-radius: 12px;
  border: 2px solid #e4e9f2;
  background: #f8fafc;
  font-size: 15px;
  transition: all 0.3s ease;
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(45, 140, 240, 0.2);
}

.input-c :deep(.ivu-input:focus) {
  transform: translateY(-2px);
  border-color: #2d8cf0;
  box-shadow: 0 8px 16px rgba(45, 140, 240, 0.15);
  animation: inputPulse 1.5s infinite;
}

.submit-btn {
  width: 100% !important;
  height: 42px !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-bottom: 16px !important;
  background: linear-gradient(45deg, #2d8cf0, #00d0ff, #2d8cf0) !important;
  background-size: 200% auto !important;
  animation: gradientShift 3s infinite;
  border: none !important;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.submit-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(45, 140, 240, 0.25);
}

.back-to-login {
  text-align: center;
  color: #666;
  font-size: 14px;
  margin-top: 16px;
}

.back-to-login span {
  color: #2d8cf0;
  cursor: pointer;
  margin-left: 4px;
  transition: all 0.3s ease;
}

.back-to-login span:hover {
  color: #00d0ff;
  text-decoration: underline;
}

.error {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
  text-align: left;
}

@keyframes shimmer {
  to {
    background-position: 200% center;
  }
}

@keyframes inputPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(45, 140, 240, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(45, 140, 240, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(45, 140, 240, 0);
  }
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style> 