<!--
 * @Description: 图片上传组件
 * @Author: Neo
 * @Date: 2019-12-11
 * @LastEditTime: 2021-11-17
 * @LastEditors: Neo
-->
<template>
  <div class="uploadWrapper">
    <vuedraggable
      class="vue-draggable"
      :class="{ single: isSingle, maxHidden: isMaxHidden }"
      v-model="imgList"
      tag="ul"
      draggable=".draggable-item"
      @start="onDragStart"
      @end="onDragEnd"
    >
      <!-- 拖拽元素 -->
      <li
        v-for="(item, index) in imgList"
        :key="item + index"
        class="draggable-item"
        :style="{ width: width + 'px', height: height + 'px' }"
      >
        <div class="img-wrapper" @click="handlePreview(item)">
          <img :src="item" :style="{ width: '100%', height: '100%', objectFit: 'cover' }" />
        </div>
        <div class="shadow" @click.stop="onRemoveHandler(index)">
          <Icon type="md-trash" />
        </div>
      </li>
      <!-- 上传按钮 -->
      <Upload
        ref="uploadRef"
        class="uploadBox"
        :style="{ width: width + 'px', height: height + 'px' }"
        :action="'https://back.bjca.xyz/diary/file/upload'"
        :headers="headers"
        :accept="'.jpg,.jpeg,.png,.gif'"
        :show-upload-list="false"
        :multiple="!isSingle"
        :max-size="size"
        :before-upload="beforeUpload"
        :on-success="onSuccessUpload"
        :on-exceeded-size="onExceed"
        :disabled="isUploading"
      >
        <div class="uploadIcon" :class="{ 'disabled': isUploading }">
          <Icon type="md-add" size="20" />
          <span class="uploading" v-show="isUploading">正在上传...</span>
          <span
            v-if="!isUploading && limit && limit!==99 && !isSingle"
            class="limitTxt"
          >最多{{ limit }}张</span>
        </div>
      </Upload>
    </vuedraggable>

    <!-- 修改预览 Modal -->
    <Modal 
      v-model="previewVisible" 
      footer-hide
      :styles="modalStyles"
      class-name="img-preview-modal"
      :mask-closable="true"
      :transition-names="['fade', 'fade']"
    >
      <!-- 添加左右切换按钮 -->
      <div class="preview-nav prev" @click="prevImage" v-show="imgList.length > 1">
        <Icon type="ios-arrow-back" size="36"/>
      </div>
      <div class="preview-nav next" @click="nextImage" v-show="imgList.length > 1">
        <Icon type="ios-arrow-forward" size="36"/>
      </div>
      
      <img 
        :src="previewImage" 
        :style="previewImageStyle"
        @load="handleImageLoad"
      />
      
      <!-- 添加图片计数器 -->
      <div class="preview-counter" v-if="imgList.length > 1">
        {{ currentIndex + 1 }} / {{ imgList.length }}
      </div>
    </Modal>
  </div>
</template>

<script>
import vuedraggable from 'vuedraggable' // 一款vue拖拽插件
import lrz from 'lrz' // 一款图片压缩插件
import utils from './utils'
import { Message, Modal } from 'view-design' // 引入 Modal

export default {
    name: 'ImgUpload',

    props: {
    // 图片数据(图片url组成的数组) 通过v-model传递
        value: {
            type: Array,
            default() {
                return []
            },
        },
        // 限制上传的图片数量
        limit: {
            type: Number,
            default: 9,
        },
        // 限制上传图片的文件大小(kb)
        size: {
            type: Number,
            default: 4096,
        },
        // 是否是单图上传(单图上传就是已传图片和上传按钮重叠)
        isSingle: {
            type: Boolean,
            default: false,
        },
        // 是否使用图片压缩
        useCompress: {
            type: Boolean,
            default: false,
        },
        // 图片显示的宽度(px)
        width: {
            type: Number,
            default: 100,
        },
        // 图片显示的高度(px)
        height: {
            type: Number,
            default: 100,
        },
    },

    data() {
        return {
            headers: {
                // 从本地存储获取 token
                authorization: localStorage.getItem('token'),
            },
            isUploading: false, // 正在上传状态
            isFirstMount: true, // 控制防止重复回显
            previewVisible: false,  // 添加预览弹窗控制
            previewImage: '',       // 添加预览图片地址
            modalStyles: {
                top: '50px',
                maxWidth: '1200px',
                width: '90%'
            },
            previewImageStyle: {
                width: '100%',
                height: 'auto',
                maxHeight: 'calc(90vh - 100px)',
                objectFit: 'contain',
                opacity: 0,  // 初始设置为透明
                transition: 'opacity 0.3s ease'  // 添加渐变效果
            },
            imageLoaded: false,
            currentIndex: 0, // 添加当前预览图片索引
        }
    },

    computed: {
    // 图片数组数据
        imgList: {
            get() {
                return this.value
            },
            set(val) {
                if (val.length < this.imgList.length) {
                    // 判断是删除图片时同步el-upload数据
                    this.syncElUpload(val)
                }
                // 同步v-model
                this.$emit('input', val)
            },
        },
        // 控制达到最大限制时隐藏上传按钮
        isMaxHidden() {
            return this.imgList.length >= this.limit
        },
    },

    watch: {
        value: {
            handler(val) {
                if (this.isFirstMount && this.value.length > 0) {
                    this.syncElUpload()
                }
            },
            deep: true,
        },
    },

    mounted() {
      if (this.value.length > 0) { this.syncElUpload() }
        window.addEventListener('keydown', this.handleKeyDown);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.handleKeyDown);
    },

    methods: {
    // 同步el-upload数据
        syncElUpload(val) {
            const imgList = val || this.imgList
            if (this.$refs.uploadRef) {
                // 修改这里，直接设置 fileList
                this.$refs.uploadRef.fileList = imgList.map((v, i) => ({
                    name: 'pic' + i,
                    url: v,
                    status: 'finished',
                    uid: utils.createUniqueString(),
                }))
            }
            this.isFirstMount = false
        },
        // 上传图片之前
        beforeUpload(file) {
            this.isFirstMount = false
            const isValidImageType = (file) => /^image\//.test(file.type)
            
            if (!isValidImageType(file)) {
                Message.error('请上传图片')
                return false
            }

            if (this.useCompress) {
                return new Promise((resolve, reject) => {
                    lrz(file, { width: 1920 }).then((rst) => {
                        if (utils.validImgUpload(rst.file, this.size)) {
                            this.isUploading = true
                            resolve(rst.file)
                        } else {
                            reject(new Error('File size exceeds limit'))
                        }
                    }).catch(() => {
                        reject(new Error('Compression failed'))
                    })
                })
            }

            if (utils.validImgUpload(file, this.size)) {
                this.isUploading = true
                return true
            }

            return false
        },
        // 上传完单张图片
        onSuccessUpload(res, file) {
            if (res.retVal) {
                if (this.imgList.length < this.limit) {
                    // 直接更新图片列表
                    this.imgList = [...this.imgList, res.retVal]
                }
            } else {
                Message.error(res.ms)
            }
            this.isUploading = false
        },
        // 移除单张图片
        onRemoveHandler(index) {
            Modal.confirm({
                title: '提示',
                content: '确定删除该图片?',
                onOk: () => {
                    const newImgList = [...this.imgList]
                    newImgList.splice(index, 1)
                    this.imgList = newImgList
                    this.$emit('removeImg', this.imgList[index])
                    // 同步更新 Upload 组件的文件列表
                    this.syncElUpload(newImgList)
                }
            })
        },
        // 超限
        onExceed() {
            Message.warning(`图片超限，最多可上传${this.limit}张图片`)
        },
        onDragStart(e) {
            e.target.classList.add('hideShadow')
        },
        onDragEnd(e) {
            e.target.classList.remove('hideShadow')
        },
        // 添加预览方法
        handlePreview(url) {
            this.currentIndex = this.imgList.findIndex(img => img === url);
            this.previewImage = url;
            this.previewVisible = true;
            this.imageLoaded = false;
        },

        // 上一张图片
        prevImage() {
            this.imageLoaded = false;
            this.currentIndex = (this.currentIndex - 1 + this.imgList.length) % this.imgList.length;
            this.previewImage = this.imgList[this.currentIndex];
        },

        // 下一张图片
        nextImage() {
            this.imageLoaded = false;
            this.currentIndex = (this.currentIndex + 1) % this.imgList.length;
            this.previewImage = this.imgList[this.currentIndex];
        },

        // 添加键盘事件监听
        handleKeyDown(e) {
            if (!this.previewVisible) return;
            
            switch(e.key) {
                case 'ArrowLeft':
                    this.prevImage();
                    break;
                case 'ArrowRight':
                    this.nextImage();
                    break;
                case 'Escape':
                    this.previewVisible = false;
                    break;
            }
        },

        // 添加图片加载完成的处理
        handleImageLoad() {
            this.$nextTick(() => {
                this.previewImageStyle.opacity = 1;
                this.imageLoaded = true;
            });
        },
    },

    components: { vuedraggable },
}
</script>

<style lang="less" scoped>
:deep(.ivu-upload) {
  width: 100%;
  height: 100%;
}

// 上传按钮
.uploadIcon {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #c0ccda;
  background-color: #fbfdff;
  border-radius: 6px;
  font-size: 20px;
  color: #999;
  cursor: pointer;

  .limitTxt,
  .uploading {
    position: absolute;
    bottom: 10%;
    left: 0;
    width: 100%;
    font-size: 14px;
    text-align: center;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.7;
    background-color: #f5f7fa;
  }
}

// 拖拽
.vue-draggable {
  display: flex;
  flex-wrap: wrap;

  .draggable-item {
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid #ddd;
    border-radius: 6px;
    position: relative;
    overflow: hidden;

    .el-image {
      width: 100%;
      height: 100%;
    }
    .shadow {
      position: absolute;
      top: 0;
      right: 0;
      background-color: rgba(0,0,0,.5);
      opacity: 0;
      transition: opacity .3s;
      color: #fff;
      font-size: 20px;
      line-height: 20px;
      padding: 2px;
      cursor: pointer;
    }
    &:hover {
      .shadow {
        opacity: 1;
      }
    }
  }
  &.hideShadow {
    .shadow {
      display: none;
    }
  }
  &.single {
    overflow: hidden;
    position: relative;

    .draggable-item {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }
  &.maxHidden {
    .uploadBox {
      display: none;
    }
  }
}

// 修改图标相关样式
.shadow {
  i {
    font-size: 20px;
  }
}

.img-wrapper {
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
    
    &:hover {
      transform: scale(1.05);
    }
  }
}

.draggable-item {
  margin-right: 5px;
  margin-bottom: 5px;
  border: 1px solid #ddd;
  border-radius: 6px;
  position: relative;
  overflow: hidden;

  .shadow {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0,0,0,.5);
    opacity: 0;
    transition: opacity .3s;
    color: #fff;
    font-size: 20px;
    line-height: 20px;
    padding: 2px;
    cursor: pointer;
    z-index: 1;  // 确保删除按钮在图片上层
  }
  
  &:hover {
    .shadow {
      opacity: 1;
    }
  }
}

// 添加预览相关样式
:deep(.img-preview-modal) {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 50px;
    padding-bottom: 50px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .ivu-modal-content {
    background-color: transparent;
    box-shadow: none;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .ivu-modal-body {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
  }
}

// 优化动画效果
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

// 遮罩层动画
:deep(.ivu-modal-mask) {
  backdrop-filter: blur(2px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

// 优化关闭按钮
:deep(.ivu-modal-close) {
  top: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  
  &:hover {
    background: rgba(255, 255, 255, 0.95);
    transform: rotate(90deg);
  }

  .ivu-icon-ios-close {
    font-size: 24px;
    color: #666;
    transition: color 0.3s ease;
    
    &:hover {
      color: #333;
    }
  }
}

// 优化遮罩层样式
:deep(.ivu-modal-wrap) {
  display: flex;
  align-items: center;
  justify-content: center;
  
  .ivu-modal {
    top: 0 !important;
    transform: none;
    margin: auto;
  }
}

// 添加性能优化
:deep(.ivu-modal),
:deep(.ivu-modal-content),
:deep(.ivu-modal-body),
img {
  will-change: transform, opacity;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

// 添加导航按钮样式
.preview-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 44px;
  height: 44px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 1000;
  
  &:hover {
    background: rgba(255, 255, 255, 0.95);
    transform: translateY(-50%) scale(1.1);
  }
  
  &.prev {
    left: 20px;
  }
  
  &.next {
    right: 20px;
  }
  
  .ivu-icon {
    color: #666;
    transition: color 0.3s ease;
  }
  
  &:hover .ivu-icon {
    color: #333;
  }
}

// 添加计数器样式
.preview-counter {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 14px;
}

// 优化图片切换动画
:deep(.ivu-modal-body) img {
  transition: opacity 0.3s ease;
}

// 添加触摸滑动支持的样式
.img-preview-modal {
  touch-action: pan-x pan-y;
  user-select: none;
}
</style>
