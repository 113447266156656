<template>
  <div class="cropper-content">
    <div class="cropper-box">
      <div class="cropper">
        <vue-cropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.outputSize"
          :outputType="option.outputType"
          :info="option.info"
          :canScale="option.canScale"
          :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :fixed="option.fixed"
          :fixedNumber="option.fixedNumber"
          :full="option.full"
          :fixedBox="false"
          :canMove="true"
          :canMoveBox="true"
          :original="option.original"
          :centerBox="true"
          :height="option.height"
          :infoTrue="option.infoTrue"
          :maxImgSize="option.maxImgSize"
          :enlarge="option.enlarge"
          :mode="option.mode"
          @realTime="realTime"
          @imgLoad="imgLoad"
        >
        </vue-cropper>
      </div>
      <div class="footer-btn">
        <div class="scope-btn">
          <label class="btn" for="uploads">选择图片</label>
          <input
            type="file"
            id="uploads"
            style="position: absolute; clip: rect(0 0 0 0)"
            accept="image/png, image/jpeg, image/gif"
            @change="selectImg"
          />
          <el-button
            size="mini"
            type="danger"
            plain
            icon="el-icon-zoom-in"
            @click="changeScale(1)"
            >放大</el-button
          >
          <el-button
            size="mini"
            type="danger"
            plain
            icon="el-icon-zoom-out"
            @click="changeScale(-1)"
            >缩小</el-button
          >
          <el-button size="mini" type="danger" plain @click="rotateLeft"
            >↺ 左旋转</el-button
          >
          <el-button size="mini" type="danger" plain @click="rotateRight"
            >↻ 右旋转</el-button
          >
        </div>
      </div>
    </div>
    <div class="show-preview">
      <div :style="previews.div" class="preview">
        <img :src="previews.url" :style="previews.img" />
      </div>
    </div>
  </div>
</template>
  
  <script>
import { uploadFile } from "@/api/file";
import { VueCropper } from "vue-cropper";

export default {
  name: "CropperImage",
  components: {
    VueCropper,
  },
  props: {
    Name: String,
    defaultImg: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      name: this.Name,
      previews: {
        url: this.defaultImg
      },
      option: {
        img: this.defaultImg,
        outputSize: 1,
        outputType: "jpeg",
        info: true,
        canScale: true,
        autoCrop: true,
        autoCropWidth: 300, // 调整选择框宽度
        autoCropHeight: 300, // 调整选择框高度
        fixed: false, // 允许裁剪框调整大小
        fixedNumber: [1, 1],
        full: false,
        fixedBox: false, // 允许裁剪框调整大小
        canMove: true, // 允许裁剪框移动
        canMoveBox: true, // 允许裁剪框拖动
        original: false,
        centerBox: true,
        height: true,
        infoTrue: false,
        maxImgSize: 3000,
        enlarge: 1,
        mode: "300px 300px",
      },
    };
  },
  watch: {
    defaultImg: {
      handler(newVal) {
        if (newVal) {
          this.option.img = newVal;
          this.previews.url = newVal;
        }
      },
      immediate: true
    }
  },
  methods: {
    imgLoad(msg) {
      console.log("工具初始化函数=====" + msg);
    },
    changeScale(num) {
      this.$refs.cropper.changeScale(num || 1);
    },
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    realTime(data) {
      this.previews = data;
    },
    selectImg(e) {
      const file = e.target.files[0];
      if (!/\.(jpg|jpeg|png|gif)$/i.test(file.name)) {
        this.$message({
          message: "图片类型要求：jpeg、jpg、png、gif",
          type: "error",
        });
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        this.option.img = data;
        this.previews.url = data;
      };
      reader.readAsDataURL(file);
    },
    async uploadImg() {
      return new Promise((resolve, reject) => {
        this.$refs.cropper.getCropBlob(async (data) => {
          try {
            const fileName = "cropped_image.jpeg";
            const file = new File([data], fileName, { type: "image/jpeg" });

            const res = await uploadFile(file);
            if (res.success) {
              resolve(res.data.url); // 假设API返回的是 {success: true, data: {url: '图片URL'}}
            } else {
              reject(new Error(res.message || '上传失败'));
            }
          } catch (error) {
            console.error(error);
            reject(error);
            this.$message({
              message: "上传失败，请稍后再试。",
              type: "error",
            });
          }
        });
      });
    },
    clearContent() {
      this.option.img = "";
      this.previews = {};
    },
  },
  beforeDestroy() {
    this.clearContent();
  },
};
</script>
  
  <style scoped lang="scss">
.cropper-content {
  display: flex;
  justify-content: flex-end;

  .cropper-box {
    flex: 1;
    width: 100%;

    .cropper {
      width: auto;
      height: 300px;
    }
  }

  .show-preview {
    flex: 1;
    display: flex;
    justify-content: center;

    .preview {
      overflow: hidden;
      border: 1px solid #67c23a;
      background: #cccccc;
    }
  }
}

.footer-btn {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;

  .scope-btn {
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
  }

  .upload-btn {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .btn {
    outline: none;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    transition: 0.1s;
    font-weight: 500;
    padding: 8px 15px;
    font-size: 12px;
    border-radius: 3px;
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    margin-right: 10px;
  }

  .btn-warning {
    background-color: #e6a23c;
    border-color: #e6a23c;
  }
}
</style>
  