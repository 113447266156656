<template>
  <div class="user-profile">
    <Card class="user-card">
      <div class="user-info">
        <div @click="showAvatarPreview">
          <Avatar :size="80" :src="user.avatar" class="user-avatar"></Avatar>
        </div>
        <div class="user-details">
          <h2>{{ user.name }}</h2>
          <p>{{ user.email }}</p>
          <Tag color="blue">{{ user.role }}</Tag>
          <div v-if="user.status" class="status">{{ user.status }}</div>
        </div>
      </div>
    </Card>

    <Modal
      width="1000"
      v-model="avatarVisible"
      title="预览头像"
      @on-ok="confirmAvatar"
      @on-cancel="cancelAvatar"
    >
      <avatar-upload
        ref="avatarUpload"
        :defaultImg="user.avatar || ''"
      ></avatar-upload>
    </Modal>

    <Tabs type="card" class="user-tabs" @on-click="handleTabChange">
      <TabPane label="个人信息" name="person">
        <Form :model="user" :label-width="80">
          <FormItem label="姓名">
            <Input v-model="user.name"></Input>
          </FormItem>
          <FormItem label="邮箱">
            <Input v-model="user.email"></Input>
          </FormItem>
          <FormItem label="角色">
            <Input v-model="user.role"></Input>
          </FormItem>
          <FormItem label="状态">
            <Input v-model="user.status"></Input>
          </FormItem>
          <FormItem>
            <Button type="primary" @click="updateUserInfo">更新信息</Button>
          </FormItem>
        </Form>
      </TabPane>

      <TabPane label="修改密码" name="updatePassword">
        <Form :model="passwordForm" :label-width="80">
          <FormItem label="旧密码">
            <Input v-model="passwordForm.oldPassword" type="password"></Input>
          </FormItem>
          <FormItem label="新密码">
            <Input v-model="passwordForm.newPassword" type="password"></Input>
          </FormItem>
          <FormItem label="确认密码">
            <Input
              v-model="passwordForm.confirmPassword"
              type="password"
            ></Input>
          </FormItem>
          <FormItem>
            <Button type="primary" @click="changePassword">修改密码</Button>
          </FormItem>
        </Form>
      </TabPane>

      <TabPane label="粉丝列表" name="fans">
        <Table :columns="userColumns" :data="followers">
          <template slot-scope="{ row }">
            <Avatar
              :src="row.avatar"
              size="small"
              style="margin-right: 8px"
            ></Avatar>
            {{ row.name }}
            <Tag
              :color="row.status === '在线' ? 'green' : 'gray'"
              style="margin-left: 8px"
              >{{ row.status }}</Tag
            >
            <Button
              type="text"
              @click="followUser(row.id)"
              style="float: right"
              >{{ row.following ? "取消关注" : "关注" }}</Button
            >
          </template>
        </Table>
      </TabPane>

      <TabPane label="关注列表" name="follow">
        <Table :columns="userColumns" :data="following">
          <template slot-scope="{ row }">
            <Avatar
              :src="row.avatar"
              size="small"
              style="margin-right: 8px"
            ></Avatar>
            {{ row.name }}
            <Tag
              :color="row.status === '在线' ? 'green' : 'gray'"
              style="margin-left: 8px"
              >{{ row.status }}</Tag
            >
            <Button
              type="text"
              @click="unfollowUser(row.id)"
              style="float: right"
              >取消关注</Button
            >
          </template>
        </Table>
      </TabPane>

      <TabPane label="动态" name="dynamic">
        <DiaryList v-if="activeTab === 'dynamic'"></DiaryList>
      </TabPane>
    </Tabs>
  </div>
</template>
  
  <script>
import AvatarUpload from "@/components/user/AvatarUpload.vue";
import DiaryList from "@/components/userHome/DiaryList.vue";
import { userInfo, updateUserInfo, updatePassword } from "@/api/user.js";

export default {
  data() {
    return {
      activeTab: "person",
      user: {
        avatar: "",
        name: "",
        email: "",
        role: "",
        status: "",
      },
      avatarVisible: false,
      passwordForm: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      followers: [],
      following: [],
      userColumns: [
        { title: "用户名", key: "name" },
        { title: "状态", key: "status" },
      ],
    };
  },
  components: {
    AvatarUpload,
    DiaryList,
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    async getUserInfo() {
      try {
        const res = await userInfo({});
        if (res.retVal) {
          this.user = {
            avatar: res.retVal.userHead,
            name: res.retVal.username,
            email: res.retVal.email,
            role: res.retVal.isAdmin === 'true' ? '管理员' : '普通用户',
            status: res.retVal.status || '在线',
          };
          this.followers = res.retVal.fans || [];
          this.following = res.retVal.follows || [];
        }
      } catch (error) {
        this.$Message.error('获取用户信息失败');
      }
    },
    async updateUserInfo() {
      try {
        await this.$Message.loading({
          content: '正在更新...',
          duration: 1
        });
        
        const params = {
          username: this.user.name,
          email: this.user.email,
          status: this.user.status
        };
        
        const res = await updateUserInfo(params);
        if (res.success) {
          this.$Message.success('用户信息已更新！');
          const currentUser = JSON.parse(localStorage.getItem('currentUser'));
          currentUser.username = this.user.name;
          currentUser.email = this.user.email;
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
        } else {
          this.$Message.error(res.message || '更新失败，请重试！');
        }
      } catch (error) {
        this.$Message.error('更新失败，请重试！');
      }
    },
    async changePassword() {
      if (this.passwordForm.newPassword !== this.passwordForm.confirmPassword) {
        this.$Message.error('两次输入的新密码不一致！');
        return;
      }
      
      if (this.passwordForm.newPassword.length < 6) {
        this.$Message.error('密码长度不能少于6位！');
        return;
      }
      
      try {
        await this.$Message.loading({
          content: '正在修改密码...',
          duration: 1
        });
        
        const params = {
          oldPassword: this.passwordForm.oldPassword,
          newPassword: this.passwordForm.newPassword
        };
        
        const res = await updatePassword(params);
        if (res.success) {
          this.$Message.success('密码修改成功！');
          this.passwordForm = {
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
          };
        } else {
          this.$Message.error(res.message || '密码修改失败，请重试！');
        }
      } catch (error) {
        this.$Message.error('密码修改失败，请重试！');
      }
    },
    handleTabChange(name) {
      this.activeTab = name;
    },
    showAvatarPreview() {
      this.avatarVisible = true;
    },
    cancelAvatar() {
      this.avatarVisible = false;
      if (this.$refs.avatarUpload) {
        this.$refs.avatarUpload.clearContent();
        this.$refs.avatarUpload.option.img = this.user.avatar;
      }
    },
    async confirmAvatar() {
      try {
        const avatarUrl = await this.$refs.avatarUpload.uploadImg();
        
        if (avatarUrl) {
          const params = {
            userHead: avatarUrl
          };
          
          const res = await updateUserInfo(params);
          if (res.success) {
            this.user.avatar = avatarUrl;
            this.$Message.success('头像更新成功！');
            
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));
            currentUser.userHead = avatarUrl;
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
          } else {
            this.$Message.error(res.message || '头像更新失败！');
          }
        }
      } catch (error) {
        this.$Message.error('头像上传失败，请重试！');
      }
      
      this.avatarVisible = false;
    },
    followUser(id) {
      const user = this.followers.find((user) => user.id === id);
      if (user) {
        user.following = !user.following;
        this.$Message.success(
          user.following ? "已关注该用户！" : "已取消关注该用户！"
        );
      }
    },
    unfollowUser(id) {
      const user = this.following.find((user) => user.id === id);
      if (user) {
        this.following = this.following.filter((user) => user.id !== id);
        this.$Message.success("已取消关注该用户！");
      }
    },
    likeActivity(id) {
      this.$Message.success("已点赞该动态！");
    },
    commentActivity(id) {
      this.$Message.success("评论功能未实现");
    },
  },
};
</script>
  
  <style scoped>
.user-profile {
  padding: 20px;
  background: linear-gradient(135deg, #f8fafc, #f0f4ff);
  min-height: 100vh;
}

.user-card {
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(92, 103, 242, 0.1);
  margin-bottom: 24px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.user-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 30px rgba(92, 103, 242, 0.15);
}

.user-info {
  display: flex;
  align-items: center;
  padding: 24px;
}

.user-avatar {
  width: 100px;
  height: 100px;
  border: 3px solid #5c67f2;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.user-avatar:hover {
  transform: scale(1.05);
  box-shadow: 0 0 0 4px rgba(92, 103, 242, 0.2);
}

.user-details {
  margin-left: 24px;
}

.user-details h2 {
  font-size: 24px;
  font-weight: 600;
  color: #1a202c;
  margin-bottom: 8px;
}

.user-details p {
  color: #4a5568;
  margin-bottom: 12px;
}

.user-details .ivu-tag {
  padding: 4px 12px;
  border-radius: 20px;
  font-weight: 500;
}

.status {
  display: inline-block;
  padding: 4px 12px;
  background: #e6ffed;
  color: #52c41a;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
}

.user-tabs {
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  padding: 24px;
}

:deep(.ivu-tabs-nav-container) {
  font-size: 16px;
  font-weight: 500;
}

:deep(.ivu-tabs-ink-bar) {
  background-color: #5c67f2;
  height: 3px;
}

:deep(.ivu-tabs-tab) {
  padding: 12px 24px;
  margin-right: 24px;
  transition: all 0.3s ease;
}

:deep(.ivu-tabs-tab:hover) {
  color: #5c67f2;
}

:deep(.ivu-tabs-tab-active) {
  color: #5c67f2;
}

:deep(.ivu-form-item) {
  margin-bottom: 24px;
}

:deep(.ivu-input) {
  border-radius: 8px;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  transition: all 0.3s ease;
}

:deep(.ivu-input:hover) {
  border-color: #5c67f2;
}

:deep(.ivu-input:focus) {
  border-color: #5c67f2;
  box-shadow: 0 0 0 2px rgba(92, 103, 242, 0.2);
}

:deep(.ivu-btn) {
  border-radius: 8px;
  padding: 8px 24px;
  height: 40px;
  font-weight: 500;
  transition: all 0.3s ease;
}

:deep(.ivu-btn-primary) {
  background: linear-gradient(135deg, #5c67f2, #4a53d6);
  border: none;
}

:deep(.ivu-btn-primary:hover) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(92, 103, 242, 0.2);
  background: linear-gradient(135deg, #4a53d6, #3b44c5);
}

.table-container {
  margin-top: 16px;
}

:deep(.ivu-table) {
  border-radius: 12px;
  overflow: hidden;
}

:deep(.ivu-table th) {
  background: #f8fafc;
  font-weight: 600;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .user-info {
    flex-direction: column;
    text-align: center;
  }

  .user-details {
    margin-left: 0;
    margin-top: 16px;
  }

  .user-tabs {
    padding: 16px;
  }
}

/* 深色模式 */
@media (prefers-color-scheme: dark) {
  .user-profile {
    background: linear-gradient(135deg, #1a1f25, #111827);
  }

  .user-card,
  .user-tabs {
    background: #1f2937;
    border-color: rgba(255, 255, 255, 0.1);
  }

  .user-details h2 {
    color: #e2e8f0;
  }

  .user-details p {
    color: #94a3b8;
  }

  .status {
    background: rgba(82, 196, 26, 0.1);
  }
}
</style>