<!-- eslint-disable vue/no-parsing-error -->
<template>
  <Modal
    v-model="internalAddModal"
    title="发布日记"
    ok-text="发布"
    cancel-text="取消"
    @on-visible-change="handleVisibleChange"
    @on-ok="handleOk"
    @on-cancel="handleCancel"
  >
    <div class="publish-page">
      <Form :model="addDiary" :label-width="100" ref="form">
        <FormItem label="说点什么呢?">
          <Input
            type="textarea"
            v-model="addDiary.text"
            :autosize="{ minRows: 2, maxRows: 6 }"
          ></Input>
        </FormItem>


        <FormItem label="上传">
          <Row>
            <Col span="12" style="max-height: 400px; overflow-y: auto">
              <RadioGroup v-model="addDiary.uploadType">
                <Radio label="image">上传图片</Radio>
                <Radio label="video">上传视频</Radio>
              </RadioGroup>
            </Col>
          </Row>
          <Row v-if="addDiary.uploadType === 'image'">
            <Col span="24" style="overflow-y: auto">
              <ImgUpload v-model="addDiary.imgList" @removeImg="handleRemoveImg"/>
            </Col>
          </Row>
          <Row v-else>
            <Col span="24" style="max-height: 400px; overflow-y: auto">
              <VideoUpload
                v-model="addDiary.videoUrl"
                action="https://back.bjca.xyz/diary/file/upload"
                :headers="{
                  authorization: token
                }"
                :fileSize="20"
                @delete="deleteVideo"
                @error="handleUploadError"
              />
            </Col>
          </Row>
        </FormItem>

        <FormItem label="是否公开">
          <span>私密</span>
          &nbsp;
          <i-switch v-model="addDiary.isPublic" />
          &nbsp;

          <span>公开</span>
        </FormItem>

      </Form>
    </div>
  </Modal>
</template>

<script>
import { Message } from 'view-design'
import ImgUpload from '@/components/ImgUpload/index.vue'
import { addDiaryApi, deleteFileApi } from '@/api/diary'
import VideoUpload from '@/components/videoUpload/VideoUpload.vue'

export default {
    data() {
        return {
            internalAddModal: false,
            token: '',
            addDiary: {
                isPublic: true,
                text: '',
                videoUrl: '',
                imgList: [],
                uploadType: 'image',
            },
        }
    },
    watch: {
        addModal(val) {
            if (val) {
                this.internalAddModal = true
            } else {
                this.internalAddModal = false
            }
        },
    },
    props: {
        addModal: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ImgUpload,
        VideoUpload,
    },
    methods: {
        deleteVideo(url) {
            deleteFileApi({ url: url }).then(res => {
                if (res.code === 0) {
                    this.$Message.success('删除成功')
                } else {
                    this.$Message.error(res.msg)
                }
            })
        },
        handleRemoveImg(val) {
            deleteFileApi({ url: val }).then(res => {
                if (res.code === 0) {
                    Message.success('删除成功')
                } else {
                    Message.error(res.msg)
                }
            })
        },
        initAddDiary() {
            this.addDiary = {
                text: '',
                videoUrl: '',
                imgList: [],
                uploadType: 'image',
            }
        },
        handleCancel() {},
        handleVisibleChange(val) {
            if (val === false) {
                this.$emit('close-add-publish', this.val)
            }
        },
        handleOk() {
            addDiaryApi({
                isPublic: this.addDiary.isPublic ? 'true' : 'false',
                text: this.addDiary.text,
                videoUrl: this.addDiary.uploadType === 'video' ? this.addDiary.videoUrl : '',
                imgUrl: this.addDiary.uploadType === 'image' ? this.addDiary.imgList.join(';') : '',
            }).then(res => {
                if (res.code === 0) {
                    Message.success('发布成功')
                    this.$emit('update-diary')
                } else {
                    Message.error(res.msg)
                }
                this.initAddDiary()
            })
        },
        handleUploadError(error) {
            Message.error(error)
        },
    },
}
</script>

  <style scoped>
.publish-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
}
</style>
