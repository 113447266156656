<template>
  <div class="particle-container"></div>
</template>

<script>
export default {
  name: 'ParticleEffect',
  mounted() {
    document.addEventListener('click', this.createParticles)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.createParticles)
  },
  methods: {
    createParticles(event) {
      const container = document.querySelector('.particle-container')
      
      // 扩展忽略列表，包括更多的交互元素
      if (event.target.tagName === 'INPUT' || 
          event.target.tagName === 'BUTTON' || 
          event.target.tagName === 'A' ||
          event.target.tagName === 'TEXTAREA' ||
          event.target.tagName === 'SELECT' ||
          event.target.tagName === 'LABEL' ||
          event.target.closest('button') ||
          event.target.closest('a') ||
          event.target.closest('.ivu-btn') ||
          event.target.closest('.el-button') ||
          event.target.closest('[role="button"]') ||
          event.target.closest('.ivu-menu') ||
          event.target.closest('.ivu-menu-item') ||
          event.target.closest('.el-menu') ||
          event.target.closest('.el-menu-item') ||
          event.target.closest('.menu') ||
          event.target.closest('.nav') ||
          event.target.closest('[class*="menu"]') ||
          event.target.closest('[class*="nav"]') ||
          event.target.closest('[class*="btn"]') ||
          event.target.closest('[class*="button"]')) {
        return
      }
      
      const particles = 80 // 大幅增加粒子数量
      const colors = [
        '#FF0000', // 红色
        '#FF7F00', // 橙色
        '#FFFF00', // 黄色
        '#00FF00', // 绿色
        '#0000FF', // 蓝色
        '#4B0082', // 靛蓝
        '#9400D3', // 紫色
        '#FF1493', // 粉色
        '#00FFFF', // 青色
        '#FFD700'  // 金色
      ]

      const x = event.pageX
      const y = event.pageY

      // 创建中心爆炸点
      const centerFlash = document.createElement('div')
      centerFlash.classList.add('center-flash')
      centerFlash.style.left = x + 'px'
      centerFlash.style.top = y + 'px'
      container.appendChild(centerFlash)
      
      setTimeout(() => centerFlash.remove(), 300)

      for (let i = 0; i < particles; i++) {
        const particle = document.createElement('div')
        particle.classList.add('particle')
        
        const size = Math.random() * 4 + 2 // 更小的粒子
        const color = colors[Math.floor(Math.random() * colors.length)]
        const angle = (Math.random() * 360 * Math.PI) / 180
        const velocity = Math.random() * 150 + 50 // 降低速度
        
        particle.style.position = 'absolute'
        particle.style.left = x + 'px'
        particle.style.top = y + 'px'
        particle.style.width = size + 'px'
        particle.style.height = size + 'px'
        particle.style.backgroundColor = color
        particle.style.boxShadow = `0 0 ${size * 2}px ${color}`
        
        container.appendChild(particle)
        
        // 烟花爆炸效果动画
        const animation = particle.animate([
          {
            transform: 'translate(0, 0) scale(1)',
            opacity: 1
          },
          {
            transform: `translate(${Math.cos(angle) * velocity}px, ${Math.sin(angle) * velocity}px) scale(0.1)`,
            opacity: 0
          }
        ], {
          duration: Math.random() * 1200 + 1000, // 延长持续时间
          easing: 'cubic-bezier(0, .9, .57, 1)',
          delay: Math.random() * 100 // 添加随机延迟
        })
        
        animation.onfinish = () => {
          particle.remove()
        }
      }
    }
  }
}
</script>

<style scoped>
.particle-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
}

.particle {
  position: absolute;
  pointer-events: none;
  will-change: transform, opacity;
  border-radius: 50%;
  background: radial-gradient(circle at center, rgba(255, 255, 255, 0.8) 0%, currentColor 60%, transparent 100%);
}

/* 中心爆炸点效果 */
.center-flash {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  pointer-events: none;
  animation: flash 0.3s ease-out forwards;
}

@keyframes flash {
  0% {
    transform: scale(0.1);
    opacity: 1;
    box-shadow: 0 0 20px #fff, 0 0 40px #fff, 0 0 60px #fff;
  }
  100% {
    transform: scale(4);
    opacity: 0;
    box-shadow: 0 0 100px rgba(255, 255, 255, 0.1), 0 0 200px rgba(255, 255, 255, 0.1);
  }
}

/* 粒子发光效果 */
.particle::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background: inherit;
  filter: blur(4px);
  opacity: 0.7;
  z-index: -1;
}
</style>
