<template>
  <div class="diary">
    <UserModal :visible="showUserModal" :userId="selectedUserId" @close="showUserModal = false"></UserModal>
  <div class="user-info">

  <img :src="diary.userHead" alt="avatar" class="avatar" @click="openUserModal(diary.userId)" style="cursor: pointer;">
  <div class="name"><a href="#" style="color: inherit;text-decoration: none" @click.prevent="openUserModal(diary.userId)">{{ diary.createBy }}</a></div>
  <div class="ip">{{ diary.publishIp }}</div>
  <div class="time">{{ diary.createDatetime }}</div>
  </div>
  <div class="content">
  <div class="text">{{ diary.text }}</div>
  <div class="images" v-if="diary.diaryType === 'image'">
  <ImageList :imgs="diary.diaryPicture"></ImageList>
  </div>
  <div class="video" v-else-if="diary.diaryType === 'video'">
  <video :src="diary.videoUrl" controls></video>
  </div>
  </div>
  <div class="actions">
    <LikeButton @like-count-change="changeLike" :diaryId="diary.diaryId" :likeCount="diary.likeNum" :isLiked="diary.isLike"></LikeButton>
    <button class="comment-btn" @click="showComments = !showComments">评论</button>
  </div>
  <div>
    <Poptip v-if="diary.userId === currentUser.userId"
        confirm
        title="确认删除?"
        @on-ok="deleteDiary()"
        >
            <Button type="dashed" style="margin-top: 5px;">删除</Button>
        </Poptip>
</div>
  <div class="avatar-container" v-if="diary.likeNum > 0">
  <img v-for="(like, index) in diary.likes" :key="index" :src="like.userHead" class="avatar" @click="openUserModal(like.userId)" style="cursor: pointer;"></img>
  </div>
  <Comment :commentList="diary.comments" @change="handleShowInput" :isShowInput="showComments" :diaryId="diary.diaryId"></Comment>
  </div>
  </template>

<script>
import { Message } from 'view-design'
import ImageList from '@/components/diary/ImageList.vue'
import Comment from '@/components/comment.vue'
import LikeButton from '@/components/like/LikeButton.vue'
import UserModal from '@/components/user/UserModal.vue'
import { deleteDiaryApi } from '@/api/diary'

export default {
    props: {
        diary: Object,
    },
    components: {
        ImageList,
        Comment,
        LikeButton,
        UserModal,
    },
    data() {
        return {
            currentUser: {},
            showComments: false,
            showUserModal: false,
            selectedUserId: null,
        }
    },
    created() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
    },
    methods: {
        deleteDiary() {
            deleteDiaryApi({ diaryId: this.diary.diaryId }).then(res => {
                if (res.code === 0) {
                    this.$emit('delete-diary', this.diary.diaryId)
                } else {
                    Message.error(res.msg)
                }
            })
        },
        changeLike(eventData) {
            if (eventData.isLike) {
                this.diary.likeNum = this.diary.likeNum + 1
                const addLike = {
                    diaryId: this.diary.diaryId,
                    userId: this.currentUser.userId,
                    userHead: this.currentUser.userHead,
                }
                this.diary.likes.push(addLike)
            } else {
                this.diary.likeNum = this.diary.likeNum - 1
                for (let i = 0; i < this.diary.likes.length; i++) {
                    if (this.diary.likes[i].userId === this.currentUser.userId) {
                        this.diary.likes.splice(i, 1)
                        break
                    }
                }
            }
        },
        handleShowInput(value) {
            this.showComments = value
        },
        handleLike() {
            this.diary.isLike = !this.diary.isLike
            this.$nextTick(() => {
                const likeBtn = this.$refs.likeBtn
                likeBtn.classList.add('clicked')
                setTimeout(() => {
                    likeBtn.classList.remove('clicked')
                }, 200)
            })
        },
        openUserModal(userId) {
            this.selectedUserId = userId;
            this.showUserModal = true;
        },
    },
}
</script>

  <style scoped>
  .diary {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
  }

  .user-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  }

  .avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  }

  .name {
  font-weight: bold;
  margin-right: 10px;
  }

  .ip {
  margin-right: 10px;
  }

  .time {
  color: #999;
  }

  .content {
  margin-bottom: 10px;
  }

  .text {
  white-space: pre-wrap;
  }

  .images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  }

  .image {
  width: calc(33.33% - 10px);
  height: auto;
  margin-bottom: 10px;
  object-fit: cover;
  }

  .video video {
  width: 30%;
  height: auto;
  margin-bottom: 10px;
  }

  .actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  }

  .likes,
  .comments {
  display: flex;
  align-items: center;
  color: #999;
  justify-content: flex-start;
  }

  .likes i,
  .comments i {
  margin-right: 5px;
  }

  .comments {
  margin-left: 20px;
  }

  .likes-list,
  .comments-list {
  margin-top: 10px;
  }

  .likes-list hr,
  .comments-list hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 10px 0;
  }

  .avatars {
  display: flex;
  }

  .avatars .avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
  }

  .comment {
  margin-bottom: 10px;
  }

  .comment-text {
  font-weight: bold;
  }

  .comment-user {
  color: #999;
  }

  .avatar-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #999;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 8px;
  }

  .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 5px;
  }

  .comment-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 20px;
  padding: 5px 10px;
  color: #666;
  font-size: 14px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 91.8%;
  }

  .comment-btn:hover,
  .comment-btn:focus {
  outline: none;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  }
  .actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}


  </style>