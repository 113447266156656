import request from '@/utils/request';

// 创建书签
export function createBookmark(data) {
    return request.post('/diary/bookmarks/createBookmark', data);
}

// 修改书签
export function modifyBookmark(data) {
    return request.post('/diary/bookmarks/modifyBookmark', data);
}

// 删除书签
export function deleteBookmark(bookmarkId) {
    return request.get('/diary/bookmarks/delete', { params: { bookmarkId } });
}

// 分页查询书签
export function findPage(params) {
    return request.get('/diary/bookmarks/findPage', { params });
}

// 增加书签浏览次数
export function addView(bookmarkId) {
    return request.get('/diary/bookmarks/addView', { params: { bookmarkId } });
}

// 获取书签分类列表
export function getCategoryList() {
    return request.get('/diary/bookmarks/categoryList');
} 

export function importBookmarks(formData) {
    // 发送 POST 请求，传递文件
    return request.post('/diary/bookmarks/importBookmarks', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // 设置请求头为 multipart/form-data
      },
    });
  }
export function topBookmark(params) {
    return request.get('/diary/bookmarks/topBookmark', { params });
}
