<template>
  <div class="login-vue" :style="bg">
    <div class="floating-shapes">
      <div class="shape"></div>
      <div class="shape"></div>
      <div class="shape"></div>
    </div>

    <div class="light-effects">
      <div class="light-beam"></div>
    </div>

    <div class="container">
      <template v-if="currentView === 'login'">
        <div class="login-header">
          <div class="logo-animation">
            <div class="logo-circle"></div>
            <div class="logo-ring"></div>
          </div>
          <h1 class="title">不见长安</h1>
          <p class="subtitle">登录您的账户继续访问</p>
        </div>

        <div class="login-body">
          <div v-if="!showQrCode" class="login-form">
            <div class="input-group">
              <div class="input-c">
                <i class="ivu-icon ios-contact"></i>
                <Input v-model="account" placeholder="用户名" clearable />
                <p class="error">{{ accountError }}</p>
              </div>

              <div class="input-c">
                <i class="ivu-icon md-lock"></i>
                <Input type="password" v-model="pwd" placeholder="密码"
                       clearable @keyup.enter.native="submit" />
                <p class="error">{{ pwdError }}</p>
              </div>
            </div>

            <div class="remember-forgot">
              <Checkbox>记住我</Checkbox>
              <span @click="forgetPwd" class="forgot-link">忘记密码？</span>
            </div>
          </div>

          <div v-else class="qr-login-section">
            <div class="qr-code-container">
              <img :src="qrCode" class="qr-code" alt="二维码" @click="scanQRCode" title="点击刷新二维码" />
              <p class="qr-tip">使用手机扫码登录</p>
            </div>
          </div>
        </div>

        <div class="login-footer">
          <div class="primary-actions">
            <Button v-if="!showQrCode"
                    class="submit-btn"
                    type="primary"
                    @click="submit"
                    :loading="isShowLoading">
              {{ isShowLoading ? '登录中...' : '登录' }}
            </Button>
            <Button v-else
                    class="submit-btn"
                    @click="showLoginForm">
              返回密码登录
            </Button>
          </div>

          <div class="secondary-actions">
            <Button v-if="!showQrCode"
                    class="action-btn qr-btn"
                    @click="scanQRCode">
              <i class="ivu-icon ios-qr-scanner"></i>
              扫码登录
            </Button>
            <Button class="action-btn qq-btn"
                    @click="openQQLogin">
              <i class="ivu-icon logo-qq"></i>
              QQ登录
            </Button>
          </div>

          <div class="register-link">
            还没有账号？ <span @click="register">立即注册</span>
          </div>
        </div>
      </template>
      
      <template v-else-if="currentView === 'register'">
        <RegisterCard @switch="switchView" />
      </template>
      
      <template v-else-if="currentView === 'forgot-password'">
        <ForgotPasswordCard @switch="switchView" />
      </template>
    </div>

    <div class="icp-footer">
      <a href="https://beian.miit.gov.cn" 
         target="_blank" 
         rel="noopener noreferrer">
        浙ICP备2022033133号
      </a>
    </div>
  </div>
</template>

<script>
import QC from "../utils/qqApi.js";
import { Message } from "view-design";
import JSEncrypt from "jsencrypt";
import { login, getQrCode, userInfo } from "@/api/user.js";
import { connectWebSocket } from "../utils/WebSocketUtil";
import RegisterCard from './RegisterCard.vue'
import ForgotPasswordCard from './ForgotPasswordCard.vue'

export default {
  name: "login",
  components: {
    RegisterCard,
    ForgotPasswordCard
  },
  data() {
    return {
      userScan: {},
      account: "",
      pwd: "",
      accountError: "",
      pwdError: "",
      isShowLoading: false,
      bg: {},
      showQrCode: false,
      qrCode: "",
      redirect: undefined,
      ws: null,
      currentView: 'login',
    };
  },
  created() {
    this.bg.backgroundImage =
      "url(https://picsum.photos/2300/1100)";
  },
  watch: {
    $route: {
      handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  methods: {
    handleMessage(message) {
      if (
        message.scanId == this.userScan.scanId &&
        message.status == "CONFIRMED"
      ) {
        Message.success({
          content: "登录成功!",
        });
        localStorage.setItem("token", message.token);
        this.closeWebSocket();
        userInfo({}).then((result) => {
          localStorage.setItem("currentUser", JSON.stringify(result.retVal));
          this.$router.push({ path: this.redirect || "/" });
        });
      }
    },
    register() {
      this.currentView = 'register';
    },
    forgetPwd() {
      this.currentView = 'forgot-password';
    },
    switchView(view) {
      this.currentView = view;
    },
    submit() {
      let pubKey =
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCkPhdjvFe62qpyqA4zHRNJZwln+zgOxKAZ5c2H8TmqAK9XPemyU0UR0sZnefy/8VNaF2qiTepKynZVNgc2glJwDwhW+lUEeqYeT+DIcAdi+yx9MNPhkeMQ+8JJ92kyHio48m+On+NrOFjY1ZMZtQRNeVmeniCK2N30ijjFvT+IAQIDAQAB";
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(pubKey);
      login({
        username: this.account,
        password: encrypt.encrypt(this.pwd),
        isRemember: false,
      }).then((res) => {
        Message.success({
          content: res.retVal,
        });
        userInfo({}).then((result) => {
          localStorage.setItem("currentUser", JSON.stringify(result.retVal));
          this.$router.push({ path: this.redirect || "/" });
        });
      });
    },
    scanQRCode() {
      getQrCode().then((res) => {
        const userScanDTO = res.retVal;
        this.qrCode = "data:image/png;base64," + userScanDTO.qrcode;
        this.showQrCode = true;
        this.userScan = userScanDTO.userScanLogin;
        connectWebSocket.call(
          this,
          "wss://www.bjca.xyz/diary/subWebsocket/" +
          userScanDTO.userScanLogin.scanId,
          this.handleMessage
        );
      });
      this.account = "";
      this.pwd = "";
    },
    closeWebSocket() {
      if (this.ws && this.ws.readyState === WebSocket.OPEN) {
        this.ws.close();
      }
    },
    showLoginForm() {
      this.showQrCode = false;
      this.closeWebSocket();
    },
    openQQLogin() {
      QC.Login.showPopup({
        appId: '102029917',
        redirectURI: 'https://www.bjca.xyz/#/qq-login-callback'
      })
    },
  },
};
</script>

<style scoped>
.login-vue {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px 20px 20px;
  backdrop-filter: blur(15px);
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.5)
  ),
  linear-gradient(
    45deg,
    rgba(45, 140, 240, 0.2),
    rgba(0, 208, 255, 0.2),
    rgba(76, 217, 105, 0.2)
  );
  animation: gradientBG 20s ease infinite;
  background-size: 150% 150%;
}

.login-vue .container {
  width: 400px;
  margin: 20px auto 0;
  min-height: auto;
  height: auto;
  padding: 0;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  z-index: 1;
  backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  transform: translateY(0);
  animation: containerAppear 0.6s ease-out;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform, box-shadow;
}

.login-vue .container:hover {
  transform: translateY(-5px);
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.2);
}

.login-header {
  padding: 25px 30px 15px;
  text-align: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4e9f2 100%);
}

.logo-container {
  margin-bottom: 20px;
}

.logo {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.title {
  font-size: 28px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 8px;
  background: linear-gradient(45deg, #2d8cf0, #00d0ff, #2d8cf0);
  background-size: 200% auto;
  animation: shimmer 2s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  color: #666;
  font-size: 14px;
  margin-bottom: 0;
}

.login-body {
  padding: 15px 30px;
}

.input-group {
  margin-bottom: 24px;
}

.input-c {
  position: relative;
  margin-bottom: 20px;
}

.input-c i {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  z-index: 2;
  font-size: 20px;
}

.input-c .ivu-input {
  height: 42px;
  padding-left: 40px;
  border-radius: 12px;
  border: 2px solid #e4e9f2;
  background: #f8fafc;
  font-size: 15px;
  transition: all 0.3s ease;
  backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(45, 140, 240, 0.2);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.input-c .ivu-input:focus {
  transform: translateY(-2px);
  border-color: #2d8cf0;
  box-shadow: 0 8px 16px rgba(45, 140, 240, 0.15);
  box-shadow: 0 0 20px rgba(45, 140, 240, 0.2);
  border-color: #2d8cf0;
  animation: inputPulse 1.5s infinite;
}

@keyframes inputPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(45, 140, 240, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(45, 140, 240, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(45, 140, 240, 0);
  }
}

.remember-forgot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.forgot-link {
  color: #2d8cf0;
  cursor: pointer;
  font-size: 14px;
}

.login-footer {
  padding: 0 30px 25px;
}

.primary-actions {
  margin-bottom: 16px;
}

.submit-btn {
  width: 100% !important;
  height: 42px !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-bottom: 16px !important;
  background: linear-gradient(45deg, #2d8cf0, #00d0ff, #2d8cf0);
  background-size: 200% auto;
  animation: gradientShift 3s infinite;
  border: none;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  color: #fff;
}

.submit-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(45, 140, 240, 0.25);
}

.submit-btn:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(45, 140, 240, 0.2);
}

.secondary-actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin-bottom: 15px;
}

.action-btn {
  height: 38px !important;
  border-radius: 12px !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.action-btn i {
  margin-right: 8px;
  font-size: 18px;
}

.action-btn:hover {
  background: #f8fafc;
  border-color: #2d8cf0;
  color: #2d8cf0;
}

.action-btn:active {
  transform: translateY(1px);
  background: #f0f7ff;
}

.qr-login-section {
  text-align: center;
  padding: 15px 0;
}

.qr-code-container {
  background: #f8fafc;
  padding: 15px;
  border-radius: 16px;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease;
}

.qr-code {
  width: 140px;
  height: 140px;
  border-radius: 12px;
  margin-bottom: 8px;
}

.qr-tip {
  color: #666;
  font-size: 13px;
  margin: 0 0 4px 0;
}

.qr-refresh-tip {
  font-size: 12px;
  color: #999;
  margin: 4px 0 0 0;
}

.register-link {
  text-align: center;
  color: #666;
  font-size: 14px;
}

.register-link span {
  color: #2d8cf0;
  cursor: pointer;
  margin-left: 4px;
  transition: all 0.3s ease;
}

.register-link span:hover {
  color: #00d0ff;
  text-decoration: underline;
}

.register-link span:active {
  color: #2d8cf0;
}

.error {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
  text-align: left;
}

/* 添加响应式设计 */
@media (max-width: 500px) {
  .container {
    width: 100%;
    max-width: 400px;
    margin: 10px auto 0;
  }
  
  .login-header,
  .login-body,
  .login-footer {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .qr-code {
    width: 130px;
    height: 130px;
  }
}

/* 添加动画关键帧 */
@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* 添加浮动形状动画 */
.animated-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

.floating-shape {
  position: absolute;
  background: linear-gradient(45deg, rgba(45, 140, 240, 0.2), rgba(0, 208, 255, 0.2));
  border-radius: 50%;
  animation: float 20s infinite;
  backdrop-filter: blur(5px);
}

.shape1 {
  width: 200px;
  height: 200px;
  top: -150px;
  left: -150px;
  animation-delay: 0s;
  animation-duration: 40s;
}

.shape2 {
  width: 150px;
  height: 150px;
  top: 70%;
  right: -100px;
  animation-delay: -5s;
  background: linear-gradient(45deg, rgba(255, 102, 0, 0.2), rgba(255, 196, 0, 0.2));
  animation-duration: 45s;
}

.shape3 {
  width: 100px;
  height: 100px;
  bottom: 10%;
  left: 15%;
  animation-delay: -10s;
  background: linear-gradient(45deg, rgba(76, 217, 105, 0.2), rgba(90, 245, 132, 0.2));
  animation-duration: 50s;
}

.shape4 {
  width: 180px;
  height: 180px;
  top: 20%;
  right: 15%;
  animation-delay: -15s;
  background: linear-gradient(45deg, rgba(132, 90, 245, 0.2), rgba(214, 90, 245, 0.2));
  animation-duration: 55s;
}

/* 浮动动画 */
@keyframes float {
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
  }
  50% {
    transform: translate(5px, 5px) rotate(2deg) scale(1.01);
  }
  100% {
    transform: translate(0, 0) rotate(0deg) scale(1);
  }
}

/* 添加粒子效果 */
.particles {
  position: absolute;
  width: 100%;
  height: 100%;
}

.particles::before,
.particles::after {
  content: '';
  position: absolute;
  width: 3px;
  height: 3px;
  background: white;
  border-radius: 50%;
  box-shadow:
    0 0 10px white,
    100px 100px 10px white,
    200px 200px 10px white,
    300px 300px 10px white,
    400px 400px 10px white,
    500px 500px 10px white,
    600px 600px 10px white,
    700px 700px 10px white,
    800px 800px 10px white;
  animation: sparkle 5s linear infinite;
}

.particles::after {
  animation-delay: -2.5s;
}

@keyframes sparkle {
  0% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  50% {
    transform: scale(1) rotate(180deg);
    opacity: 0.5;
  }
  100% {
    transform: scale(0) rotate(360deg);
    opacity: 0;
  }
}

/* 添加容器入场动画 */
@keyframes containerAppear {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 添加禁用状态样式 */
.submit-btn[disabled],
.action-btn[disabled] {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none !important;
}

/* 添加加载状态样式 */
.submit-btn.ivu-btn-loading {
  pointer-events: none;
  position: relative;
  opacity: 0.8;
}

.qr-btn:hover {
  border-color: #2d8cf0;
  color: #2d8cf0;
}

.qq-btn:hover {
  border-color: #12B7F5;
  color: #12B7F5;
}

/* 修改按钮样式，确保不被覆盖 */
.ivu-btn.submit-btn {
  width: 100%;
  height: 48px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(45deg, #2d8cf0, #00d0ff);
  border: none;
  transition: all 0.3s ease;
  cursor: pointer;
  color: #fff;
}

.ivu-btn.submit-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(45, 140, 240, 0.2);
  background: linear-gradient(45deg, #2d8cf0, #00d0ff);
  border: none;
  color: #fff;
}

.ivu-btn.submit-btn:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(45, 140, 240, 0.2);
}

.ivu-btn.action-btn {
  height: 44px;
  border-radius: 12px;
  font-size: 14px;
  border: 2px solid #e4e9f2;
  background: #fff;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ivu-btn.action-btn i {
  margin-right: 8px;
  font-size: 18px;
}

.ivu-btn.action-btn:hover {
  background: #f8fafc;
  border-color: #2d8cf0;
  color: #2d8cf0;
}

.ivu-btn.action-btn:active {
  transform: translateY(1px);
  background: #f0f7ff;
}

.ivu-btn.qr-btn:hover {
  border-color: #2d8cf0;
  color: #2d8cf0;
}

.ivu-btn.qq-btn:hover {
  border-color: #12B7F5;
  color: #12B7F5;
}

/* 确保其他样式不变 */

/* 添加新的动画样式 */
.logo-animation {
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto 20px;
  animation: logoFloat 3s ease-in-out infinite;
}

.logo-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(45deg, #2d8cf0, #00d0ff);
  animation: pulse 2s ease-in-out infinite;
}

.logo-ring {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #2d8cf0;
  animation: spin 3s linear infinite;
}

/* 新增动画关键帧 */
@keyframes shimmer {
  to {
    background-position: 200% center;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(45, 140, 240, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(45, 140, 240, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(45, 140, 240, 0);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* 添加光效样式 */
.light-effects {
  position: fixed;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.light-beam {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, 
    rgba(45, 140, 240, 0.1) 0%,
    rgba(0, 208, 255, 0.1) 100%);
  filter: blur(100px);
  animation: rotateLightBeam 60s linear infinite;
  opacity: 0.03;
}

.light-circle {
  position: absolute;
  width: 300px;
  height: 300px;
  background: radial-gradient(
    circle,
    rgba(45, 140, 240, 0.1) 0%,
    transparent 70%
  );
  animation: moveCircle 80s ease-in-out infinite;
  opacity: 0.03;
}

@keyframes rotateLightBeam {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes moveCircle {
  0% {
    top: 10%;
    left: 10%;
  }
  25% {
    top: 10%;
    left: 80%;
  }
  50% {
    top: 80%;
    left: 80%;
  }
  75% {
    top: 80%;
    left: 10%;
  }
  100% {
    top: 10%;
    left: 10%;
  }
}

/* 修改备案信息样式 */
.icp-footer {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 15px 0;
  margin-top: 15px;
}

.icp-footer a {
  color: rgba(255, 255, 255, 0.9);
  font-size: 13px;
  text-decoration: none;
  transition: all 0.3s ease;
  padding: 6px 12px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.icp-footer a:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.icp-footer a:active {
  transform: translateY(0);
}

/* 适配移动端 */
@media (max-width: 500px) {
  .login-vue {
    padding: 20px 15px 100px;
  }
  
  .container {
    margin-bottom: 40px;
  }
  
  .login-header,
  .login-body,
  .login-footer {
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .icp-footer {
    bottom: 10px;
    font-size: 11px;
  }
}

/* 添加最小高度限制,确保在小屏幕上内容不会挤压 */
@media (max-height: 700px) {
  .login-vue {
    height: auto;
    min-height: calc(100vh + 60px);
  }
  
  .container {
    margin: 20px auto 80px;
  }
}

/* 添加点击特效画布样式 */
.click-effect {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 9999;
}

/* 修改现有的景样式，添加更炫酷的渐变 */
.login-vue {
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.5)
  ),
  linear-gradient(
    45deg,
    rgba(45, 140, 240, 0.2),
    rgba(0, 208, 255, 0.2),
    rgba(76, 217, 105, 0.2)
  );
  animation: gradientBG 20s ease infinite;
}

/* 添加容器悬浮效果 */
.container {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.container:hover {
  transform: translateY(-5px) scale(1.01);
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.2);
}

/* 添加入框聚焦时的光晕效果 */
.input-c .ivu-input:focus {
  box-shadow: 0 0 20px rgba(45, 140, 240, 0.2);
  border-color: #2d8cf0;
  animation: inputPulse 1.5s infinite;
}

@keyframes inputPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(45, 140, 240, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(45, 140, 240, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(45, 140, 240, 0);
  }
}

/* 添加按钮悬浮特效 */
.submit-btn {
  background: linear-gradient(45deg, #2d8cf0, #00d0ff, #2d8cf0);
  background-size: 200% auto;
  animation: gradientShift 3s infinite;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* 优化 logo 动画 */
.logo-animation {
  animation: logoFloat 3s ease-in-out infinite;
}

@keyframes logoFloat {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
</style>
