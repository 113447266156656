<template>
  <div class="chat-app">
    <aside class="sidebar">
      <!-- Chat List -->
      <div class="chat-list">
        <div
          v-for="chatLink in chatUsers"
          :key="chatLink.linkId"
          class="chat-list-item"
          :class="{
            active: chatLink.linkId === (selectedUser && selectedUser.linkId),
          }"
        >
          <div @click="selectUser(chatLink)" class="selectable-area">
            <img :src="chatLink.toUserHead" class="avatar" />
            <div class="user-info">
              <div class="user-name-unread">
                <h5 class="user-name">{{ chatLink.toUsername }}</h5>
                <span class="unread-count" v-if="chatLink.unread > 0">
                  {{ chatLink.unread }}
                </span>
              </div>
              <p class="last-message" v-if="chatLink.lastMessageType === 'txt'">
                {{ chatLink.lastMessage }}
              </p>
              <p class="last-message" v-if="chatLink.lastMessageType === 'img'">
                [图片]
              </p>
              <p
                class="last-message"
                v-if="chatLink.lastMessageType === 'video'"
              >
                [视频]
              </p>
              <p
                class="last-message"
                v-if="chatLink.lastMessageType === 'audio'"
              >
                [语音]
              </p>
              <p
                class="last-message"
                v-if="chatLink.lastMessageType === 'file'"
              >
                [文件]
              </p>
              <p class="last-message-time">{{ chatLink.updateTime }}</p>
            </div>
          </div>
          <span class="delete-chat" @click.stop="deleteChat(chatLink.linkId)"
            >&times;</span
          >
        </div>
      </div>
    </aside>
    <section class="chat-section" v-if="selectedUser">
      <!-- Chat Header -->
      <header class="chat-header">
        <img :src="selectedUser.toUserHead" class="avatar" />
        <div class="user-info">
          <h5 class="user-name">{{ selectedUser.toUsername }}</h5>
          <p class="user-status">在线</p>
        </div>
      </header>
      <!-- Chat Window -->
      <main class="chat-window" ref="chatWindow" @scroll="handleScroll($event)">
        <chat-message
          v-for="message in messages"
          :key="`${message.messageId}-${message.fromUser}-${message.toUser}`"
          :message="message"
          :ownAvatar="currentUser.userHead"
          :toAvatar="selectedUser.toUserHead"
          :isOwnMessage="message.fromUser === currentUser.userId"
          @deleteMessage="deleteMessage"
          @question-clicked="handleRecommendationClicked"
        />
      </main>
      <!-- Chat Input -->
      <footer class="chat-input">
        <chat-input
          @send="handleSend"
          @sendMedia="handleSendMedia"
          @sendFileName="handleSendFileName"
        />
      </footer>
    </section>
    <section v-if="!selectedUser" class="chat-section-placeholder">
      <div class="welcome-content">
        <img src="@/assets/imgs/blank/contact_non_selected@2x.png" class="welcome-image" />
        <h2 class="welcome-title">欢迎使用聊天系统</h2>
        <p class="welcome-text">请从左侧选择一个联系人开始聊天</p>
      </div>
    </section>
  </div>
</template>
<script>
import ChatMessage from "@/components/userHome/message/ChatMessage.vue";
import ChatInput from "@/components/userHome/message/ChatInput.vue";
import { linkList, messagePage, clearUnread } from "@/api/message";
import { generateUUID } from "@/utils/uuidGenerator.js";

export default {
  components: {
    ChatMessage,
    ChatInput,
  },
  data() {
    return {
      websocket: null,
      firstResult: 0,
      pageSize: 10,
      currentUser: {},
      selectedUser: null,
      chatUsers: [],
      messages: [],
      messageTotal: 0,
    };
  },
  watch: {
    '$store.state.openMessageUser': {
      handler(newVal) {
        if (newVal) {
          this.openMessageUserSelect();
        }
      },
      immediate: true
    }
  },
  computed: {
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  },
  mounted() {
    this.connectWebSocket(this.currentUser.userId);
    this.$eventBus.$on("websocket-reconnected", () => {
      this.addWebsocket();
    });    
    this.getChatList();
    
    if (this.$store.state.openMessageUser) {
      this.openMessageUserSelect();
    }
  },
  beforeDestroy() {
    this.$eventBus.$off("websocket-reconnected");
    if (this.$refs.chatWindow) {
      this.$refs.chatWindow.removeEventListener("scroll", this.handleScroll);
    }
  },
  methods: {
    openMessageUserSelect() {
      let openUser = this.$store.state.openMessageUser;
      console.log('openUser',openUser);
      
      // 验证必要的字段是否存在
      if (!openUser || !openUser.senderId || !openUser.senderName) {
          console.warn('无效的用户数据格式');
          this.$store.commit('setOpenMessageUser', null);
          return;
      }
      
      // 先清除状态
      this.$store.commit('setOpenMessageUser', null);
      
      this.getChatList().then(() => {
        // 先查找是否存在现有对话
        const existingUser = this.chatUsers.find(user => user.toUser === openUser.senderId);
        
        if (existingUser) {
          this.selectUser(existingUser);
        } else {
          // 创建新的对话，使用从 UserModal 传来的 linkId
          this.selectedUser = {
            toUser: openUser.senderId,
            toUsername: openUser.senderName,
            toUserHead: openUser.senderAvatar,
            lastMessage: '',
            lastMessageType: 'txt', 
            unreadCount: 0,
            linkId: openUser.linkId || `temp-${openUser.senderId}-${Date.now()}`
          };
          
          // 将新创建的对话添加到聊天列表中
          this.chatUsers.unshift(this.selectedUser);
        }
      }).catch(err => {
        console.error('获取聊天列表失败:', err);
        this.$Message.error('获取聊天列表失败');
      });
    },
    connectWebSocket(val) {
      const url = "wss://www.bjca.xyz/websocket?userId=" + val;
      this.$store.dispatch("initializeWebSocket", url);
      this.addWebsocket();
    },
    /**
     * 修改对象数组中对象的某个属性值
     * @param {Array} array 对象数组
     * @param {string} key 属性名
     * @param {*} value 属性值
     * @param {string} property 要修改的属性名
     * @param {*} newValue 新的属性值
     * @returns {boolean} 返回true表示修改成功，返回false表示未找到对应对象或修改失败
     */
    updateProperty(array, key, value, property, newValue) {
      let obj = this.findByProperty(array, key, value);
      if (obj) {
        obj[property] = newValue;
        return true;
      }
      return false;
    },
    /**
     * 根据对象数组中对象的某个属性值来查找对象
     * @param {Array} array 对象数组
     * @param {string} key 属性名
     * @param {*} value 属性值
     * @returns {Object} 查找的对象，若未找到返回null
     */
    findByProperty(array, key, value) {
      return array.find((item) => item[key] === value) || null;
    },
    handleRecommendationClicked(recommendationContent) {
      console.log('recommendationContent',recommendationContent);
      this.handleSend(recommendationContent);
    },
    updateMessageSendingStatus(messageId, sendingStatus) {
      const messageIndex = this.messages.findIndex(
        (m) => m.messageId === messageId
      );
      if (messageIndex >= 0) {
        this.$set(this.messages[messageIndex], "sending", sendingStatus);
      }
    },
    loadMoreMessages() {
      if (this.firstResult >= this.messageTotal) {
        return;
      }
      this.firstResult += this.pageSize;
      messagePage({
        fromUser: this.currentUser.userId,
        toUser: this.selectedUser.toUser,
        firstResult: this.firstResult,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.code === 0) {
          const newMessages = res.retVal.chatMessageTotalDTO.messages;
          this.messageTotal = res.retVal.total;
          this.messages = [...newMessages, ...this.messages];
          // 为保持当前滚动位置，您可能需要调整滚动条位置
          this.$nextTick(() => {
            // 计算新消息的总高度
            let newMessagesHeight = 0;
            const chatWindow = this.$refs.chatWindow;
            const messageElements = chatWindow.children;
            for (let i = 0; i < newMessages.length; i++) {
              newMessagesHeight += messageElements[i].offsetHeight;
            }
            // 更新滚动位置
            chatWindow.scrollTop += newMessagesHeight;
          });
        } else {
          this.$Message.error(res.msg);
        }
      });
    },
    handleScroll(event) {
      const element = event.target;
      if (element.scrollTop === 0) {
        this.loadMoreMessages();
      }
    },
    addWebsocket() {
      this.websocket = null;
      const webSocketService = this.$store.state.socket;
      this.websocket = webSocketService;
      this.websocket.addListener("message", this.onMessage);
    },
    onMessage(event) {
      if (event.data === "已收到您的消息") {
        return;
      }
      const msg = JSON.parse(event.data);
      if (msg.type === "chat") {
        const tempArray = this.chatUsers;
        let found = false;
        tempArray.forEach((item, index) => {
          if (item.fromUser === msg.receiver && item.toUser === msg.sender) {
            found = true;
            if (this.selectedUser && this.selectedUser.linkId === item.linkId) {
              return;
            }
            tempArray[index].lastMessage = msg.content;
            tempArray[index].lastMessageType = msg.header;
            tempArray[index].unread = tempArray[index].unread + 1;
          }
        });
        if (!found) {
          setTimeout(() => {
            this.getChatList();
          }, 1000);
        }
      }
      if (
        this.selectedUser &&
        msg.type === "chat" &&
        msg.receiver === this.currentUser.userId &&
        this.selectedUser.toUser === msg.sender
      ) {
        const newMessage = {
          messageId: msg.messageId,
          fromUser: msg.sender,
          toUser: msg.receiver,
          content: msg.content,
          type: msg.header,
        };
        this.messages.push({
          ...newMessage,
          key: `${newMessage.messageId}-${newMessage.fromUser}-${newMessage.toUser}-${Date.now()}`
        });
        this.$nextTick(() => {
          this.toDown();
        });
      }
    },
    getChatList() {
      return linkList().then((res) => {
        if (res.code === 0) {
          this.chatUsers = res.retVal;
        } else {
          this.$Message.error(res.msg);
        }
      });
    },
    toDown() {
      const chatWindow = this.$refs.chatWindow;
      chatWindow.scrollTop = chatWindow.scrollHeight;
    },
    selectUser(user) {
      if (user.unread != 0 && user.unread > 0) {
        clearUnread({
          linkId: user.linkId,
        }).then((res) => {
          if (res.code === 0) {
            user.unread = 0;
          } else {
            this.$Message.error(res.msg);
          }
        });
      }
      this.messages = [];
      this.messageTotal = 0;
      this.firstResult = 0;
      this.selectedUser = user;
      messagePage({
        fromUser: this.currentUser.userId,
        toUser: user.toUser,
        firstResult: this.firstResult,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.code === 0) {
          this.messages = res.retVal.chatMessageTotalDTO.messages.map(msg => ({
            ...msg,
            key: `${msg.messageId}-${Date.now()}`
          }));
          this.messageTotal = res.retVal.total;
          this.$nextTick(() => {
            this.toDown();
          });
        } else {
          this.$Message.error(res.msg);
        }
      });
      this.$store.commit('setOpenMessageUser', null);
    },
    handleSend(newMessage) {
      const messId = generateUUID(32, false);
      const timestamp = new Date().toTimeString().substr(0, 5);
      const message = {
        messageId: messId,
        fromUser: this.currentUser.userId,
        toUser: this.selectedUser.toUser,
        content: newMessage,
        sendTime: timestamp,
        type: "txt",
      };
      this.messages.push({
        ...message,
        key: `${message.messageId}-${message.fromUser}-${message.toUser}-${Date.now()}`
      });
      this.$nextTick(() => {
        this.toDown();
      });
      let messToServer = {
        messageId: messId,
        header: "txt",
        sender: this.currentUser.userId,
        receiver: this.selectedUser.toUser,
        content: newMessage,
        type: "chat",
      };
      this.websocket.send(JSON.stringify(messToServer));
      if (messToServer.type === "chat") {
        const tempArray = this.chatUsers;
        tempArray.forEach((item, index) => {
          if (
            item.fromUser === messToServer.sender &&
            item.toUser === messToServer.receiver
          ) {
            tempArray[index].lastMessage = messToServer.content;
            tempArray[index].lastMessageType = messToServer.header;
          }
        });
      }
      if (!this.chatUsers.some(user => user.toUser === this.selectedUser.toUser)) {
        this.getChatList();
      }
    },
    handleSendFileName(fileId,fileName) {
      this.updateProperty(this.messages,'messageId',fileId,'fileName',fileName)
    },
    handleSendMedia(url, mediaType, fileId, isTemp) {
      console.log(url, mediaType, fileId, isTemp);
      if (isTemp === "true") {
        const timestamp = new Date().toTimeString().substr(0, 5);
        const message = {
          messageId: fileId,
          fromUser: this.currentUser.userId,
          toUser: this.selectedUser.toUser,
          content: url,
          sendTime: timestamp,
          type: mediaType,
          sending: true, // 标记为发送中
        };
        this.messages.push(message);
        this.$nextTick(() => {
          this.toDown();
        });
        return;
      }
      this.updateProperty(this.messages,'messageId',fileId,'content',url)

      let messToServer = {
        messageId: fileId,
        header: mediaType,
        sender: this.currentUser.userId,
        receiver: this.selectedUser.toUser,
        content: url,
        type: "chat",
      };
      this.websocket.send(JSON.stringify(messToServer));
      this.updateMessageSendingStatus(fileId, false);
      if (messToServer.type === "chat") {
        const tempArray = this.chatUsers;
        tempArray.forEach((item, index) => {
          if (
            item.fromUser === messToServer.sender &&
            item.toUser === messToServer.receiver
          ) {
            tempArray[index].lastMessage = messToServer.content;
            tempArray[index].lastMessageType = messToServer.header;
          }
        });
      }
    },
    deleteChat(userId) {
      console.log("删除聊天列表", userId);
    },
    deleteMessage(message) {
      const index = this.messages.findIndex(
        (item) => item.messageId === message.messageId
      );
      this.messageTotal--;
      if (index !== -1) {
        this.messages.splice(index, 1);
      }
      this.getChatList();
    },
    handleMessageSent() {
      this.$emit('message-sent');
    }
  },
};
</script>

<style scoped>
.chat-app {
  display: flex;
  height: 85vh;
  font-family: "Arial", sans-serif;
  flex-wrap: nowrap; /* 防止项目换行 */
}

.chat-list {
  flex: 0 0 250px; /* 聊天列表宽度固定为250px，不伸缩 */
  min-width: 250px; /* 确保最小宽度为250px */
  overflow-x: hidden; /* 防止水平滚动 */
  overflow-y: auto; /* 允许垂直滚动 */
}

.sidebar {
  width: 250px;
  background-color: #2c3e50;
  color: white;
  overflow-y: auto;
  flex-shrink: 0; /* 防止被压缩 */
}

.chat-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #34495e;
  cursor: pointer;
  transition: background-color 0.2s;
}

.chat-list-item:hover,
.chat-list-item.active {
  background-color: #34495e;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-info {
  overflow: hidden;
}

.user-name {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.last-message {
  margin: 0;
  font-size: 0.8em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px; /* 根据需要调整最大宽度 */
}

.chat-section {
  flex-shrink: 1; /* 允许在必要时缩小以适应屏幕 */
  flex-grow: 1; /* 允许chat-section占据剩余空间 */
  display: flex;
  flex-direction: column;
}

.chat-header {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #ecf0f1;
  border-bottom: 1px solid #bdc3c7;
}

.chat-window {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #ecf0f1;
  flex: 1; /* 聊天窗口占据剩余空间 */
}

.chat-input {
  padding: 10px;
  background-color: #ecf0f1;
  border-top: 1px solid #bdc3c7;
}

.delete-chat {
  color: #e74c3c;
  font-size: 24px;
  cursor: pointer;
  padding: 8px;
  visibility: hidden; /* 默认不显示删除按钮 */
}

.chat-list-item:hover .delete-chat {
  visibility: visible; /* 悬停时显示删除按钮 */
}

.selectable-area {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 10px;
  cursor: pointer;
}

.chat-section-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: #f5f7fa;
  padding: 20px;
  width: 100%;  /* 确保占据所有可用宽度 */
}

.welcome-content {
  text-align: center;
  width: 90%;       /* 使用百分比宽度 */
  max-width: 800px; /* 设置最大宽度 */
  padding: 5% 40px; /* 使用混合单位，垂直方向用百分比 */
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.welcome-image {
  width: 50%;        /* 使用百分比宽度 */
  max-width: 400px;  /* 设置最大宽度 */
  min-width: 200px;  /* 设置最小宽度 */
  height: auto;
  margin-bottom: 32px;
  opacity: 0.9;
  transition: transform 0.3s ease;
}

.welcome-image:hover {
  transform: scale(1.05);
}

.welcome-title {
  color: #2c3e50;
  font-size: 24px;
  margin-bottom: 12px;
  font-weight: 600;
}

.welcome-text {
  color: #7f8c8d;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

.last-message-time {
  margin: 0;
  font-size: 0.7em; /* 小点的字体大小 */
  color: #bdc3c7; /* 灰色 */
}

.user-name-unread {
  display: flex;
  align-items: center;
}

.user-name {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.unread-count {
  background-color: #e74c3c;
  color: white;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7em;
  margin-left: 10px;
}

.last-message {
  margin: 0;
  font-size: 0.8em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .chat-list {
    flex: 0 0 250px;
    min-width: 250px;
  }
  
  .welcome-content {
    width: 95%;
    padding: 20px;
  }
  
  .welcome-image {
    width: 70%;
  }
}

@media (max-width: 480px) {
  .welcome-image {
    width: 90%;
  }
  
  .welcome-title {
    font-size: 20px;
  }
  
  .welcome-text {
    font-size: 14px;
  }
}
/* 其他样式保持不变 */
</style>
