<template>
  <div class="register-card">
    <h2>创建新账户</h2>
    <div class="input-group">
      <div class="input-c">
        <i class="ivu-icon ios-contact"></i>
        <Input v-model="form.username" placeholder="用户名" clearable />
        <p class="error">{{ errors.username }}</p>
      </div>

      <div class="input-c">
        <i class="ivu-icon ios-mail"></i>
        <Input v-model="form.email" placeholder="电子邮箱" clearable />
        <p class="error">{{ errors.email }}</p>
      </div>

      <div class="input-c verification-code">
        <i class="ivu-icon ios-keypad"></i>
        <div class="code-input-container">
          <div class="code-input-wrapper">
            <input v-for="(digit, index) in 6" :key="index" :ref="`codeInput${index}`"
              v-model="verificationDigits[index]" type="text" maxlength="1" @input="handleCodeInput($event, index)"
              @keydown="handleKeydown($event, index)" @paste="handlePaste" />
          </div>
          <Button class="code-btn" :disabled="cooldown > 0" @click="sendVerificationCode">
            {{ cooldown > 0 ? `${cooldown}s后重试` : '获取验证码' }}
          </Button>
        </div>
        <p class="error">{{ errors.code }}</p>
      </div>

      <div class="input-c">
        <i class="ivu-icon md-lock"></i>
        <Input type="password" v-model="form.password" placeholder="密码" clearable @on-focus="showPasswordTips = true"
          @on-blur="showPasswordTips = false" />
        <p class="error">{{ errors.password }}</p>
        <div class="password-tips" v-show="showPasswordTips">
          <p>密码要求：</p>
          <ul>
            <li :class="{ valid: passwordStrength.length }">至少8个字符</li>
            <li :class="{ valid: passwordStrength.number }">包含数字</li>
            <li :class="{ valid: passwordStrength.letter }">包含字母</li>
          </ul>
        </div>
      </div>

      <div class="input-c">
        <i class="ivu-icon md-lock"></i>
        <Input type="password" v-model="form.confirmPassword" placeholder="确认密码" clearable />
        <p class="error">{{ errors.confirmPassword }}</p>
      </div>
    </div>

    <Button class="submit-btn" type="primary" @click="handleRegister" :loading="isLoading" :disabled="isLoading">
      {{ isLoading ? '注册中...' : '立即注册' }}
    </Button>

    <div class="back-to-login">
      已有账号？ <span @click="$emit('switch', 'login')">返回登录</span>
    </div>
  </div>
</template>

<script>
import { sendEmailRegister, register, login, userInfo } from '@/api/user'
import JSEncrypt from 'jsencrypt'

export default {
  name: 'RegisterCard',
  data() {
    return {
      form: {
        username: '',
        email: '',
        code: '',
        password: '',
        confirmPassword: ''
      },
      errors: {
        username: '',
        email: '',
        code: '',
        password: '',
        confirmPassword: ''
      },
      isLoading: false,
      cooldown: 0,
      showPasswordTips: false,
      verificationDigits: ['', '', '', '', '', ''],
    }
  },
  computed: {
    passwordStrength() {
      return {
        length: this.form.password.length >= 8,
        number: /\d/.test(this.form.password),
        letter: /[a-zA-Z]/.test(this.form.password)
      }
    }
  },
  watch: {
    verificationDigits: {
      handler(newVal) {
        this.form.code = newVal.join('');
      },
      deep: true
    }
  },
  methods: {
    startCooldown() {
      this.cooldown = 60;
      const timer = setInterval(() => {
        this.cooldown--;
        if (this.cooldown <= 0) {
          clearInterval(timer);
        }
      }, 1000);
    },
    async sendVerificationCode() {
      if (!this.validateEmail()) return;
      const res = await sendEmailRegister({ email: this.form.email })
      if (res.code == 0) {
        this.startCooldown();
        this.$Message.success('验证码已发送到您的邮箱');
      } else {
        this.$Message.error(res.msg);
      }
    },
    validateEmail() {
      if (!this.form.email) {
        this.errors.email = '请输入邮箱地址';
        return false;
      }
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.form.email)) {
        this.errors.email = '请输入有效的邮箱地址';
        return false;
      }
      return true;
    },
    validateForm() {
      let isValid = true;
      this.errors = {
        username: '',
        email: '',
        code: '',
        password: '',
        confirmPassword: ''
      };

      if (!this.form.username) {
        this.errors.username = '请输入用户名';
        isValid = false;
      }

      if (!this.validateEmail()) {
        isValid = false;
      }

      if (!this.form.code || this.form.code.length !== 6) {
        this.errors.code = '请输入6位验证码';
        isValid = false;
      }

      if (!this.form.password) {
        this.errors.password = '请输入密码';
        isValid = false;
      } else if (!this.passwordStrength.length ||
        !this.passwordStrength.number ||
        !this.passwordStrength.letter) {
        this.errors.password = '密码不符合要求';
        isValid = false;
      }

      if (this.form.password !== this.form.confirmPassword) {
        this.errors.confirmPassword = '两次输入的密码不一致';
        isValid = false;
      }

      return isValid;
    },
    handleRegister() {
      if (!this.validateForm()) return;

      this.isLoading = true;

      let pubKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCkPhdjvFe62qpyqA4zHRNJZwln+zgOxKAZ5c2H8TmqAK9XPemyU0UR0sZnefy/8VNaF2qiTepKynZVNgc2glJwDwhW+lUEeqYeT+DIcAdi+yx9MNPhkeMQ+8JJ92kyHio48m+On+NrOFjY1ZMZtQRNeVmeniCK2N30ijjFvT+IAQIDAQAB";
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(pubKey);

      register({
        username: this.form.username,
        email: this.form.email,
        code: this.form.code,
        password: encrypt.encrypt(this.form.password),
        confirmPassword: encrypt.encrypt(this.form.confirmPassword)
      })
        .then(res => {
          if (res && res.code === 0) {
            this.$Message.success({
              content: '注册成功！正在为您自动登录...',
              duration: 3
            });
            
            // 延迟2秒后执行登录操作
            setTimeout(() => {
              login({
                username: this.form.username,
                password: encrypt.encrypt(this.form.password),
                isRemember: false,
              }).then((res) => {
                if (res.code === 0) {
                  this.$Message.success({
                    content: '登录成功！',
                    duration: 2,
                    onClose: () => {
                      userInfo({}).then((result) => {
                        localStorage.setItem("currentUser", JSON.stringify(result.retVal));
                        this.$router.push({ path: this.redirect || "/" });
                      });
                    }
                  });
                }
              });
            }, 2000);
          } else {
            const errorMsg = res?.msg || '注册失败，请重试';
            this.$Message.error(errorMsg);
          }
        })
        .catch(error => {
          console.error('注册错误:', error);
          this.$Message.error('注册失败，请稍后重试');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleCodeInput(event, index) {
      const value = event.target.value;
      // 确保只能输入数字
      if (!/^\d*$/.test(value)) {
        this.verificationDigits[index] = '';
        return;
      }

      // 自动跳转到下一个输入框
      if (value && index < 5) {
        this.$refs[`codeInput${index + 1}`][0].focus();
      }
    },
    handleKeydown(event, index) {
      // 处理退格键
      if (event.key === 'Backspace' && !this.verificationDigits[index] && index > 0) {
        this.verificationDigits[index - 1] = '';
        this.$refs[`codeInput${index - 1}`][0].focus();
      }
    },
    handlePaste(event) {
      event.preventDefault();
      const pastedText = (event.clipboardData || window.clipboardData).getData('text');
      const numbers = pastedText.replace(/\D/g, '').slice(0, 6).split('');

      // 清空所有验证��输入框
      this.verificationDigits = ['', '', '', '', '', ''];

      // 填入粘贴的数字
      numbers.forEach((num, index) => {
        if (index < 6) {
          this.verificationDigits[index] = num;
        }
      });

      // 清除验证码错误提示
      this.errors.code = '';

      // 如果粘贴的内容正好是6位数字，自动触发验证
      if (numbers.length === 6) {
        this.$nextTick(() => {
          // 确保验证码已经更新到 form.code
          if (this.form.code.length === 6) {
            // 验证成功，可以在这里添加额外的处理逻辑
          }
        });
      }
    }
  }
}
</script>

<style scoped>
.register-card {
  padding: 30px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.register-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.15);
}

h2 {
  font-size: 32px;
  font-weight: 700;
  background: linear-gradient(45deg, #2d8cf0, #00d0ff, #2d8cf0);
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientText 3s linear infinite;
  text-align: center;
  margin-bottom: 30px;
}

@keyframes gradientText {
  to {
    background-position: 200% center;
  }
}

.input-group {
  margin: 24px 0;
}

.input-c {
  position: relative;
  margin-bottom: 25px;
}

.input-c i {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #2d8cf0;
  font-size: 20px;
  z-index: 2;
  transition: all 0.3s ease;
}

.input-c :deep(.ivu-input) {
  height: 48px;
  padding-left: 45px;
  border-radius: 12px;
  border: 2px solid rgba(45, 140, 240, 0.2);
  background: rgba(255, 255, 255, 0.9);
  font-size: 15px;
  transition: all 0.3s ease;
  backdrop-filter: blur(8px);
}

.input-c :deep(.ivu-input:focus) {
  transform: translateY(-2px);
  border-color: #2d8cf0;
  box-shadow: 0 8px 20px rgba(45, 140, 240, 0.15);
}

.verification-code {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.code-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.code-input-wrapper {
  display: flex;
  gap: 6px;
  flex: 1;
}

.code-input-wrapper input {
  width: 36px;
  height: 48px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #2d8cf0;
  border: 2px solid rgba(45, 140, 240, 0.15);
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.95);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.code-input-wrapper input:focus {
  outline: none;
  border-color: #2d8cf0;
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(45, 140, 240, 0.15);
}

.code-btn {
  height: 48px;
  padding: 0 15px;
  min-width: 100px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 500;
  background: linear-gradient(135deg, #2d8cf0, #00d0ff);
  border: none;
  color: white;
  transition: all 0.3s ease;
  white-space: nowrap;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.code-btn:hover:not([disabled]) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(45, 140, 240, 0.2);
  background: linear-gradient(135deg, #2d8cf0, #2d8cf0);
}

.code-btn[disabled] {
  background: #f5f5f5;
  color: #999;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.verification-code .input-c {
  position: relative;
  display: flex;
  align-items: center;
}

.verification-code i {
  position: absolute;
  left: 15px;
  color: #2d8cf0;
  font-size: 20px;
  z-index: 2;
}

.password-tips {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 10px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 10;
  font-size: 12px;
}

.password-tips p {
  color: #666;
  margin-bottom: 5px;
}

.password-tips ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.password-tips li {
  color: #ff4d4f;
  margin: 3px 0;
  padding-left: 20px;
  position: relative;
}

.password-tips li::before {
  content: "×";
  position: absolute;
  left: 0;
  color: #ff4d4f;
}

.password-tips li.valid {
  color: #52c41a;
}

.password-tips li.valid::before {
  content: "✓";
  color: #52c41a;
}

.submit-btn {
  width: 100%;
  height: 48px !important;
  border-radius: 12px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  background: linear-gradient(45deg, #2d8cf0, #00d0ff, #2d8cf0) !important;
  background-size: 200% auto !important;
  border: none !important;
  color: white !important;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  animation: gradientShift 3s infinite;
}

.submit-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(45, 140, 240, 0.25);
}

.back-to-login {
  text-align: center;
  color: #666;
  font-size: 14px;
  margin-top: 16px;
}

.back-to-login span {
  color: #2d8cf0;
  cursor: pointer;
  margin-left: 4px;
  transition: all 0.3s ease;
}

.back-to-login span:hover {
  color: #00d0ff;
  text-decoration: underline;
}

.error {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 4px;
  text-align: left;
}

@keyframes shimmer {
  to {
    background-position: 200% center;
  }
}

@keyframes inputPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(45, 140, 240, 0.4);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(45, 140, 240, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(45, 140, 240, 0);
  }
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}
</style>