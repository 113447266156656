<template>
  <div>
    <Modal v-model="localVisible" title="用户信息" width="550" footer-hide @on-visible-change="handleVisibleChange">
      <div class="user-info">
        <div class="avatar-section">
          <Avatar :src="userInfo.userHead" size="large" class="user-avatar"></Avatar>
          <div class="status-badge" :class="{ 'online': status === '在线' }">
            {{ status }}
          </div>
        </div>
        <div class="info-section">
          <h2 class="username">
            <a @click="goToUserProfile">{{ userInfo.username }}</a>
          </h2>
          <div class="motto-box">
            <Icon type="md-quote" />
            <span class="motto">{{ userInfo.motto || '这个人很懒，什么都没写~' }}</span>
          </div>
          <div class="user-stats">
            <div class="stat-item">
              <div class="stat-value">{{userInfo.followNum}}</div>
              <div class="stat-label">关注</div>
            </div>
            <div class="stat-item">
              <div class="stat-value">{{ userInfo.fansNum }}</div>
              <div class="stat-label">粉丝</div>
            </div>
            <div class="stat-item">
              <div class="stat-value">{{ userInfo.diaryNum }}</div>
              <div class="stat-label">日记</div>
            </div>
          </div>
          <div class="location-info">
            <p><Icon type="md-pin" /> {{ userInfo.lastLoginAddress }}</p>
            <p><Icon type="md-time" /> {{ userInfo.lastLogintime }}</p>
          </div>
        </div>
      </div>
      <div class="action-buttons" >
        <i-button 
            :type="isFollowUser ? 'default' : 'primary'" 
            :icon="isFollowUser ? 'md-person' : 'md-person-add'" 
            v-if="!isMe"
            @click="handleFollow">
            {{ isFollowUser ? '已关注' : '关注' }}
        </i-button>
        <i-button type="success" icon="md-mail" v-if="!isMe" @click="handleMessage">发消息</i-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { Modal, Avatar, Button, Icon } from 'view-design'
import { getUserById,isFollow, followUser, unfollowUser,getUserInfo } from '@/api/user.js'

export default {
    components: {
        Modal,
        Avatar,
        'i-button': Button,
        Icon
    },
    props: {
        userId: {
            type: String,
            default: '',
        },
        visible: {
            type: Boolean,
            default: true,
        },
    },
    watch: {
        visible(newValue) {
            this.localVisible = newValue
        },
        localVisible(newValue) {
            if (newValue) {
                this.getUserInfo()
                this.checkFollowStatus()
            }
            this.$emit('update:visible', newValue)
        },
        userId: {
            immediate: true,
            handler(newValue) {
                if(newValue && this.localVisible) {
                    this.checkFollowStatus()
                }
                this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
                this.isMe = newValue === this.currentUser.userId;
            }
        }
    },
    data() {
        return {
            isFollowUser: false,
            isMe: false,
            localVisible: this.visible,
            userInfo: {

            },
            status: '在线', // 添加状态字段
        }
    },
    created() {
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"))
    },
    mounted() {
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"))
        if(this.userId) {
            this.checkFollowStatus()
        }
    },
    methods: {
        getUserInfo() {
            if (!this.currentUser) {
                this.currentUser = JSON.parse(localStorage.getItem("currentUser"))
            }
            if (this.userId === this.currentUser.userId) {
                this.userInfo = this.currentUser
                this.isMe = true
            } else {
                getUserById({ userId: this.userId }).then(res => {
                    this.userInfo = res.retVal
                    this.isMe = false
                    this.checkFollowStatus()
                })
            }
        },
        handleVisibleChange(visible) {
            this.localVisible = visible
            if (!visible) {
                this.$emit('close', this.localVisible)
            }
        },
        closeUserInfo() {
            this.localVisible = false
            this.$emit('close', this.localVisible)
        },
        goToUserProfile() {
        // 跳转到用户个人主页的逻辑
        },
        async handleFollow() {
            try {
                if (this.isFollowUser) {
                    // 取消关注
                    await unfollowUser({ userId: this.userId });
                    this.$Message.success('取消关注成功');
                } else {
                    // 关注用户
                    await followUser({ userId: this.userId });
                    this.$Message.success('关注成功');
                }
                // 更新关注状态
                this.isFollowUser = !this.isFollowUser;
            } catch (error) {
                this.$Message.error('操作失败，请稍后重试');
            }
        },
        async checkFollowStatus() {
            if (!this.isMe && this.userId) {
                try {
                    const res = await isFollow({ userId: this.userId });
                    this.isFollowUser = res.retVal;
                } catch (error) {
                    console.error('获取关注状态失败:', error);
                }
            }
        },
        handleMessage() {
            // 存储要打开聊天的用户信息到 Vuex
            this.$store.commit('setOpenMessageUser', {
                senderId: this.userId,
                senderName: this.userInfo.username,
                senderAvatar: this.userInfo.userHead
            });
            
            // 关闭当前弹窗
            this.closeUserInfo();
            
            // 跳转到消息页面并设置激活菜单
            this.$router.push('/MessageList');
            
            // 通过 eventBus 发送事件来更新导航栏状态
            this.$eventBus.$emit('update-nav', 'message');
        }
    },
}
</script>

<style scoped>
.user-info {
  display: flex;
  padding: 32px;
  background: linear-gradient(145deg, #ffffff, #f8faff);
  border-radius: 20px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.06);
}

.avatar-section {
  position: relative;
  margin-right: 40px;
}

.user-avatar {
  width: 130px !important;
  height: 130px !important;
  border: 5px solid rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
}

.user-avatar:hover {
  transform: scale(1.02);
}

.status-badge {
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding: 6px 14px;
  border-radius: 20px;
  font-size: 12px;
  background-color: #f0f0f0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.status-badge.online {
  background: linear-gradient(45deg, #42b983, #4CAF50);
  color: white;
}

.info-section {
  flex: 1;
}

.username {
  font-size: 28px;
  font-weight: 600;
  margin: 0 0 16px 0;
}

.username a {
  color: #2d8cf0;
  text-decoration: none;
  transition: color 0.3s;
}

.username a:hover {
  color: #5cadff;
}

.motto-box {
  background-color: rgba(248, 249, 250, 0.8);
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.motto {
  font-style: italic;
  color: #666;
  margin-left: 8px;
}

.user-stats {
  display: flex;
  margin: 25px 0;
  padding: 20px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.stat-item {
  flex: 1;
  text-align: center;
  transition: transform 0.2s ease;
}

.stat-item:hover {
  transform: translateY(-2px);
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
  background: linear-gradient(45deg, #2d8cf0, #2d8cf0cc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stat-label {
  font-size: 12px;
  color: #999;
}

.location-info {
  color: #666;
  font-size: 14px;
}

.location-info p {
  margin: 8px 0;
}

.action-buttons {
  margin-top: 25px;
  text-align: center;
}

.action-buttons button {
  margin: 0 10px;
  padding: 8px 20px;
  transition: all 0.3s ease;
}

.action-buttons button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(45, 140, 240, 0.2);
}

.ivu-icon {
  margin-right: 4px;
}

.action-buttons .ivu-btn {
    min-width: 100px;
}

.action-buttons .ivu-btn-default:hover {
    color: #ed4014;
    border-color: #ed4014;
}
</style>