<template>
  <div class="video-upload-container">
    <div 
      class="upload-area"
      :class="{ 'is-dragover': isDragover }"
      @drop.prevent="handleDrop"
      @dragover.prevent="isDragover = true"
      @dragleave.prevent="isDragover = false"
    >
      <div v-if="!videoUrl" class="upload-placeholder">
        <Upload
          ref="upload"
          :action="action"
          :headers="headers"
          accept="video/mp4,video/avi,video/quicktime"
          :format="['mp4', 'avi', 'mov']"
          :max-size="fileSize * 1024"
          :on-progress="handleProgress"
          :on-success="handleSuccess"
          :on-error="handleError"
          :on-format-error="handleFormatError"
          :on-exceeded-size="handleMaxSize"
          :before-upload="handleBeforeUpload"
          :show-upload-list="false"
        >
          <div class="upload-trigger">
            <Icon type="ios-cloud-upload" size="52" />
            <p>点击或拖拽视频文件到此处上传</p>
            <p class="upload-hint">支持 mp4/avi/mov 格式，文件大小不超过 {{ fileSize }}MB</p>
          </div>
        </Upload>
        
        <!-- 内嵌进度条 -->
        <div v-if="isUploading" class="progress-wrapper">
          <Progress :percent="uploadProgress" :stroke-width="4" status="active" />
          <div class="progress-text">{{ uploadProgress }}%</div>
          <Button type="error" size="small" @click="handleCancel" :disabled="uploadProgress >= 100">
            取消上传
          </Button>
        </div>
      </div>

      <div v-else class="video-preview">
        <div class="video-header">
          <Button 
            type="error" 
            icon="ios-trash" 
            size="small"
            @click="handleRemove"
            class="delete-btn"
          />
        </div>
        <video
          ref="videoPlayer"
          :src="videoUrl"
          controls
          class="video-player"
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoUpload',
  props: {
    value: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      required: true
    },
    fileSize: {
      type: Number,
      default: 20
    },
    headers: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      videoUrl: this.value,
      isDragover: false,
      uploadProgress: 0,
      isUploading: false
    }
  },
  watch: {
    value(val) {
      this.videoUrl = val
    }
  },
  methods: {
    handleDrop(e) {
      this.isDragover = false
      const file = e.dataTransfer.files[0]
      if (file) {
        this.$refs.upload.handleStart(file)
      }
    },
    handleBeforeUpload() {
      this.isUploading = true
      this.uploadProgress = 0
      return true
    },
    handleProgress(event, file) {
      this.uploadProgress = parseInt(file.percentage, 10)
    },
    handleSuccess(response) {
      if (response.code === 0) {
        this.videoUrl = response.retVal
        this.$emit('input', response.retVal)
        this.$Message.success('上传成功')
      } else {
        this.$Message.error(response.msg || '上传失败')
      }
      this.isUploading = false
    },
    handleError(error) {
      this.$Message.error('上传失败: ' + error)
      this.isUploading = false
    },
    handleFormatError() {
      this.$Message.error('文件格式不正确，请选择 mp4/avi/mov 格式的视频文件')
    },
    handleMaxSize() {
      this.$Message.error(`文件大小不能超过 ${this.fileSize}MB`)
    },
    handleRemove() {
      if (!this.videoUrl) return
      
      this.$Modal.confirm({
        title: '确认删除',
        content: '确定要删除这个视频吗？',
        onOk: () => {
          this.$emit('delete', this.videoUrl)
          this.videoUrl = ''
          this.$emit('input', '')
        }
      })
    },
    handleCancel() {
      this.$refs.upload.clearFiles()
      this.isUploading = false
      this.$Message.warning('已取消上传')
    }
  }
}
</script>

<style scoped>
.video-upload-container {
  width: 100%;
}

.upload-area {
  border: 2px dashed #dcdee2;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  transition: all .3s;
  cursor: pointer;
}

.upload-area.is-dragover {
  border-color: #2d8cf0;
  background: rgba(45,140,240,.06);
}

.upload-placeholder {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.upload-trigger {
  color: #515a6e;
}

.upload-hint {
  font-size: 12px;
  color: #808695;
  margin-top: 10px;
}

.progress-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.progress-text {
  margin: 10px 0;
  color: #2d8cf0;
  font-size: 14px;
}

.video-preview {
  width: 100%;
  position: relative;
}

.video-header {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.delete-btn {
  opacity: 0.8;
  transition: opacity 0.3s;
}

.delete-btn:hover {
  opacity: 1;
}

.video-player {
  width: 100%;
  max-height: 400px;
  border-radius: 4px;
  background: #000;
}
</style>
