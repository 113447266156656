<template>
  <div class="bookmark-container">
    <div class="operation-bar">
      <Input v-model="searchQuery" placeholder="搜索书签..." class="input-style search-input"/>
      <Select
        v-model="selectedCategory"
        class="input-style select-category"
        filterable
        clearable
        @on-change="handleCategoryChange"
      >
        <Option value="">所有分类</Option>
        <Option v-for="category in categories" :value="category" :key="category">
          {{ category }}
        </Option>
      </Select>
      <Button @click="triggerSearch" type="primary" class="button-style">搜索</Button>
      <Button @click="showAddModal" type="primary" class="button-style">添加书签</Button>
      <Button @click="showImportModal" type="primary" class="button-style">导入书签</Button>
    </div>
    <div class="bookmark-grid">
      <div class="bookmark-card" v-for="bookmark in bookmarks" :key="bookmark.bookmarkId" style="will-change: transform;">
        <Tooltip placement="top">
          <div class="bookmark-content">
            <div class="top-indicator" v-if="bookmark.top === 'T'">
            </div>
            
            <a :href="bookmark.url" target="_blank" class="bookmark-link" @click="incrementViewCount(bookmark.bookmarkId)">
              <img :src="bookmark.icon" alt="bookmark.name" class="bookmark-icon" @error="handleImageError">
              <div class="bookmark-info">
                <h3>{{ bookmark.name }}</h3>
                <p class="views-count">浏览次数: {{ bookmark.views }}</p>
              </div>
            </a>
            <div class="category-tags">
              <span 
                v-for="(cat, index) in bookmark.categoryList" 
                :key="index" 
                class="category-tag"
                @click="selectCategory(cat)"
              >
                {{ cat }}
              </span>
            </div>
            <div class="bookmark-actions">
              <button @click="toggleTop(bookmark)" class="action-button top" :title="bookmark.top === 'T' ? '取消置顶' : '置顶'">
                <Icon :type="bookmark.top === 'T' ? 'md-arrow-dropdown' : 'md-arrow-dropup'" />
              </button>
              <button @click="editBookmark(bookmark)" class="action-button edit">✎</button>
              <button @click="deleteBookmark(bookmark.bookmarkId)" class="action-button delete">✖</button>
            </div>
          </div>
          <template #content>
            {{ bookmark.name }}
          </template>
        </Tooltip>
      </div>
    </div>
    <Modal v-model="isAddModalVisible" title="添加书签" :loading="loading" :closable="false" :mask-closable="false">
      <Form :label-width="80">
        <FormItem label="名称">
          <Input v-model="currentBookmark.name" placeholder="请输入书签名称"/>
        </FormItem>

        <FormItem label="URL" required>
          <Input v-model="currentBookmark.url" placeholder="请输入URL" :class="{'input-required': !currentBookmark.url}"/>
        </FormItem>

        <FormItem label="分类">
          <v-select v-model="currentBookmark.category" :options="categories" taggable multiple></v-select>
        </FormItem>

        <FormItem label="图标 URL">
          <Input v-model="currentBookmark.icon" placeholder="输入图标的网络地址"/>
        </FormItem>

        <FormItem label="上传图标">
          <Upload
            action=""
            :before-upload="file => { handleIconUpload({ target: { files: [file] } }); return false; }"
            accept="image/*"
            :headers="uploadHeaders"
          >
            <Button icon="ios-cloud-upload-outline">选择图片</Button>
          </Upload>
        </FormItem>

        <FormItem v-if="currentBookmark.icon">
          <div class="icon-preview">
            <img :src="currentBookmark.icon" alt="预览图标" class="preview-icon">
          </div>
        </FormItem>
      </Form>
      <template #footer>
        <Button type="primary" @click="addBookmark" :loading="loading">保存</Button>
        <Button @click="closeModal" :disabled="loading" style="margin-left: 8px">取消</Button>
      </template>
    </Modal>
    <Modal v-model="isEditModalVisible" title="编辑书签" :loading="loading" :closable="false" :mask-closable="false">
      <Form :label-width="80">
        <FormItem label="名称">
          <Input v-model="editingBookmark.name" placeholder="请输入书签名称"/>
        </FormItem>

        <FormItem label="URL" required>
          <Input v-model="editingBookmark.url" placeholder="请输入URL" :class="{'input-required': !editingBookmark.url}"/>
        </FormItem>

        <FormItem label="分类">
          <v-select v-model="editingBookmark.category" :options="categories" taggable multiple></v-select>
        </FormItem>

        <FormItem label="图标 URL">
          <Input v-model="editingBookmark.icon" placeholder="输入图标的网络地址"/>
        </FormItem>

        <FormItem label="上传图标">
          <Upload
            action=""
            :before-upload="file => { handleEditIconUpload({ target: { files: [file] } }); return false; }"
            accept="image/*"
            :headers="uploadHeaders"
          >
            <Button icon="ios-cloud-upload-outline">选择图片</Button>
          </Upload>
        </FormItem>

        <FormItem v-if="editingBookmark.icon">
          <div class="icon-preview">
            <img :src="editingBookmark.icon" alt="预览图标" class="preview-icon">
          </div>
        </FormItem>
      </Form>
      <template #footer>
        <Button type="primary" @click="saveEditedBookmark" :loading="loading">保存更改</Button>
        <Button @click="closeEditModal" :disabled="loading" style="margin-left: 8px">取消</Button>
      </template>
    </Modal>
    <div class="pagination">
      <Page
        :total="total"
        :current="currentPage"
        :page-size="itemsPerPage"
        show-total
        show-elevator
        show-sizer
        :page-size-opts="[10, 20, 30, 50]"
        @on-change="handlePageChange"
        @on-page-size-change="handlePageSizeChange"
        @on-page-number-change="handlePageNumberChange"
        transfer
        class="custom-page"
      />
    </div>
    <Spin size="large" fix v-if="loading"></Spin>
    <Modal v-model="deleteModalVisible" title="确认删除">
      <p>确定要删除这个书签吗？</p>
      <template #footer>
        <Button type="primary" @click="confirmDelete" :loading="loading">确定</Button>
        <Button @click="deleteModalVisible = false" :disabled="loading" style="margin-left: 8px">取消</Button>
      </template>
    </Modal>
    <Modal v-model="isImportModalVisible" title="导入书签" :loading="loading" :closable="false" :mask-closable="false">
      <Form :label-width="80">
        <FormItem label="浏览器">
          <Select v-model="importConfig.browserType">
            <Option value="Chrome">Chrome</Option>
            <Option value="Firefox">Firefox</Option>
            <Option value="Edge">Edge</Option>
            <Option value="Safari">Safari</Option>
          </Select>
        </FormItem>

        <FormItem label="目标分类">
          <v-select v-model="importConfig.targetCategory" :options="categories" taggable></v-select>
        </FormItem>

        <FormItem label="书签文件">
          <Upload
            action=""
            :before-upload="handleImportFile"
            accept=".html"
            :headers="uploadHeaders"
          >
            <Button icon="ios-cloud-upload-outline">选择书签文件</Button>
          </Upload>
        </FormItem>

        <FormItem v-if="importConfig.fileName">
          <div class="file-info">
            已选择文件: {{ importConfig.fileName }}
          </div>
        </FormItem>
      </Form>
      <template #footer>
        <Button type="primary" @click="submitImport" :loading="loading" :disabled="!importConfig.file">导入</Button>
        <Button @click="closeImportModal" :disabled="loading" style="margin-left: 8px">取消</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import {
  createBookmark,
  modifyBookmark,
  deleteBookmark,
  findPage,
  addView,
  getCategoryList,
  importBookmarks,
  topBookmark
} from '@/api/bookmark';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import axios from 'axios';

export default {
  components: {
    vSelect
  },
  data() {
    return {
      bookmarks: [],
      filteredBookmarks: [],
      loading: false,
      searchQuery: '',
      selectedCategory: '',
      categories: [],
      currentBookmark: {
        id: '',
        name: '',
        url: '',
        category: [],
        icon: '',
        views: 0
      },
      isAddModalVisible: false,
      isEditModalVisible: false,
      editingBookmark: {
        name: '',
        url: '',
        category: [],
        icon: '',
        views: 0
      },
      currentPage: 1,
      itemsPerPage: 20,
      total: 0,
      newCategory: '',
      deleteModalVisible: false,
      bookmarkToDelete: null,
      uploadHeaders: {
        authorization: localStorage.getItem('token')
      },
      isImportModalVisible: false,
      importConfig: {
        browserType: 'chrome',
        targetCategory: '',
        file: null,
        fileName: ''
      }
    };
  },
  computed: {
    paginatedBookmarks() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredBookmarks.slice(start, end);
    }
  },
  methods: {
    showAddModal() {
      this.isAddModalVisible = true;
      this.currentBookmark = { id: Date.now(), name: '', url: '', category: [], icon: '', views: 0 };
    },
    closeModal() {
      if (this.loading) return; // 加载中不允许关闭
      this.isAddModalVisible = false;
      this.currentBookmark = {
        id: '',
        name: '',
        url: '',
        category: [],
        icon: '',
        views: 0
      };
    },
    addBookmark() {
      if (!this.currentBookmark.url) {
        this.$Message.error('URL 不能为空');
        return;
      }
      this.loading = true;
      const categoryString = Array.isArray(this.currentBookmark.category) ? this.currentBookmark.category.join(',') : this.currentBookmark.category;
      const bookmarkToCreate = {
        ...this.currentBookmark,
        category: categoryString
      };

      createBookmark(bookmarkToCreate).then((response) => {
        if(response.code == 0) {
          this.$Message.success('保存成功');
          this.loadBookmarks();
          this.isAddModalVisible = false;
          this.fetchCategories();
          this.closeModal();
        } else {
          this.$Message.error(response.msg);
        }
      }).catch(error => {
        console.error('添加书签失败:', error);
      }).finally(() => {
        this.loading = false;
      });
    },
    editBookmark(bookmark) {
      this.editingBookmark = JSON.parse(JSON.stringify({
        ...bookmark,
        category: Array.isArray(bookmark.categoryList) ? bookmark.categoryList : []
      }));
      this.isEditModalVisible = true;
    },
    deleteBookmark(id) {
      this.bookmarkToDelete = id;
      this.deleteModalVisible = true;
    },
    confirmDelete() {
      if (!this.bookmarkToDelete) return;
      
      this.loading = true;
      deleteBookmark(this.bookmarkToDelete)
        .then((response ) => {
          if(response.code == 0) {
            this.$Message.success('删除成功');
            this.loadBookmarks();
            this.fetchCategories();
            this.deleteModalVisible = false;
          } else {
            this.$Message.error(response.msg);
          }
        })
        .catch(error => {
          console.error('删除书签失败:', error);
        })
        .finally(() => {
          this.loading = false;
          this.bookmarkToDelete = null;
        });
    },
    loadBookmarks() {
      const maxPage = Math.ceil(this.total / this.itemsPerPage);
      if (this.currentPage > maxPage) {
        this.currentPage = maxPage || 1;
      }

      const params = {
        pageSize: this.itemsPerPage,
        firstResult: (this.currentPage - 1) * this.itemsPerPage,
        search: this.searchQuery,
        category: this.selectedCategory
      };
      
      this.loading = true;
      findPage(params).then(response => {
        this.bookmarks = response.retVal.datas;
        this.total = response.retVal.totalRows;
      }).catch(error => {
        console.error('加载书签失败:', error);
        this.$Message.error('加载书签失败');
      }).finally(() => {
        this.loading = false;
      });
    },
    saveEditedBookmark() {
      if (!this.editingBookmark.url) {
        this.$Message.error('URL 不能为空');
        return;
      }
      this.loading = true;
      const categoryString = Array.isArray(this.editingBookmark.category) ? this.editingBookmark.category.join(',') : this.editingBookmark.category;
      const bookmarkToUpdate = {
        ...this.editingBookmark,
        category: categoryString
      };
      modifyBookmark(bookmarkToUpdate).then((response) => {
        if(response.code == 0) {
          this.$Message.success('保存成功');
          this.loadBookmarks();
          this.fetchCategories();
          this.isEditModalVisible = false;
          this.closeEditModal();
        } else {
          this.$Message.error(response.msg);
        }
      }).catch(error => {
        console.error('保存编辑书签失败:', error);
      }).finally(() => {
        this.loading = false;
      });
    },
    closeEditModal() {
      if (this.loading) return; // 加载中不允许关闭
      this.isEditModalVisible = false;
      this.editingBookmark = {  
        name: '',
        url: '',
        category: [],
        icon: '',
        views: 0
      };
    },
    handleIconUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.currentBookmark.icon = e.target.result;
        };
        reader.readAsDataURL(file);
        
        const formData = new FormData();
        formData.append("file", file);

        axios.post("https://www.bjca.xyz/diary/message/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if(response.data.retVal) {
            this.currentBookmark.icon = response.data.retVal;
          }
        })
        .catch((error) => {
          this.$Message.error('图标上传失败');
          console.error('上传图标失败:', error);
        });
      }
    },
    handleEditIconUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.editingBookmark.icon = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    handlePageChange(page) {
      if (page !== this.currentPage) {
        this.currentPage = page;
        this.loadBookmarks();
      }
    },
    handlePageSizeChange(pageSize) {
      this.itemsPerPage = pageSize;
      this.currentPage = 1; // 重置到第一页
      this.loadBookmarks();
    },
    handlePageNumberChange(page) {
      const maxPage = Math.ceil(this.total / this.itemsPerPage);
      if (page && page > 0 && page <= maxPage) {
        this.currentPage = page;
        this.$nextTick(() => {
          this.loadBookmarks();
        });
      } else {
        this.$Message.warning('请输入有效的页码');
        this.currentPage = Math.min(Math.max(1, this.currentPage), maxPage);
      }
    },
    fetchCategories() {
      getCategoryList().then(response => {
        this.categories = response.retVal;
      }).catch(error => {
        console.error('获取分类列表失败:', error);
      });
    },
    incrementViewCount(bookmarkId) {
      addView(bookmarkId)
      .then(() => {
        this.bookmarks.find(bookmark => bookmark.bookmarkId === bookmarkId).views++;
      })
      .catch(error => {
        console.error('增加浏览次数失败:', error);
      });
    },
    handleImageError(event) {
      event.target.src = 'https://www.bjca.xyz/lidachui-files/bookmark_icon.png';
    },
    triggerSearch() {
      this.currentPage = 1;
      this.loadBookmarks();
    },
    handleCategoryChange(value) {
      this.currentPage = 1;
      this.loadBookmarks();
    },
    selectCategory(category) {
      this.selectedCategory = category;
      this.currentPage = 1; // 重置页码到第一页
      this.loadBookmarks(); // 直接加载数据
    },
    showImportModal() {
      this.isImportModalVisible = true;
      this.importConfig = {
        browserType: 'chrome',
        targetCategory: '',
        file: null,
        fileName: ''
      };
    },
    closeImportModal() {
      if (this.loading) return;
      this.isImportModalVisible = false;
      this.importConfig = {
        browserType: 'chrome',
        targetCategory: '',
        file: null,
        fileName: ''
      };
    },
    handleImportFile(file) {
      this.importConfig.file = file;
      this.importConfig.fileName = file.name;
      return false;
    },
    submitImport() {
      if (!this.importConfig.file) {
        this.$Message.error('请选择书签文件');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.importConfig.file);
      formData.append('browserType', this.importConfig.browserType);
      formData.append('targetCategory', this.importConfig.targetCategory || '');

      this.loading = true;
      importBookmarks(formData)
        .then(response => {
          if(response.code == 0) {
            this.$Message.success('书签导入成功');
            this.loadBookmarks();
            this.fetchCategories();
            this.closeImportModal(); 
          } else {
            this.$Message.error(response.msg);
          }
        })
        .catch(error => {
          console.error('导入书签失败:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleTop(bookmark) {
      const newTopStatus = bookmark.top === 'T' ? 'F' : 'T';
      this.loading = true;
      
      topBookmark( {
          bookmarkId: bookmark.bookmarkId,
          top: newTopStatus
        }
       
      ).then((response) => {
        if (response.code === 0) {
          bookmark.top = newTopStatus;
          this.$Message.success(newTopStatus === 'T' ? '已置顶' : '已取消置顶');
          this.loadBookmarks(); // 重新加载列表以更新顺序
        } else {
          this.$Message.error(response.data.msg || '操作失败');
        }
      }).catch(error => {
        console.error('置顶操作失败:', error);
        this.$Message.error('操作失败');
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  mounted() {
    this.loadBookmarks();
    this.fetchCategories();
  }
};
</script>

<style scoped>
.bookmark-container {
  padding: 24px;
  background: #f6f8fa;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  max-width: 100%;
  box-sizing: border-box;
  min-height: 100vh;
  overflow-x: hidden;
}

.operation-bar {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  align-items: center;
  padding: 16px;
  background: linear-gradient(to right, #ffffff, #f8fafc);
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  margin-bottom: 24px;
}

.search-input {
  width: 280px;
}

.select-category {
  width: 180px;
}

.button-style {
  padding: 0 20px;
  height: 36px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #5c67f2, #4a53d6);
  border: none;
  color: white;
  font-weight: 600;
  height: 40px;
  padding: 0 24px;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(92, 103, 242, 0.25);
  }
}

.bookmark-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  padding: 6px;
  margin-bottom: 20px;
}

.bookmark-card {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid rgba(92, 103, 242, 0.1);
  min-height: 140px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;
  
  /* 添加初始状态 */
  transform: translateY(0) scale(1);
  filter: brightness(1);
}

.bookmark-card:hover {
  transform: translateY(-8px) scale(1.02);
  box-shadow: 0 20px 30px rgba(92, 103, 242, 0.15);
  border-color: rgba(92, 103, 242, 0.3);
  z-index: 1;
  
  /* 添加发光效果 */
  filter: brightness(1.02);
  
  /* 添加微妙的渐变变化 */
  background: linear-gradient(165deg, #ffffff, #f0f4ff);
}

.bookmark-link {
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  color: #2c3e50;
  width: 100%;
  padding: 4px;
}

.bookmark-icon {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  padding: 6px;
  background: white;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  object-fit: cover;
  flex-shrink: 0;
}

.bookmark-card:hover .bookmark-icon {
  transform: scale(1.1) translateY(-4px);
  box-shadow: 0 8px 16px rgba(92, 103, 242, 0.2);
}

.bookmark-info {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.bookmark-info h3 {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  color: #1a1a1a;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  transition: all 0.3s ease;
}

.bookmark-card:hover .bookmark-info h3 {
  color: #5c67f2;
  transform: translateY(-2px);
}

.views-count {
  font-size: 13px;
  color: #64748b;
  margin: 0;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
}

.views-count::before {
  content: '👁';
  font-size: 12px;
}

.category-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin: 4px 0;
}

.category-tag {
  font-size: 12px;
  padding: 2px 10px;
  background-color: #f1f5f9;
  color: #475569;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.bookmark-card:hover .category-tag {
  transform: translateY(-1px);
  background: linear-gradient(135deg, #f0f4ff, #e6eafe);
  box-shadow: 0 2px 6px rgba(92, 103, 242, 0.1);
}

/* 深色模式适配 */
@media (prefers-color-scheme: dark) {
  .bookmark-card {
    background: linear-gradient(145deg, #1f2937, #1a1f2e);
  }
  
  .bookmark-card:hover {
    background: linear-gradient(165deg, #1f2937, #1e293b);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);
    border-color: rgba(92, 103, 242, 0.4);
  }
  
  .bookmark-card:hover .bookmark-info h3 {
    color: #8b9dff;
  }
  
  .bookmark-card:hover .category-tag {
    background: linear-gradient(135deg, #2d3748, #1f2937);
  }
  
  .bookmark-icon {
    background: #2d3748;
  }
  
  .bookmark-card:hover .action-button.edit {
    background-color: rgba(33, 150, 243, 0.2);
  }
  
  .bookmark-card:hover .action-button.delete {
    background-color: rgba(244, 67, 54, 0.2);
  }
}

.bookmark-actions {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid #f0f0f0;
  margin-top: auto;
}

.action-button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
  flex-shrink: 0;
  opacity: 0.7;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.bookmark-card:hover .action-button {
  opacity: 1;
  transform: translateY(-2px);
}

.bookmark-card:hover .action-button.edit {
  background-color: rgba(33, 150, 243, 0.1);
  color: #2196F3;
}

.bookmark-card:hover .action-button.delete {
  background-color: rgba(244, 67, 54, 0.1);
  color: #F44336;
}

.icon-preview {
  margin: 16px 0;
  padding: 20px;
  background-color: #f8fafc;
  border-radius: 12px;
  text-align: center;
}

.preview-icon {
  width: 56px;
  height: 56px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 16px;
}

@media (max-width: 768px) {
  .bookmark-container {
    padding: 16px;
  }

  .operation-bar {
    flex-direction: column;
    padding: 12px;
    gap: 8px;
  }

  .search-input,
  .select-category,
  .button-style {
    width: 100%;
  }

  .bookmark-grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 12px;
  }

  .bookmark-card {
    padding: 10px;
  }

  .pagination button {
    padding: 10px 20px;
  }
}

/* 添加一动画效果 */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.bookmark-card {
  animation: fadeIn 0.3s ease-out;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #c0c6d0;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a0a6b0;
}

/* Modal 样式优化 */
:deep(.ivu-modal) {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

:deep(.ivu-modal-header) {
  background: #f8fafc;
  padding: 20px 24px;
  border-bottom: 1px solid #edf2f7;
}

:deep(.ivu-modal-header-inner) {
  font-size: 18px;
  font-weight: 600;
  color: #1a202c;
}

:deep(.ivu-modal-content) {
  border-radius: 16px;
}

:deep(.ivu-modal-body) {
  padding: 24px;
}

:deep(.ivu-modal-footer) {
  border-top: 1px solid #edf2f7;
  padding: 16px 24px;
  background: #f8fafc;
}

/* Form 样式优化 */
:deep(.ivu-form-item) {
  margin-bottom: 20px;
}

:deep(.ivu-form-item-label) {
  font-size: 14px;
  font-weight: 500;
  color: #4a5568;
}

:deep(.ivu-input) {
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  padding: 8px 12px;
  transition: all 0.3s ease;
}

:deep(.ivu-input:hover) {
  border-color: #5c67f2;
}

:deep(.ivu-input:focus) {
  border-color: #5c67f2;
  box-shadow: 0 0 0 2px rgba(92, 103, 242, 0.2);
}

/* v-select 样式优化 */
:deep(.v-select) {
  border-radius: 8px;
}

:deep(.v-select .vs__dropdown-toggle) {
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 4px 8px;
}

:deep(.v-select .vs__selected) {
  background: #edf2f7;
  border-radius: 4px;
  padding: 2px 8px;
  margin: 2px;
}

:deep(.v-select .vs__deselect) {
  margin-left: 4px;
}

/* Upload 按钮样式 */
:deep(.ivu-upload) {
  width: 100%;
}

:deep(.ivu-upload .ivu-btn) {
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #f8fafc;
  border: 2px dashed #e2e8f0;
  transition: all 0.3s ease;
}

:deep(.ivu-upload .ivu-btn:hover) {
  border-color: #5c67f2;
  background: #f0f4ff;
}

/* Modal 按钮样式 */
:deep(.ivu-modal-footer .ivu-btn) {
  padding: 8px 24px;
  height: 40px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.3s ease;
}

:deep(.ivu-modal-footer .ivu-btn-primary) {
  background: #5c67f2;
  border-color: #5c67f2;
}

:deep(.ivu-modal-footer .ivu-btn-primary:hover) {
  background: #4a53d6;
  border-color: #4a53d6;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(92, 103, 242, 0.2);
}

/* 图标预览样式优化 */
.icon-preview {
  margin: 16px 0;
  padding: 24px;
  background: linear-gradient(to bottom right, #f8fafc, #f0f4ff);
  border-radius: 12px;
  text-align: center;
  border: 1px solid #e2e8f0;
}

.preview-icon {
  width: 64px;
  height: 64px;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.preview-icon:hover {
  transform: scale(1.05);
}

/* 输入框必填样式 */
.input-required {
  border-color: #ff4d4f !important;
}

/* 响应式调整 */
@media (max-width: 768px) {
  :deep(.ivu-modal-body) {
    padding: 16px;
  }
  
  :deep(.ivu-modal-header) {
    padding: 16px;
  }
  
  :deep(.ivu-modal-footer) {
    padding: 12px 16px;
  }
}

/* 深色模式调整 */
@media (prefers-color-scheme: dark) {
  .bookmark-container {
    background: #1a1f25;
  }

  .bookmark-card {
    background: #2d3748;
    border-color: rgba(255, 255, 255, 0.1);
  }

  .bookmark-info h3 {
    color: #ffffff;
  }
}

/* 添加可点击的 Tooltip 链接样式 */
:deep(.tooltip-link) {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

:deep(.tooltip-link:hover) {
  text-decoration: underline;
}

:deep(.ivu-tooltip-inner) {
  max-width: 300px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  text-align: center;
  line-height: 1.5;
  padding: 8px 12px;
  font-size: 13px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
}

.category-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin: 2px 0;
}

.category-tag {
  font-size: 11px;
  padding: 1px 8px;
  background-color: #f0f4ff;
  color: #5c67f2;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.category-tag:hover {
  background-color: #5c67f2;
  color: white;
  transform: translateY(-1px);
}

/* 深色模式适配 */
@media (prefers-color-scheme: dark) {
  .category-tag {
    background-color: #3a4556;
    color: #8b9dff;
  }
  
  .category-tag:hover {
    background-color: #5c67f2;
    color: white;
  }
}

.bookmark-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px;
  flex: 1;
  min-width: 0;
  overflow: visible;
}

.bookmark-link {
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  color: #2c3e50;
  width: 100%;
}

/* 添加/修改以下样式 */
:deep(.ivu-modal-wrap) {
  position: fixed;
  z-index: 1000;
}

:deep(.ivu-modal) {
  position: relative;
}

:deep(.ivu-spin-fix) {
  position: absolute;
  z-index: 2000;
}

/* 自定义分页样式 */
:deep(.custom-page) {
  .ivu-page-item {
    border-radius: 4px;
    transition: all 0.3s ease;
    
    &:hover {
      border-color: #5c67f2;
      color: #5c67f2;
    }
    
    &.ivu-page-item-active {
      background: #5c67f2;
      border-color: #5c67f2;
      
      &:hover {
        background: #4a53d6;
      }
    }
  }

  .ivu-page-options-elevator {
    input {
      border-radius: 4px;
      transition: all 0.3s ease;
      
      &:hover, &:focus {
        border-color: #5c67f2;
      }
    }
  }

  .ivu-page-options-sizer {
    .ivu-select-selection {
      border-radius: 4px;
      
      &:hover {
        border-color: #5c67f2;
      }
    }
  }

  .ivu-page-prev, .ivu-page-next {
    border-radius: 4px;
    transition: all 0.3s ease;
    
    &:hover {
      border-color: #5c67f2;
      color: #5c67f2;
    }
  }

  .ivu-page-item-jump-next, .ivu-page-item-jump-prev {
    border-radius: 4px;
  }
}

/* 深色模式适配 */
@media (prefers-color-scheme: dark) {
  :deep(.custom-page) {
    .ivu-page-item {
      background: #2d3748;
      border-color: #4a5568;
      color: #e2e8f0;
      
      &:hover {
        border-color: #5c67f2;
        color: #5c67f2;
      }
      
      &.ivu-page-item-active {
        background: #5c67f2;
        border-color: #5c67f2;
        color: white;
      }
    }

    .ivu-page-prev, .ivu-page-next {
      background: #2d3748;
      border-color: #4a5568;
      color: #e2e8f0;
      
      &:hover {
        border-color: #5c67f2;
        color: #5c67f2;
      }
    }

    .ivu-page-options-elevator {
      input {
        background: #2d3748;
        border-color: #4a5568;
        color: #e2e8f0;
      }
    }

    .ivu-select-selection {
      background: #2d3748;
      border-color: #4a5568;
      color: #e2e8f0;
    }
  }
}

/* 添加输入框的样式优化 */
:deep(.ivu-page-options-elevator) {
  input {
    width: 50px;
    text-align: center;
    margin: 0 6px;
    
    &:focus {
      border-color: #5c67f2;
      box-shadow: 0 0 0 2px rgba(92, 103, 242, 0.2);
    }
  }
}

:deep(.ivu-page-options) {
  margin-left: 15px;
}

/* 添加跳转按钮的样式 */
:deep(.ivu-page-options-elevator button) {
  min-width: 32px;
  padding: 0 8px;
  font-size: 12px;
  margin-left: 6px;
  border-radius: 4px;
  
  &:hover {
    color: #5c67f2;
    border-color: #5c67f2;
  }
}

/* 添加或修改Select相关样式 */
:deep(.select-category) {
  .ivu-select-selection {
    border-radius: 8px;
    
    &:hover {
      border-color: #5c67f2;
    }
  }

  .ivu-select-input {
    height: 32px;
  }

  .ivu-tag {
    margin: 2px;
    background: #f0f4ff;
    border-color: transparent;
    color: #5c67f2;
    
    .ivu-icon {
      color: #5c67f2;
    }
  }
}

/* 修改 Tooltip 相关样式 */
:deep(.ivu-tooltip) {
  z-index: 1100 !important; /* 确保比其他元素层级高 */
}

:deep(.ivu-tooltip-popper) {
  z-index: 1100 !important;
}

:deep(.ivu-tooltip-inner) {
  max-width: 300px;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  text-align: center;
  line-height: 1.5;
  padding: 8px 12px;
  font-size: 13px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/* 确保 Tooltip 容器不会被截 */
.bookmark-card {
  overflow: visible; /* 修改为 visible */
}

.bookmark-content {
  overflow: visible; /* 修改为 visible */
}

/* 1. 美化操作栏 */
.operation-bar {
  background: linear-gradient(to right, #ffffff, #f8fafc);
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  padding: 20px;
  gap: 16px;
}

.button-style {
  background: linear-gradient(135deg, #5c67f2, #4a53d6);
  border: none;
  color: white;
  font-weight: 600;
  height: 40px;
  padding: 0 24px;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(92, 103, 242, 0.25);
  }
}

/* 2. 美化书签卡片 */
.bookmark-card {
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  border: 1px solid rgba(92, 103, 242, 0.1);
  padding: 20px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  
  &:hover {
    transform: translateY(-4px) scale(1.02);
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  }
}

.bookmark-icon {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  padding: 6px;
  background: white;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.1);
  }
}

/* 3. 美化分类标签 */
.category-tag {
  background: linear-gradient(135deg, #f0f4ff, #e6eafe);
  color: #5c67f2;
  font-weight: 600;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 12px;
  
  &:hover {
    background: linear-gradient(135deg, #5c67f2, #4a53d6);
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(92, 103, 242, 0.2);
  }
}

/* 4. 美化操作按钮 */
.action-button {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  
  &.edit {
    background: #e6eafe;
    color: #5c67f2;
    
    &:hover {
      background: #5c67f2;
      color: white;
      transform: translateY(-2px);
    }
  }
  
  &.delete {
    background: #fee2e2;
    color: #ef4444;
    
    &:hover {
      background: #ef4444;
      color: white;
      transform: translateY(-2px);
    }
  }
}

/* 5. 深色模式优化 */
@media (prefers-color-scheme: dark) {
  .bookmark-container {
    background: #111827;
  }
  
  .operation-bar {
    background: linear-gradient(to right, #1f2937, #1a1f2e);
  }
  
  .bookmark-card {
    background: linear-gradient(145deg, #1f2937, #1a1f2e);
    border-color: rgba(255, 255, 255, 0.1);
  }
  
  .category-tag {
    background: linear-gradient(135deg, #2d3748, #1f2937);
    color: #8b9dff;
    
    &:hover {
      background: linear-gradient(135deg, #5c67f2, #4a53d6);
      color: white;
    }
  }
  
  .bookmark-icon {
    background: #2d3748;
  }
  
  .action-button {
    &.edit {
      background: #2d3748;
      color: #8b9dff;
    }
    
    &.delete {
      background: #3d2828;
      color: #f87171;
    }
  }
}

/* 6. 添加加载动画 */
.bookmark-card {
  animation: fadeInUp 0.6s ease-out;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 7. 美化滚动条 */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f5f9;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(135deg, #5c67f2, #4a53d6);
  border-radius: 8px;
  
  &:hover {
    background: linear-gradient(135deg, #4a53d6, #3b44c5);
  }
}

.div-tags {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 6px 12px;
  background: linear-gradient(to right, #ffffff, #f8fafc);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
  padding: 8px;
}

.ul-c {
  height: 32px;
  background: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 8px;
  overflow: hidden;
  width: calc(100% - 120px);
}

.ul-c li {
  border-radius: 6px;
  cursor: pointer;
  font-size: 12px;
  height: 26px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  border: 1px solid rgba(92, 103, 242, 0.1);
  background: linear-gradient(145deg, #ffffff, #f8fafc);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.ul-c li:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 6px rgba(92, 103, 242, 0.12);
}

.ul-c li.active {
  background: linear-gradient(135deg, #5c67f2, #4a53d6);
  border: 1px solid transparent;
  color: white;
}

.li-a {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  color: #475569;
  font-size: 12px;
}

.ul-c .ivu-icon {
  margin-left: 6px;
  font-size: 12px;
  transition: all 0.2s ease;
}

.div-icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: transparent;
  height: 32px;
  width: 120px;
  font-size: 16px;
  gap: 8px;
}

.refresh-c, .tag-options {
  width: 26px;
  height: 26px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #f1f5f9;
  transition: all 0.2s ease;
}

.refresh-c:hover, .tag-options:hover {
  background: #e2e8f0;
  transform: translateY(-1px);
}

@media (prefers-color-scheme: dark) {
  .div-tags {
    background: linear-gradient(to right, #1f2937, #1a1f2e);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .ul-c li {
    background: #1f2937;
    border-color: rgba(255, 255, 255, 0.1);
  }
  
  .li-a {
    color: #e2e8f0;
  }
  
  .refresh-c, .tag-options {
    background: #334155;
  }
  
  .refresh-c:hover, .tag-options:hover {
    background: #475569;
  }
}

@media (max-width: 768px) {
  .div-tags {
    margin: 6px;
    padding: 6px;
  }
  
  .ul-c {
    width: calc(100% - 80px);
  }
  
  .div-icons {
    width: 80px;
  }
  
  .li-a {
    max-width: 60px;
  }
}

.top-indicator {
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 2px solid #5c67f2;
  border-radius: 14px;
  pointer-events: none;
  z-index: 1;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.top-indicator::after {
  content: '⭐';
  position: absolute;
  top: -8px;
  right: -8px;
  background: #5c67f2;
  color: white;
  width: 16px;
  height: 16px;
  font-size: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(92, 103, 242, 0.2);
}

.bookmark-card:hover .top-indicator {
  border-color: #4a53d6;
  box-shadow: 0 0 0 2px rgba(92, 103, 242, 0.1);
}

/* 深色模式适配 */
@media (prefers-color-scheme: dark) {
  .top-indicator {
    border-color: #8b9dff;
  }
  
  .top-indicator::after {
    background: #8b9dff;
  }
  
  .bookmark-card:hover .top-indicator {
    border-color: #5c67f2;
  }
}

/* 移除之前的内容padding调整 */
.bookmark-content {
  padding-top: initial;
}

.bookmark-link {
  margin-top: initial;
}
</style>