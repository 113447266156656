<template>
  <div class="note-app" ref="noteApp">
    <div
      class="sidebar"
      :class="{ active: isSidebarOpen, collapsed: isSidebarCollapsed }"
    >
      <div class="header">
        <div class="header">
          <h2 class="header-title">笔记目录</h2>
          <Button
            @click="isSidebarCollapsed = !isSidebarCollapsed"
            class="collapse-button"
            style="padding: 0"
          >
            <Icon
              :type="
                isSidebarCollapsed ? 'ios-arrow-forward' : 'ios-arrow-back'
              "
            />
          </Button>
        </div>

        <Input
          v-model="searchQuery"
          type="text"
          placeholder="搜索..."
          class="search-input"
          @on-enter="getNoteList"
          @on-change="handleSearch"
        />

        <div class="button-group">
          <Button @click="showAddFolderDialog" class="action-button primary">
            <Icon type="ios-folder" /> 新增目录
          </Button>
          <Button @click="showAddNoteDialog" class="action-button primary">
            <Icon type="ios-paper" /> 新增笔记
          </Button>
          <Button @click="showBatchImportDialog" class="action-button primary">
            <Icon type="md-cloud-upload" /> 批量导入
          </Button>
        </div>
      </div>

      <div class="tree-wrapper">
        <Tree
          :data="treeData"
          :render="renderTreeNode"
          class="demo-tree-render"
          :expand-node="true"
          @on-toggle-expand="toggleExpand"
        />
      </div>
    </div>
    <div
      class="content"
      :class="{
        shift: isSidebarOpen,
        expanded: isSidebarCollapsed,
        fullscreen: isFullscreen,
      }"
    >
      <transition name="fade">
        <div v-if="selectedNote" class="note-details">
          <div class="note-header">
            <Button
              v-if="isEditing"
              @click="saveNote"
              class="action-button save-button"
              >保存</Button
            >
            <Button
              v-if="!isEditing"
              @click="editNote"
              class="action-button edit-button"
              >编辑</Button
            >
            <Button @click="downloadNote" class="action-button download-button"
              >下载</Button
            >
            <Button @click="toggleFullscreen" class="action-button secondary"
              >全屏</Button
            >
            <Button @click="openShareDialog" class="action-button share-button"
              >分享</Button
            >
          </div>
          <div v-if="isEditing" class="note-editor">
            <Input
              v-model="selectedNote.noteTitle"
              type="text"
              placeholder="笔记标题"
              class="title-input"
            />
            <MarkdownPro
              v-model="selectedNote.noteContent"
              :exportFileName="selectedNote.noteTitle"
              class="markdown-editor"
              :options="editorOptions"
              @on-copy="onCopy"
              :toolbars="toolbars"
            />
          </div>
          <div v-else class="note-preview">
            <h3>{{ selectedNote.noteTitle }}</h3>
            <MarkdownPreview
              :initialValue="selectedNote.noteContent"
              class="markdown-preview"
              @on-copy="onCopy"
            />
          </div>
        </div>
        <div v-else class="placeholder">
          <div class="placeholder-content">
            <h3>没有选择笔记</h3>
            <p>请在左侧目录中选择一个笔记以查看详细内容。</p>
          </div>
        </div>
      </transition>
    </div>

    <Modal
      v-model="showAddFolder"
      title="新增目录"
      class="ivu-modal"
      :mask-closable="false"
    >
      <Card>
        <div class="modal-body">
          <label for="folderTitle" class="required">目录名称</label>
          <Input
            id="folderTitle"
            v-model="newFolderTitle"
            type="text"
            placeholder="请输入目录名称"
            class="folder-input"
          />
          <label for="parentFolder" class="noRequired">选择父级目录</label>
          <Treeselect
            id="parentFolder"
            v-model="selectedAddFolderFolderId"
            :options="folderTreeData"
            placeholder="请选择父级目录"
            class="folder-select"
          />
        </div>
      </Card>
      <div slot="footer">
        <Button type="primary" @click="closeAddFolderDialog">取消</Button>
        <Button type="primary" @click="addFolder">确定</Button>
      </div>
    </Modal>

    <Modal v-model="showAddNote" title="新增笔记" class="ivu-modal">
      <Card>
        <div class="modal-body">
          <label for="noteTitle" class="required">笔记标题</label>
          <Input
            id="noteTitle"
            v-model="newNoteTitle"
            type="text"
            placeholder="请输入笔记标题"
            class="note-input"
          />
          <label for="noteFolder" class="required">选择目录</label>
          <Treeselect
            id="noteFolder"
            v-model="selectedAddNoteFolderId"
            :options="noteFolderTreeData"
            placeholder="请选择目录"
            class="note-select"
          />
        </div>
      </Card>
      <div slot="footer">
        <Button type="primary" @click="closeAddNoteDialog">取消</Button>
        <Button type="primary" @click="addNote">确定</Button>
      </div>
    </Modal>

    <Modal
      v-model="showDeleteConfirm"
      title="确认删除"
      class="ivu-modal"
      @on-ok="confirmDelete"
      @on-cancel="cancelDelete"
    >
      <p>{{ deleteModalContent }}</p>
    </Modal>

    <Modal
      v-model="showBatchImport"
      title="批量导入笔记"
      class="ivu-modal"
      :mask-closable="false"
    >
      <Card>
        <label for="batchFolder" class="required">选择目录</label>
        <Treeselect
          id="batchFolder"
          v-model="selectedBatchImportFolderId"
          :options="noteFolderTreeData"
          placeholder="请选择目录"
          class="folder-select"
        />
        <label for="fileInput" class="required"
          >上传文件 (仅支持 .md 文件)</label
        >
        <Upload
          multiple
          action="//jsonplaceholder.typicode.com/posts/"
          :before-upload="beforeUpload"
          accept=".md"
        >
          <Button type="default">选择 Markdown 文件</Button>
        </Upload>
        <div>
          <ul class="file-list">
            <li
              v-for="(file, index) in uploadedFiles"
              :key="index"
              class="file-item"
            >
              <Icon type="ios-document" size="20" class="file-icon" />
              <span class="file-name">{{ file.name }}</span>
              <Button
                type="error"
                size="small"
                @click="removeFile(index)"
                class="delete-button"
              >
                <Icon type="ios-trash" size="16" />
              </Button>
            </li>
          </ul>
        </div>
      </Card>
      <div slot="footer">
        <Button type="primary" @click="closeBatchImportDialog">取消</Button>
        <Button type="primary" @click="importNotes">确定</Button>
      </div>
    </Modal>

    <Modal v-model="showShareDialog" title="分享笔记" @on-ok="closeShareDialog">
      <div class="share-dialog-content">
        <div>
          <div class="share-title">分享链接</div>
          <div class="share-input-wrapper">
            <Input 
              v-model="shareLink" 
              readonly 
              class="share-input"
              :value="shareLink"
            />
            <Button @click="copyLink" class="copy-button">
              <Icon type="md-copy" style="margin-right: 4px"/>
              复制链接
            </Button>
          </div>
        </div>
        <div class="share-tips">
          <p>🔒 安全提示：</p>
          <p>• 链接有效期为7天</p>
          <p>• 任何获得链接的人都可以查看该笔记</p>
          <p>• 请谨慎分享包含敏感信息的笔记</p>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  listNotes,
  saveNote,
  modifyNote,
  saveDirectory,
  deleteDirectory,
  deleteNote,
  modifyNoteDirectory,
  noteById,
  batchImportNotes,
  buildShareKey
} from "@/api/note";
import MarkdownPro from '@/components/markdown/pro';
import MarkdownPreview from '@/components/markdown/preview';
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { Message } from "view-design";
import Clipboard from 'clipboard';

export default {
  components: {
    MarkdownPro,
    MarkdownPreview,
    Treeselect,
  },
  data() {
    return {
      showShareDialog: false,
      shareLink: "",
      toolbars: {
        h4: true,
        h5: true,
        h6: true,
        uploadImage: true,
        print: true,
        save: true,
        clear: true,
      },
      folders: [],
      selectedNote: null,
      searchQuery: "",
      isEditing: false,
      showAddFolder: false,
      showAddNote: false,
      showDeleteConfirm: false,
      newFolderTitle: "",
      newNoteTitle: "",
      selectedFolderId: "",
      deleteTarget: null,
      deleteType: "",
      deleteModalContent: "确认删除该目录？操作不可撤销！",
      editorOptions: {
        placeholder: "在此输入笔记内容...",
        theme: "dark",
      },
      isSidebarOpen: false,
      selectedAddFolderFolderId: null,
      selectedAddNoteFolderId: null,
      deleteNoteDirectoryId: null,
      editFolderTitle: "",
      editingFolderId: null,
      expandedKeys: [],
      showBatchImport: false,
      selectedBatchImportFolderId: null, // 选中的目录ID
      uploadedFiles: [], // 上传的文件列表
      isSidebarCollapsed: false,
      isFullscreen: false,
    };
  },
  computed: {
    treeData() {
      const buildTree = (folders) => {
        return folders.map((folder) => {
          const isExpanded = this.expandedKeys.includes(folder.directoryId);

          // 处理 notesList 为 null 的情况
          let children = [];
          if (folder.notesList) {
            children = folder.notesList.map((note) => ({
              noteTitle: note.noteTitle,
              isLeaf: true,
              noteId: note.noteId,
              noteContent: note.noteContent,
              directoryId: note.directoryId,
            }));
          }

          // 处理子目录
          if (folder.children && folder.children.length > 0) {
            children = children.concat(buildTree(folder.children));
          }

          return {
            parentId: folder.parentId,
            directoryName: folder.directoryName,
            children: children,
            directoryId: folder.directoryId,
            isOpen: folder.isOpen,
            listOrder: folder.listOrder,
            expand: isExpanded,
          };
        });
      };
      return buildTree(this.folders);
    },
    folderTreeData() {
      const buildTree = (folders) => {
        return folders.map((folder) => ({
          id: folder.directoryId,
          label: folder.directoryName,
          children:
            folder.children && folder.children.length > 0
              ? buildTree(folder.children)
              : [],
        }));
      };
      return [
        {
          id: null,
          label: "根目录",
          children: buildTree(this.folders),
        },
      ];
    },
    noteFolderTreeData() {
      const buildTree = (folders) => {
        return folders.map((folder) => ({
          id: folder.directoryId,
          label: folder.directoryName,
          children:
            folder.children && folder.children.length > 0
              ? buildTree(folder.children)
              : [],
        }));
      };
      return buildTree(this.folders);
    },
  },
  mounted() {
    this.getNoteList();
  },
  methods: {
    openShareDialog() {
      buildShareKey({noteId: this.selectedNote.noteId}).then(res =>{
        if(res.code !== 0){
          this.$Message.error("获取分享链接失败:" + res.msg);
        }else{
          let shareKey = res.retVal;
          this.shareLink = `${window.location.origin}/#/share?shareKey=${shareKey}`;
          this.showShareDialog = true;
        }
      })
    },
    closeShareDialog() {
      this.showShareDialog = false;
    },
    copyLink() {
      this.onCopy(this.shareLink);
    },
    removeFile(index) {
      this.uploadedFiles.splice(index, 1);
    },
    beforeUpload(file) {
      this.uploadedFiles.push(file);
      return false;
    },
    showBatchImportDialog() {
      this.showBatchImport = true;
    },
    closeBatchImportDialog() {
      this.showBatchImport = false;
      this.selectedBatchImportFolderId = null;
      this.uploadedFiles = []; // 清空已上传文件
    },
    async importNotes() {
      if (!this.selectedBatchImportFolderId) {
        Message.error("请选择目录！");
        return;
      }

      if (this.uploadedFiles.length === 0) {
        Message.error("请上传文件！");
        return;
      }
      batchImportNotes(
        this.uploadedFiles,
        this.selectedBatchImportFolderId
      ).then((res) => {
        if (res.code == 0) {
          Message.success("导入成功！");
          this.closeBatchImportDialog();
          this.getNoteList();
        } else {
          Message.error("导入失败！");
        }
      });
    },
    toggleExpand(val) {
      const directoryId = val.directoryId;
      if (val.expand) {
        // 当 val.expand 为 true 时，添加 directoryId
        if (!this.expandedKeys.includes(directoryId)) {
          this.expandedKeys.push(directoryId);
        }
      } else {
        // 当 val.expand 为 false 时，从 expandedKeys 删除 directoryId
        const index = this.expandedKeys.indexOf(directoryId);
        if (index !== -1) {
          this.expandedKeys.splice(index, 1);
        }
      }
    },
    moveNoteToFolder(noteId, targetFolderId) {
      this.traverseDirectories(this.folders, (folder) => {
        const noteIndex = folder.notesList.findIndex(
          (note) => note.noteId === noteId
        );
        if (noteIndex !== -1) {
          const note = folder.notesList[noteIndex];
          folder.notesList.splice(noteIndex, 1); // 从当前目录中移除
          // 找到目标目录并添加笔记
          this.traverseDirectories(this.folders, (targetFolder) => {
            if (targetFolder.directoryId === targetFolderId) {
              targetFolder.notesList.push(note);

              const modifyNoteData = {
                noteId: note.noteId,
                directoryId: targetFolderId,
              };
              modifyNote(modifyNoteData).then((res) => {
                if (res.code === 0) {
                  Message.success("笔记移动成功");
                } else {
                  Message.error("笔记移动失败");
                }
              });
            }
          });
        }
      });
    },
    updateFolder() {
      if (!this.editFolderTitle.trim()) {
        Message.error("目录名称不能为空！");
        return;
      }
      const modifyId = this.editingFolderId;
      const modifyTitle = this.editFolderTitle;
      
      this.traverseDirectories(this.folders, (folder) => {
        if (folder.directoryId === modifyId) {
          folder.directoryName = modifyTitle;
          modifyNoteDirectory(folder).then((res) => {
            if (res.code === 0) {
              Message.success("目录名称修改成功");
              this.editingFolderId = null;
              // 重新获取目录列表以更新视图
              this.getNoteList();
            } else {
              Message.error("目录名称修改失败：" + res.msg);
            }
          });
        }
      });
    },
    getNoteList() {
      listNotes({ search: this.searchQuery }).then((res) => {
        if (res.code !== 0) {
          this.$Message.error(res.msg);
          return;
        }
        // 确保在更新数据前重置状态
        this.selectedNote = null;
        this.expandedKeys = [];
        
        const list = res.retVal;
        this.traverseDirectories(list, (directory) => {
          directory.isOpen = false;
        });
        this.folders = list;
        
        // 强制更新视图
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      });
    },
    handleSearch: debounce(function() {
      this.getNoteList();
    }, 300),
    toggleFolder(folder) {
      folder.isOpen = !folder.isOpen;
    },
    confirmDeleteNode(node) {
      if (node.isLeaf) {
        this.confirmDeleteNote(node.directoryId, node.noteId);
      } else {
        this.confirmDeleteFolder(node.directoryId);
      }
    },
    startEditFolder(folder) {
      this.editingFolderId = folder.directoryId;
      this.editFolderTitle = folder.directoryName;
    },
    renderTreeNode(h, { data }) {
      const isSelected =
        this.selectedNote && data.noteId === this.selectedNote.noteId;
      const isEditing = this.editingFolderId === data.directoryId;

      const renderLeafNode = () => (
        <div
          draggable
          onDragstart={(event) => {
            event.dataTransfer.setData("noteId", data.noteId);
          }}
          onDragover={(event) => {
            event.preventDefault(); // 允许拖拽
          }}
          onDrop={(event) => {
            event.preventDefault();
            const noteId = event.dataTransfer.getData("noteId");
            this.moveNoteToFolder(noteId, data.directoryId); // 移动笔记
          }}
          style={{
            cursor: "pointer",
            backgroundColor: isSelected ? "#e0f7fa" : "transparent",
            padding: "5px 10px",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            this.handleNodeClick(data);
          }}
        >
          <Icon type="ios-paper-outline" style={{ marginRight: "8px" }} />
          <span style={{ flex: 1 }}>{data.noteTitle}</span>
          <Icon
            type="ios-trash"
            style={{ color: "red", cursor: "pointer", marginLeft: "10px" }}
            onClick={(event) => {
              event.stopPropagation();
              this.confirmDeleteNode(data);
            }}
          />
        </div>
      );

      const renderFolderNode = () => (
        <div
          style={{
            cursor: "pointer",
            backgroundColor: isSelected ? "#e0f7fa" : "transparent",
            padding: "5px 10px",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            this.handleNodeClick(data);
          }}
          onDragover={(event) => {
            event.preventDefault(); // 允许拖拽
          }}
          onDrop={(event) => {
            event.preventDefault();
            const noteId = event.dataTransfer.getData("noteId");
            this.moveNoteToFolder(noteId, data.directoryId); // 移动笔记
          }}
        >
          <Icon type="ios-folder-outline" style={{ marginRight: "8px" }} />
          {isEditing ? (
            <Input
              v-model={this.editFolderTitle}
              style={{ flex: 1 }}
              onOn-blur={() => this.updateFolder(data)}
              onOn-enter={() => this.updateFolder(data)}
              autofocus
            />
          ) : (
            <span style={{ flex: 1 }}>{data.directoryName}</span>
          )}
          <Icon
            type="ios-create-outline"
            style={{ color: "blue", cursor: "pointer", marginLeft: "10px" }}
            onClick={(event) => {
              event.stopPropagation();
              this.startEditFolder(data);
            }}
          />
          <Icon
            type="ios-trash"
            style={{ color: "red", cursor: "pointer", marginLeft: "10px" }}
            onClick={(event) => {
              event.stopPropagation();
              this.confirmDeleteNode(data);
            }}
          />
        </div>
      );
      return data.isLeaf ? renderLeafNode() : renderFolderNode();
    },
    handleNodeClick(node) {
      if (node.isLeaf) {
        this.selectNote(node);
      } else {
        this.toggleFolder(node);
      }
    },
    onCopy(text) {
      const clipboard = new Clipboard('.btn', {
        text: () => text
      });

      clipboard.on('success', () => {
        this.$Message.success('复制成功');
        clipboard.destroy(); // 清理
      });

      clipboard.on('error', () => {
        this.$Message.error('复制失败');
        clipboard.destroy(); // 清理
      });

      clipboard.onClick(event); // 手动触发复制
  },
    toggleFolder(folder) {
      folder.isOpen = !folder.isOpen;
    },
    selectNote(note) {
      noteById({ noteId: note.noteId }).then((res) => {
        if (res.code !== 0) {
          Message.error(res.msg);
          return;
        }
        this.selectedNote = res.retVal;
        this.isEditing = false;
      });
    },
    showAddFolderDialog() {
      this.showAddFolder = true;
    },
    closeAddFolderDialog() {
      this.showAddFolder = false;
      this.newFolderTitle = "";
      this.selectedAddFolderFolderId = null;
    },
    addFolder() {
      if (!this.newFolderTitle.trim()) {
        Message.error("目录名称不能为空！");
        return;
      }
      let newFolder = {
        directoryName: this.newFolderTitle,
        notesList: [],
        isOpen: true,
        parentId: this.selectedAddFolderFolderId,
      };
      const that = this;
      const parentId = this.selectedAddFolderFolderId;
      saveDirectory(newFolder).then((res) => {
        if (res.code === 0) {
          newFolder.directoryId = res.retVal;
          if (parentId) {
            that.addFolderToParent(that.folders, parentId, newFolder);
          } else {
            that.folders.push(newFolder);
          }
          Message.success("目录添加成功");
        } else {
          Message.error("目录添加失败:" + res.msg);
          return;
        }
      });
      this.closeAddFolderDialog();
      this.restoreExpandState();
    },
    showAddNoteDialog() {
      this.selectedFolderId =
        this.folders.length > 0 ? this.folders[0].directoryId : null;
      if (this.folders.length === 0) {
        Message.error("请先新增目录");
        return;
      }
      this.showAddNote = true;
    },
    closeAddNoteDialog() {
      this.showAddNote = false;
      this.newNoteTitle = "";
      this.selectedAddNoteFolderId = null;
    },
    addNote() {
      if (!this.newNoteTitle) {
        this.$message.error("请填写笔记名称！");
        return;
      }
      if (!this.selectedAddNoteFolderId) {
        Message.error("请选择目录！");
        return;
      }
      let newNote = {
        noteTitle: this.newNoteTitle,
        directoryId: this.selectedAddNoteFolderId,
        noteContent: "",
        noteId: null,
      };
      saveNote(newNote).then((res) => {
        if (res.code === 0) {
          newNote.noteId = res.retVal;
          this.$Message.success("笔记添加成功");
        } else {
          this.$Message.error("笔记添加失败");
        }
      });
      let addNoteFolderId = this.selectedAddNoteFolderId;
      this.traverseDirectories(this.folders, (folder) => {
        if (folder.directoryId === addNoteFolderId) {
          folder.notesList.push(newNote);
        }
      });
      this.selectedNote = newNote;
      this.isEditing = true;
      this.closeAddNoteDialog();
      this.restoreExpandState();
    },
    editNote() {
      this.isEditing = true;
    },
    saveNote() {
      let modifyNoteData = {
        noteId: this.selectedNote.noteId,
        noteTitle: this.selectedNote.noteTitle,
        noteContent: this.selectedNote.noteContent,
      };
      modifyNote(modifyNoteData).then((res) => {
        if (res.code === 0) {
          // 更新左侧目录树中的笔记标题
          this.traverseDirectories(this.folders, (folder) => {
            if (folder.notesList) {
              const note = folder.notesList.find(
                note => note.noteId === this.selectedNote.noteId
              );
              if (note) {
                note.noteTitle = this.selectedNote.noteTitle;
              }
            }
          });
          Message.success("笔记保存成功");
        } else {
          Message.error("笔记保存失败");
        }
      });
      this.isEditing = false;
    },
    downloadNote() {
      const blob = new Blob([this.selectedNote.noteContent], {
        type: "text/markdown",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${this.selectedNote.noteTitle}.md`;
      a.click();
      URL.revokeObjectURL(url);
    },
    toggleFullscreen() {
      if (!document.fullscreenElement) {
        this.$refs.noteApp.requestFullscreen().catch(err => {
          console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
      } else {
        document.exitFullscreen();
      }
    },
    confirmDeleteFolder(folderId) {
      this.deleteTarget = folderId;
      this.deleteType = "folder";
      this.deleteModalContent = "确定要删除该目录吗？不可恢复！";
      this.showDeleteConfirm = true;
    },
    confirmDeleteNote(directoryId, noteId) {
      this.deleteTarget = noteId;
      this.deleteNoteDirectoryId = directoryId;
      this.deleteType = "note";
      this.deleteModalContent = "确定要删除该笔记吗？不可恢复！";
      this.showDeleteConfirm = true;
    },
    confirmDelete() {
      const deleteId = this.deleteTarget;
      if (this.deleteType === "folder") {
        deleteDirectory({ directoryId: this.deleteTarget }).then((res) => {
          if (res.code === 0) {
            this.$Message.success("目录删除成功");
            this.folders = this.removeDirectory(this.folders, deleteId);
          } else {
            this.$Message.error("目录删除失败");
            return;
          }
        });
      } else if (this.deleteType === "note") {
        deleteNote({ noteId: deleteId }).then((res) => {
          if (res.code === 0) {
            this.traverseDirectories(this.folders, (folder) => {
              if (folder.directoryId === this.deleteNoteDirectoryId) {
                const index = folder.notesList.findIndex(
                  (note) => note.noteId === deleteId
                );
                if (index !== -1) {
                  folder.notesList.splice(index, 1);
                }
              }
            });
            if (this.selectedNote && this.selectedNote.noteId === deleteId) {
              this.selectedNote = null;
            }
            this.$Message.success("笔记删除成功");
          } else {
            this.$Message.error("笔记删除失败");
            return;
          }
        });
      }
      this.deleteTarget = null;
      this.showDeleteConfirm = false;
    },
    cancelDelete() {
      this.showDeleteConfirm = false;
      this.deleteTarget = null;
      this.deleteType = "";
    },
    traverseDirectories(directories, action) {
      directories.forEach((directory) => {
        action(directory);
        if (directory.children && directory.children.length > 0) {
          this.traverseDirectories(directory.children, action);
        }
      });
    },
    addFolderToParent(folders, parentId, newFolder) {
      for (const folder of folders) {
        if (folder.directoryId === parentId) {
          if (!folder.children) {
            folder.children = []; // 初始化子目录
          }
          folder.children.push(newFolder); // 添加新文件夹
          folder.isOpen = true;
          return true; // 添加成功
        }
        if (folder.children) {
          const found = this.addFolderToParent(
            folder.children,
            parentId,
            newFolder
          );
          if (found) return true; // 找到并添加
        }
      }
      return false; // 未找到父文件夹
    },
    removeDirectory(directories, directoryId) {
      return directories.filter((directory) => {
        if (directory.directoryId === directoryId) {
          return false; // 删除该目录
        }
        // 递归处理子目录
        if (directory.children && directory.children.length > 0) {
          directory.children = this.removeDirectory(
            directory.children,
            directoryId
          );
        }
        return true; // 保留其他目录
      });
    },
  },
};

function debounce(fn, delay) {
  let timer = null;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}
</script>

<style scoped>
.note-app {
  display: flex;
  height: 95vh;
  background: linear-gradient(135deg, #f8f9fa, #e9ecef);
  gap: 24px;
  padding: 24px;
}

.sidebar {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 24px;
  width: 380px;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  display: flex;  /* 添加 */
  flex-direction: column;  /* 添加 */
  height: 100%;  /* 添加 */
}

.sidebar.collapsed {
  width: 90px;
  background-color: rgba(255, 255, 255, 0.98);
  box-shadow: 4px 0 15px rgba(0, 0, 0, 0.1);
}

.sidebar.collapsed .tree-wrapper,
.sidebar.collapsed .button-group,
.sidebar.collapsed .search-input {
  display: none;
}

.header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.header-title {
  font-size: 18px;
  margin-bottom: 0;
  flex-grow: 1;
  text-align: left;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.content.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 999;
}

.content.fullscreen .note-header {
  position: fixed;
  width: 100%;
  padding: 0 40px;
}

.content.fullscreen .note-details {
  padding-top: 70px;
  height: 100vh;
}

.collapse-button {
  background: linear-gradient(135deg, #40a9ff, #1e7ddc);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  font-size: 20px;
  padding: 8px 12px;
  transition: background-color 0.3s, transform 0.3s;
}

.collapse-button:hover {
  background-color: #3399cc;
  transform: scale(1.05);
}

.collapse-button Icon {
  transition: transform 0.3s;
}

.sidebar.collapsed .collapse-button Icon {
  transform: rotate(90deg);
}

.search-input {
  margin: 4px 0;
  width: 100%;
}

.search-input .ivu-input {
  height: 32px;
  border: 2px solid #e8e8e8;
  border-radius: 10px;
  padding: 4px 12px;
  transition: all 0.3s ease;
}

.search-input .ivu-input:hover,
.search-input .ivu-input:focus {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  gap: 4px;
}

.tree-wrapper {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  border-radius: 8px;
  background: #f8f9fa;
  margin-top: 8px;
}

/* 可选:添加滚动条样式美化 */
.tree-wrapper::-webkit-scrollbar {
  width: 6px;
}

.tree-wrapper::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.tree-wrapper::-webkit-scrollbar-track {
  background: transparent;
}

.note-details {
  flex: 1;
  overflow-y: auto;
  padding: 0 24px 24px;
  background: rgba(255, 255, 255, 0.98);
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.note-header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid #f0f0f0;
  padding: 0 24px;
  margin: 0;
  display: flex;
  gap: 10px;
  align-items: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.note-header .action-button {
  min-width: 80px;
  justify-content: center;
}

.note-header .save-button {
  background: #52c41a;
  color: white;
}

.note-header .edit-button {
  background: #1890ff;
  color: white;
}

.note-header .download-button {
  background: #722ed1;
  color: white;
}

.note-header .share-button {
  background: #fa8c16;
  color: white;
}

.note-header .action-button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.action-button {
  padding: 4px 8px;
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.25s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  font-size: 12px;
  height: 28px;
  line-height: 1;
}

.action-button.primary {
  background: linear-gradient(135deg, #40a9ff, #1890ff);
  color: white;
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.action-button .ivu-icon {
  font-size: 12px;
}

.note-editor,
.note-preview {
  height: 100%;
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    overflow: hidden;
  }

  .sidebar.active {
    width: 300px;
  }

  .content {
    margin-left: 0;
  }

  .content.shift {
    margin-left: 300px;
  }
}

.demo-tree-render .ivu-tree-title {
  padding: 6px 10px;
  border-radius: 8px;
  transition: all 0.25s ease;
  margin: 1px 0;
}

.demo-tree-render .ivu-tree-title:hover {
  background: rgba(24, 144, 255, 0.12);
}

.modal-body {
  padding: 24px;
}

.required {
  display: block;
  margin-bottom: 5px;
  color: #ff4d4f;
  font-weight: bold;
}

.required::before {
  content: "* ";
  color: #ff4d4f;
}

.noRequired {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.folder-input,
.note-input,
.folder-select,
.note-select {
  margin-bottom: 15px;
}

.folder-select,
.note-select {
  width: 100%;
}

h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.note-header {
  margin-bottom: 15px;
}

.placeholder {
  background: linear-gradient(135deg, #f8f9fa, #e9ecef);
  border: 2px dashed #d9d9d9;
  border-radius: 16px;
  padding: 40px;
  text-align: center;
}

.placeholder-content {
  color: #8c8c8c;
}

.placeholder-content h3 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.placeholder-content p {
  font-size: 16px;
  color: #666;
}

.ivu-modal {
  padding: 20px;
  background-color: #f7f7f7;
}

.ivu-modal-header {
  font-size: 18px;
  font-weight: bold;
}

.note-app .sidebar .demo-tree-render div:hover {
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
}

.file-list {
  margin-top: 16px;
}

.file-item {
  background: rgba(248, 249, 250, 0.8);
  border-radius: 10px;
  padding: 14px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  transition: all 0.25s ease;
}

.file-item:hover {
  background: rgba(232, 232, 232, 0.9);
  transform: translateX(4px);
}

.file-icon {
  margin-right: 10px;
}

.file-name {
  flex-grow: 1;
}

.delete-button {
  margin-left: 10px;
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

/* 分享弹窗样式优化 */
.ivu-modal-content {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
}

.ivu-modal-header {
  background: linear-gradient(135deg, #1890ff, #096dd9);
  padding: 20px 24px;
  position: relative;
  overflow: hidden;
}

.ivu-modal-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.1));
}

.ivu-modal-header-inner {
  color: white;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ivu-modal-body {
  padding: 28px;
  background: #ffffff;
}

.share-dialog-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.share-title {
  font-size: 16px;
  color: #1f1f1f;
  margin-bottom: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}

.share-title::before {
  content: '🔗';
  font-size: 20px;
}

.share-input-wrapper {
  display: flex;
  gap: 12px;
  align-items: center;
  background: #f8f9fa;
  border-radius: 12px;
  padding: 4px;
  border: 1px solid #e8e8e8;
  transition: all 0.3s ease;
}

.share-input-wrapper:hover {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
}

.share-input {
  flex: 1;
  background: transparent;
  border: none !important;
  padding: 12px;
  font-size: 14px;
  color: #333;
}

.share-input:focus {
  box-shadow: none;
}

.copy-button {
  background: linear-gradient(135deg, #1890ff, #096dd9);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
  white-space: nowrap;
}

.copy-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(24, 144, 255, 0.25);
  background: linear-gradient(135deg, #40a9ff, #1890ff);
}

.copy-button:active {
  transform: translateY(0);
}

.share-tips {
  margin-top: 8px;
  color: #666;
  font-size: 14px;
  line-height: 1.8;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 12px;
  border-left: 4px solid #1890ff;
  position: relative;
  overflow: hidden;
}

.share-tips::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent, rgba(24, 144, 255, 0.05));
  pointer-events: none;
}

.share-tips p {
  margin: 8px 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.share-tips p:first-child {
  font-weight: 600;
  color: #1890ff;
  margin-top: 0;
}

/* 添加动画效果 */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.share-dialog-content > * {
  animation: slideIn 0.3s ease-out forwards;
}

.share-dialog-content > *:nth-child(2) {
  animation-delay: 0.1s;
}

.share-dialog-content > *:nth-child(3) {
  animation-delay: 0.2s;
}

/* 调整 Input 组件的默认高度 */
.ivu-input {
  height: 32px;
}

/* 如果使用了 view-design 的按钮，可能还需要覆盖其默认样式 */
.ivu-btn {
  height: 28px;
  line-height: 1;
}
</style>
