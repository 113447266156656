export default {
    tabSize: 4,
    styleActiveLine: true,
    lineNumbers: true,
    lineWrapping: false,
    line: true,
    mode: 'text/x-src',
    theme: 'default',
    cursorHeight:0.8,
    lineWiseCopyCut:true
};
