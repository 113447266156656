<template>
  <div class="input-group">
    <div class="action-buttons">
      <Upload
        :headers="headers"
        :before-upload="beforeUpload"
        action="https://www.bjca.xyz/diary/message/upload"
        :on-success="sendImgAndVideo"
        :on-error="handleUploadError"
        :show-upload-list="false"
         accept="image/*,video/*"
    :format="['jpg', 'jpeg', 'png', 'gif', 'mp4', 'avi', 'mov']"
      >
        <iButton icon="ios-images" class="media-button"></iButton>
      </Upload>

      <Upload
        :headers="headers"
        :before-upload="beforeUploadFile"
        action="https://www.bjca.xyz/diary/message/upload"
        :on-success="sendFile"
        :on-error="handleUploadError"
        :show-upload-list="false"
      >
        <iButton icon="ios-folder" class="file-button"></iButton>
      </Upload>

      <iButton
        :class="{ 'audio-button': true, recording: isRecording }"
        @click="toggleRecording"
      >
        <span v-if="isRecording">{{ recordingTime }}</span>
        <span v-else>开始录音</span>
      </iButton>

      <Poptip
        title
        trigger="click"
        placement="top-start"
        ref="emojiPicker"
        @on-popper-hide="handleEmojiPickerHide"
        transfer
        :transfer-class-name="'emoji-transfer-class'"
      >
        <iButton
          icon="md-happy"
          @click="toggleEmojiPicker"
          class="emoji-button"
        ></iButton>
        <div slot="content" class="emoji-content" @click="handleEmojiContentClick">
          <Tabs v-model="activeTab" :animated="false">
            <TabPane label="普通表情" name="0">
              <div class="emoji-category-tabs">
                <div
                  v-for="category in emojiCategories"
                  :key="category.emojis"
                  :class="['category-tab', { active: currentEmojiCategory === category.emojis }]"
                  @click="changeEmojiCategory(category.emojis)"
                >
                  {{ category.name }}
                </div>
              </div>
              <div class="emoji-grid">
                <div
                  v-for="(emoji, index) in normalEmojis"
                  :key="`emoji-${index}`"
                  class="emoji-item"
                  @click.stop="insertEmoji('normal', emoji, $event)"
                >
                  {{ emoji }}
                </div>
              </div>
            </TabPane>
            <TabPane label="图片表情" name="1">
              <Input
                placeholder="搜索表情..."
                v-model="searchText"
                @on-change="getEmoji(searchText)"
              />
              <div class="image-emoji-container">
                <div v-for="img in emojiImgList" :key="img" class="image-emoji-item">
                  <Poptip trigger="hover" placement="top" transfer>
                    <div class="img-wrapper">
                      <Spin fix v-if="!imageLoaded[img]">
                        <Icon type="ios-loading" size="18" class="spin-icon-load"></Icon>
                      </Spin>
                      <img
                        :src="img"
                        @click.stop="insertEmoji('img', img, $event)"
                        @load="handleImageLoad(img)"
                        loading="lazy"
                      />
                    </div>
                    <div slot="content" class="emoji-preview">
                      <img :src="img" alt="预览" />
                    </div>
                  </Poptip>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </Poptip>
    </div>

    <div class="message-input-container">
      <Input
        v-model="newMessage"
        placeholder="请输入消息..."
        @on-enter="send"
        type="textarea"
        :autosize="{ minRows: 1, maxRows: 10 }"
        class="message-input"
      />
      <iButton @click="send" icon="ios-send" class="send-button"></iButton>
    </div>

    <Modal
      v-model="showRecordingModal"
      title="录音"
      @on-ok="sendAudio"
      @on-cancel="cancelRecording"
    >
      <div class="recording-modal-content">
        <div class="recording-timer">{{ recordingTime }}</div>
        <div class="recording-controls">
          <iButton
            v-if="!isRecording"
            class="record-button"
            @click="startRecording"
          >
            开始录音
          </iButton>
          <iButton
            v-if="isRecording"
            class="stop-button"
            @click="stopRecording"
          >
            停止录音
          </iButton>
        </div>
        <audio v-if="audioBlob" controls :src="audioUrl"></audio>
      </div>
    </Modal>
  </div>
</template>
<script>
import { generateUUID } from "@/utils/uuidGenerator.js";
import axios from "axios";
import emojis from '@/assets/emojis.json';  // 导入表情JSON

export default {
  data() {
    return {
      mediaType: "",
      headers: {
        authorization: localStorage.getItem("token"),
      },
      newMessage: "",
      fileId: "",
      activeTab: "0",
      normalEmojis: [], // 将在 mounted 中填充
      emojiCategories: [
        { name: '笑脸', emojis: 'smileys' },
        { name: '手势', emojis: 'gestures' },
        { name: '人物', emojis: 'people' },
        { name: '动物', emojis: 'animals' },
        { name: '食物', emojis: 'food' },
        { name: '爱心', emojis: 'hearts' },
        { name: '活动', emojis: 'activities' },
        { name: '交通', emojis: 'travel' },
        { name: '物品', emojis: 'objects' }
      ],
      currentEmojiCategory: 'smileys',
      searchText: "",
      emojiImgList: [],
      isRecording: false,
      mediaRecorder: null,
      recordedChunks: [],
      recordingTime: "00:00",
      recordingInterval: null,
      showRecordingModal: false,
      audioBlob: null,
      audioUrl: "",
      imageLoaded: {},
    };
  },
  mounted() {
    document.addEventListener("paste", this.handlePaste);
    this.normalEmojis = emojis[this.currentEmojiCategory];
  },
  methods: {
    handleEmojiPickerHide() {
      this.activeTab = '0';
      this.searchText = '';
    },
    toggleEmojiPicker(e) {
      if (e) {
        e.stopPropagation();
      }
      this.$refs.emojiPicker.visible = !this.$refs.emojiPicker.visible;
      if (this.$refs.emojiPicker.visible) {
        this.$nextTick(() => {
          this.activeTab = '0';
          this.searchText = '';
        });
      }
    },
    insertEmoji(type, emoji, e) {
      if (e) {
        e.stopPropagation();
      }
      
      if (type === "normal") {
        this.newMessage += emoji;
      } else if (type === "img") {
        const fileTempId = generateUUID(32, false);
        this.$emit("sendMedia", emoji, "img", fileTempId, "true");
        this.$emit("sendMedia", emoji, "img", fileTempId, "false");
        this.$refs.emojiPicker.visible = false;
      }
    },
    uploadImage(file) {
      const formData = new FormData();
      formData.append("file", file);

      axios
        .post("https://www.bjca.xyz/diary/file/uploadImg", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.$emit(
        "sendMedia",
        response.data.retVal,
        "img",
        this.fileId,
        "false"
      );
          this.sendImage(response.data, file, []);
        })
        .catch((error) => {
          this.handleUploadError(error, file, []);
        });
    },
    handlePaste(event) {
      const items = (event.clipboardData || event.originalEvent.clipboardData)
        .items;

      for (const item of items) {
        if (item.type.indexOf("image") !== -1) {
          const file = item.getAsFile();
          this.handlePastedImage(file);
          this.uploadImage(file);
        }
      }
    },
    handlePastedImage(file) {
      const tempUrl = URL.createObjectURL(file);
      const fileTempId = generateUUID(32, false);
      this.fileId = fileTempId;
      this.$emit("sendMedia", tempUrl, "img", this.fileId, "true");
    },
    getEmoji(text) {
      this.emojiImgList = [];
      const service1 = axios.create({
        baseURL: "https://www.bjca.xyz/diary/common/searchEmoji?searchKey=" + text,
        timeout: 60000,
      });
      service1
        .get()
        .then((res) => {
          this.emojiImgList = res.data.retVal;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    beforeUpload(file) {
      const allowedImageTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/svg+xml",
      ];
      const allowedVideoTypes = [
        "video/mp4",
        "video/avi",
        "video/mov",
        "video/mpeg",
        "video/webm",
      ];
      if (
        !allowedImageTypes.includes(file.type) &&
        !allowedVideoTypes.includes(file.type)
      ) {
        this.$Message.error("暂时只支持发送图片和视频");
        return false;
      }
      const tempUrl = URL.createObjectURL(file);
      if (file.type.includes("image")) {
        this.mediaType = "img";
        const fileTempId = generateUUID(32, false);
        this.fileId = fileTempId;
        this.$emit("sendMedia", tempUrl, "img", this.fileId, "true");
      } else if (file.type.includes("video")) {
        this.mediaType = "video";
        const fileTempId = generateUUID(32, false);
        this.fileId = fileTempId;
        this.$emit("sendMedia", tempUrl, "video", this.fileId, "true");
      }
      return true;
    },
    beforeUploadFile(file) {
      const tempUrl = URL.createObjectURL(file);
      const fileTempId = generateUUID(32, false);
      this.fileId = fileTempId;
      this.$emit("sendMedia", tempUrl, "file", this.fileId, "true");
      this.$emit("sendFileName", this.fileId, file.name);
      return true;
    },
    sendImgAndVideo(response, file, fileList) {
      this.$emit(
        "sendMedia",
        response.retVal,
        this.mediaType,
        this.fileId,
        "false"
      );
    },
    sendFile(response, file, fileList) {
      this.$emit(
        "sendMedia",
        response.retVal,
        "file",
        this.fileId,
        "false"
      );
    },
    handleUploadError(error, file, fileList) {
      console.error("上传文件失败", error);
    },
    send() {
      if (this.newMessage.trim()) {
        console.log('发送消息',this.newMessage);
        this.$emit("send", this.newMessage);
        this.newMessage = "";
      }
    },
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        if (this.audioBlob) {
          this.$Message.warning("当前有未完成的录音，请先处理");
          return;
        }
        this.startRecording();
      }
    },
    startRecording() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((stream) => {
            this.mediaRecorder = new MediaRecorder(stream);
            this.mediaRecorder.ondataavailable = (event) => {
              this.recordedChunks.push(event.data);
            };
            this.mediaRecorder.onstop = this.handleRecordingStop;
            this.mediaRecorder.start();
            this.isRecording = true;
            this.$nextTick(() => {
              // 在图更新后展示录音模态框
              this.showRecordingModal = true;
            });
            this.startTimer();
          })
          .catch((error) => {
            console.error("Error accessing microphone:", error);
          });
      }
    },
    stopRecording() {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
      }
      this.isRecording = false;
      this.stopTimer();
    },
    handleRecordingStop() {
      this.audioBlob = new Blob(this.recordedChunks, { type: "audio/webm" });
      this.audioUrl = URL.createObjectURL(this.audioBlob);
      this.recordedChunks = [];
      this.showRecordingModal = true;
    },
    sendAudio() {
      if (!this.audioBlob) return;

      const tempUrl = URL.createObjectURL(this.audioBlob);
      const fileTempId = generateUUID(32, false);
      this.fileId = fileTempId;
      this.$emit("sendMedia", tempUrl, "audio", this.fileId, "true");

      const formData = new FormData();
      formData.append("file", this.audioBlob, "recording.webm");
      axios
        .post("https://www.bjca.xyz/diary/message/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.sendAudioResponse(response.data);
        })
        .catch((error) => {
          this.handleUploadError(error, this.audioBlob, []);
        });

      this.showRecordingModal = false;
      this.audioBlob = null;
    },
    sendAudioResponse(response) {
      if (response.code === 0) {
        const fileUrl = response.retVal;
        this.$emit("sendMedia", fileUrl, "audio", this.fileId, "false");
      }
    },
    cancelRecording() {
      this.showRecordingModal = false;
      this.audioBlob = null;
    },
    startTimer() {
      let seconds = 0;
      this.recordingInterval = setInterval(() => {
        seconds += 1;
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        this.recordingTime = `${minutes
          .toString()
          .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.recordingInterval);
      this.recordingTime = "00:00";
    },
    handleEmojiContentClick(e) {
      e.stopPropagation();
    },
    handleImageLoad(imgUrl) {
      this.$set(this.imageLoaded, imgUrl, true);
    },
    changeEmojiCategory(category) {
      this.currentEmojiCategory = category;
      this.normalEmojis = emojis[category];
    }
  },
};
</script>
<style scoped>
.input-group {
  display: flex;
  flex-direction: column;
  padding: 12px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
}

.action-buttons {
  display: flex;
  margin-bottom: 12px;
  gap: 8px; /* 使用gap替代margin-right，更均匀 */
}

/* 美化功能按钮 */
.media-button,
.emoji-button,
.audio-button,
.file-button {
  padding: 8px 12px;
  border-radius: 8px;
  border: none;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  min-width: 36px;
}

.media-button {
  background: linear-gradient(135deg, #60a5fa, #3b82f6);
}

.file-button {
  background: linear-gradient(135deg, #34d399, #10b981);
}

.emoji-button {
  background: linear-gradient(135deg, #fbbf24, #f59e0b);
}

.audio-button {
  background: linear-gradient(135deg, #f87171, #ef4444);
}

/* 按钮悬停效果 */
.media-button:hover,
.emoji-button:hover,
.audio-button:hover,
.file-button:hover {
  transform: translateY(-2px);
  filter: brightness(110%);
}

/* 输入框容器样式优化 */
.message-input-container {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  background: #f3f4f6;
  padding: 8px;
  border-radius: 12px;
}

.message-input {
  flex-grow: 1;
}

/* 覆盖 iView 的默认样式 */
.message-input :deep(.ivu-input-wrapper) {
  width: 100%;
}

.message-input :deep(.ivu-input) {
  min-height: 40px;
  border: none;
  border-radius: 8px;
  background: white;
  padding: 8px 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

/* 关键修改：直接设置 textarea 元素的样式 */
.message-input :deep(.ivu-input textarea) {
  resize: vertical !important;
  min-height: 40px !important;
  max-height: 300px !important;
}

.message-input :deep(.ivu-input:focus) {
  box-shadow: 0 2px 8px rgba(59, 130, 246, 0.15);
}

/* 自定义文本域的滚动条样式 */
.message-input :deep(.ivu-input::-webkit-scrollbar) {
  width: 6px;
}

.message-input :deep(.ivu-input::-webkit-scrollbar-track) {
  background: #f1f1f1;
  border-radius: 3px;
}

.message-input :deep(.ivu-input::-webkit-scrollbar-thumb) {
  background: #c1c1c1;
  border-radius: 3px;
}

.message-input :deep(.ivu-input::-webkit-scrollbar-thumb:hover) {
  background: #a8a8a8;
}

/* 发送按钮样式 */
.send-button {
  padding: 8px 16px;
  border-radius: 20px;
  border: none;
  background: linear-gradient(135deg, #3b82f6, #2563eb);
  color: white;
  font-size: 16px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 42px;
  height: 42px;
}

.send-button:hover {
  transform: translateY(-2px);
  background: linear-gradient(135deg, #2563eb, #1d4ed8);
  box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);
}

/* 表情选择器样式 */
.emoji-content {
  width: 360px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1000;
  overflow: visible;
  max-height: 400px;
}

/* 表情网格布局 */
.emoji-grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 6px;
  padding: 12px;
  height: 200px;
  overflow-y: auto;
}

/* 表情项样式 */
.emoji-item {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.emoji-item:hover {
  background-color: #f0f2f5;
}

/* 标签页样式 */
.emoji-content :deep(.ivu-tabs-bar) {
  margin-bottom: 0;
  border-bottom: 1px solid #eee;
}

.emoji-content :deep(.ivu-tabs-nav) {
  padding: 8px 8px 0;
}

.emoji-content :deep(.ivu-tabs-tab) {
  padding: 6px 12px;
  font-size: 13px;
}

.emoji-content :deep(.ivu-tabs-tab-active) {
  color: #3b82f6 !important;
}

/* 移除多余边距 */
:deep(.ivu-poptip-body) {
  padding: 0;
}

:deep(.ivu-poptip-body-content) {
  padding: 0;
}

/* 图片表情容器 */
.image-emoji-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  padding: 8px;
  max-height: 300px;
  overflow-y: auto;
  background: #fff;
}

/* 自定义滚动条样式 */
.image-emoji-container::-webkit-scrollbar {
  width: 6px;
}

.image-emoji-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.image-emoji-container::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

.image-emoji-container::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* 确保 Poptip 预览不受滚动影响 */
.emoji-content {
  max-height: 400px;
}

/* 图片表情项 */
.image-emoji-item {
  aspect-ratio: 1;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.image-emoji-item:hover {
  transform: scale(1.05);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* 图片样式 */
.image-emoji-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

/* 自定义滚动条 */
.emoji-grid::-webkit-scrollbar,
.image-emoji-container::-webkit-scrollbar {
  width: 4px;
}

.emoji-grid::-webkit-scrollbar-thumb,
.image-emoji-container::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 2px;
}

/* 录音模态框样式 */
.recording-modal-content {
  text-align: center;
  padding: 24px;
}

.recording-timer {
  font-size: 32px;
  font-weight: 600;
  color: #3b82f6;
  margin-bottom: 24px;
}

.recording-controls {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-bottom: 24px;
}

/* 确保表情选择器始终显示在上方 */
.emoji-transfer-class {
  transform-origin: center bottom !important;
}

/* 调整弹出层位置 */
:deep(.ivu-poptip-popper) {
  padding-bottom: 10px !important;
}

:deep(.ivu-poptip-arrow) {
  bottom: 4px !important;
}

/* 图片表情搜索框 */
.emoji-content :deep(.ivu-tabs-tabpane) {
  padding: 8px 8px 0;
}

.emoji-content :deep(.ivu-input-wrapper) {
  margin-bottom: 8px;
}

.emoji-content :deep(.ivu-input) {
  height: 28px;
  border-radius: 4px;
  font-size: 12px;
}

/* 图片预览样式 */
.emoji-preview {
  padding: 4px;
}

.emoji-preview img {
  width: 200px;
  height: 200px;
  object-fit: contain;
  border-radius: 4px;
}

/* 调整 Poptip 的样式 */
:deep(.ivu-poptip-body) {
  padding: 0;
}

:deep(.ivu-poptip-body-content) {
  padding: 0;
}

/* 移除原来的预览相关样式 */
.image-preview {
  display: none;
}

.img-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

/* 加载图标旋转动画 */
.spin-icon-load {
  animation: ani-spin 1s linear infinite;
}

@keyframes ani-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* 调整 Spin 组件样式 */
:deep(.ivu-spin-fix) {
  background-color: rgba(255, 255, 255, 0.8);
}

:deep(.ivu-spin-fix .ivu-spin-main) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 确保图片容器保持正方形 */
.image-emoji-item {
  aspect-ratio: 1;
  position: relative;
}

.image-emoji-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.emoji-category-tabs {
  display: flex;
  overflow-x: auto;
  padding: 8px;
  border-bottom: 1px solid #eee;
}

.category-tab {
  padding: 4px 12px;
  margin-right: 8px;
  border-radius: 12px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 12px;
  transition: all 0.3s;
}

.category-tab:hover {
  background: #f0f2f5;
}

.category-tab.active {
  background: #e6f7ff;
  color: #1890ff;
}
</style>
